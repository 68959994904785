// このファイルは自動生成されたものです。手で編集しないでください。
export const wkRoles = ["admin", "exective", "nopj", "manager", "clerk", "pl", "user", "sport", "temp"];
export const wkRoleAbilities = {
  admin: {
    roleCode: "admin",
    roleName: "管理者",
    abilities: ["DISP_ROOTMENU_LIST", "DISP_MENU_STATISTICS", "DISP_MENU_KINTAILIST", "DISP_CLOSE_DPT_KINTAI", "DISP_MENU_KINTAISUM", "DISP_MENU_TRANSDAYLIST", "DISP_MENU_MEDLIST", "DISP_MEDLIST_SAVE", "DISP_MENU_SABUROKU", "DISP_MENU_EXPORT", "DISP_ROOTMENU_ADMIN", "DISP_KINTAI_CLERK_TS", "DISP_KINTAI_HRM_TS", "DISP_OTHERSKINTAI_APP", "DISP_PRIVILEGEDKINTAI_APP", "DISP_KINTAI_BULKSET_BUNRUI", "DISP_KINTAI_REFRESH", "DISP_KINTAI_MODIFY_ATTRS", "DISP_KINTAI_DELETE", "DISP_KINTAI_SHOW_SET_FIXED_TIME", "DISP_OTHERSKINTAI_SAVE", "API_OTHERSKINTAI_LIST", "API_OTHERSKINTAI_SAVE", "API_KINTAI_REFRESH", "API_KINTAI_MODIFY_ATTRS", "API_KINTAI_DELETE", "API_KINTAI_CLERK_TS", "API_KINTAI_HRM_TS", "API_OTHERSKINTAI_APP", "API_PRIVILEGEDKINTAI_APP", "API_CLOSE_DPT_KINTAI", "API_KINTAI_CANCREATE_SPORT", "API_TRANSDATE_LIST", "API_MED_LIST", "API_MED_SAVE", "API_SABUROKU_SAVE", "API_ACCOUNT_LIST", "API_ACCOUNT_SAVE", "API_DEFAULT_PASSWORD_GET", "API_PV_LIST", "API_PV_SAVE", "API_CAL_LIST", "API_CAL_SAVE", "API_DEPT_LIST", "API_DEPT_SAVE", "API_RANK_LIST", "API_RANK_SAVE", "API_SPORTADMIN_LIST", "API_SPORTADMIN_SAVE", "API_DEPTPIC_LIST", "API_DEPTPIC_SAVE", "API_PROJECT_CLOSE_LIST", "API_PROJECT_CLOSE_SAVE"]
  },
  exective: {
    roleCode: "exective",
    roleName: "役員",
    abilities: ["DISP_ROOTMENU_LIST", "DISP_MENU_STATISTICS", "DISP_MENU_KINTAILIST", "DISP_MENU_KINTAISUM", "DISP_MENU_TRANSDAYLIST", "DISP_MENU_MEDLIST", "DISP_MENU_SABUROKU", "DISP_MENU_EXPORT", "DISP_KINTAI_SHOW_SET_FIXED_TIME", "DISP_OTHERSKINTAI_SAVE", "API_OTHERSKINTAI_LIST", "API_OTHERSKINTAI_SAVE", "API_TRANSDATE_LIST", "API_MED_LIST", "API_SABUROKU_SAVE", "API_CAL_LIST", "API_DEPT_LIST", "API_RANK_LIST", "API_SPORTADMIN_LIST"]
  },
  nopj: {
    roleCode: "nopj",
    roleName: "月報禁止",
    abilities: []
  },
  manager: {
    roleCode: "manager",
    roleName: "管理職",
    abilities: ["DISP_ROOTMENU_LIST", "DISP_MENU_STATISTICS", "DISP_MENU_KINTAILIST", "DISP_MENU_KINTAISUM", "DISP_MENU_TRANSDAYLIST", "DISP_MENU_MEDLIST", "DISP_MENU_SABUROKU", "DISP_KINTAI_SHOW_SET_FIXED_TIME", "DISP_OTHERSKINTAI_SAVE", "API_OTHERSKINTAI_LIST", "API_OTHERSKINTAI_SAVE", "API_TRANSDATE_LIST", "API_MED_LIST", "API_SABUROKU_SAVE", "API_CAL_LIST", "API_DEPT_LIST", "API_RANK_LIST", "API_SPORTADMIN_LIST"]
  },
  clerk: {
    roleCode: "clerk",
    roleName: "事務担当",
    abilities: ["DISP_ROOTMENU_LIST", "DISP_MENU_STATISTICS", "DISP_MENU_KINTAILIST", "DISP_CLOSE_DPT_KINTAI", "DISP_MENU_KINTAISUM", "DISP_MENU_TRANSDAYLIST", "DISP_MENU_EXPORT", "DISP_KINTAI_CLERK_TS", "DISP_OTHERSKINTAI_APP", "DISP_KINTAI_BULKSET_BUNRUI", "DISP_KINTAI_MODIFY_ATTRS", "DISP_OTHERSKINTAI_SAVE", "API_OTHERSKINTAI_LIST", "API_OTHERSKINTAI_SAVE", "API_KINTAI_REFRESH", "API_KINTAI_MODIFY_ATTRS", "API_KINTAI_CLERK_TS", "API_OTHERSKINTAI_APP", "API_CLOSE_DPT_KINTAI", "API_TRANSDATE_LIST", "API_MED_LIST", "API_CAL_LIST", "API_DEPT_LIST", "API_RANK_LIST", "API_SPORTADMIN_LIST"]
  },
  pl: {
    roleCode: "pl",
    roleName: "PL",
    abilities: ["DISP_ROOTMENU_LIST", "DISP_MENU_STATISTICS", "DISP_MENU_KINTAILIST", "DISP_MENU_KINTAISUM", "API_OTHERSKINTAI_LIST", "API_CAL_LIST", "API_DEPT_LIST", "API_RANK_LIST", "API_SPORTADMIN_LIST"]
  },
  user: {
    roleCode: "user",
    roleName: "一般",
    abilities: ["DISP_ROOTMENU_LIST", "DISP_MENU_STATISTICS", "API_CAL_LIST", "API_DEPT_LIST", "API_RANK_LIST", "API_SPORTADMIN_LIST"]
  },
  sport: {
    roleCode: "sport",
    roleName: "S-Port",
    abilities: ["API_KINTAI_CANCREATE_SPORT"]
  },
  temp: {
    roleCode: "temp",
    roleName: "移行者",
    abilities: ["API_CAL_LIST", "API_DEPT_LIST", "API_RANK_LIST", "API_SPORTADMIN_LIST"]
  }
};