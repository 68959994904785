export const minutesAdd = (l, r) => {
  if (!l && !r) {
    return undefined;
  }

  if (!l) {
    return r;
  }

  if (!r) {
    return l;
  }

  return l.add(r);
};