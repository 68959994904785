import { NullKintai } from "./definitions";
/**
 * 分類1-4の組み合わせについての計算クラス
 */

export class RegularBunruiComb {
  constructor(allBunruiList, bunruiList) {
    this.allBunruiList = allBunruiList;
    this.bunruiList = bunruiList; // 正規化する

    this.normalize();
  }

  getAvailableBunruiList(bunruiIdx) {
    if (bunruiIdx === 1) {
      return this.allBunruiList.filter(it => it.canAppearAt(1));
    }

    let curList = this.allBunruiList;

    for (let i = 0; i < Math.min(bunruiIdx - 1, this.bunruiList.length); i++) {
      curList = curList.filter(e => e.isForRegular && e.canBeWith(this.bunruiList[i]));
    }

    return curList.filter(e => e.canAppearAt(bunruiIdx));
  }

  getAt(bunruiIdx) {
    return this.bunruiList[bunruiIdx - 1] || NullKintai;
  }

  normalize() {
    this.bunruiList = this.bunruiList // 先頭から指定可能なもののみでフィルタする
    .filter((e, idx) => {
      if (idx === 0) {
        return true;
      }

      return this.getAvailableBunruiList(idx + 1).findIndex(it => it === e) > 0;
    }) // 空は詰める
    .filter(e => e !== NullKintai);
  }

}