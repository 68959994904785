/// <reference path="./custom.d.ts" />
// tslint:disable

/**
 * webkintai-backend-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as url from "url";
import * as portableFetch from "portable-fetch";
const BASE_PATH = "http://localhost:17080".replace(/\/+$/, "");
/**
 *
 * @export
 */

export const COLLECTION_FORMATS = {
  csv: ",",
  ssv: " ",
  tsv: "\t",
  pipes: "|"
};
/**
 *
 * @export
 * @class BaseAPI
 */

export class BaseAPI {
  constructor(configuration, basePath = BASE_PATH, fetch = portableFetch) {
    this.basePath = basePath;
    this.fetch = fetch;

    if (configuration) {
      this.configuration = configuration;
      this.basePath = configuration.basePath || this.basePath;
    }
  }

}
;
/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */

export class RequiredError extends Error {
  constructor(field, msg) {
    super(msg);
    this.field = field;
    this.name = "RequiredError";
  }

}
/**
 * @export
 * @namespace ApprovalStatusEntity
 */

export var ApprovalStatusEntity;

(function (ApprovalStatusEntity) {
  /**
   * @export
   * @enum {string}
   */
  let ValueEnum;

  (function (ValueEnum) {
    ValueEnum["Approved"] = "approved";
    ValueEnum["Rejected"] = "rejected";
  })(ValueEnum = ApprovalStatusEntity.ValueEnum || (ApprovalStatusEntity.ValueEnum = {}));
})(ApprovalStatusEntity || (ApprovalStatusEntity = {}));
/**
 * @export
 * @namespace ApproversResult
 */


export var ApproversResult;

(function (ApproversResult) {
  /**
   * @export
   * @enum {string}
   */
  let StatusEnum;

  (function (StatusEnum) {
    StatusEnum["Success"] = "success";
    StatusEnum["Failure"] = "failure";
    StatusEnum["RequiredToChangePassword"] = "required_to_change_password";
  })(StatusEnum = ApproversResult.StatusEnum || (ApproversResult.StatusEnum = {}));
})(ApproversResult || (ApproversResult = {}));
/**
 * @export
 * @namespace CalendarResult
 */


export var CalendarResult;

(function (CalendarResult) {
  /**
   * @export
   * @enum {string}
   */
  let StatusEnum;

  (function (StatusEnum) {
    StatusEnum["Success"] = "success";
    StatusEnum["Failure"] = "failure";
    StatusEnum["RequiredToChangePassword"] = "required_to_change_password";
  })(StatusEnum = CalendarResult.StatusEnum || (CalendarResult.StatusEnum = {}));
})(CalendarResult || (CalendarResult = {}));
/**
 * @export
 * @namespace CompanyNendoDashboardResult
 */


export var CompanyNendoDashboardResult;

(function (CompanyNendoDashboardResult) {
  /**
   * @export
   * @enum {string}
   */
  let StatusEnum;

  (function (StatusEnum) {
    StatusEnum["Success"] = "success";
    StatusEnum["Failure"] = "failure";
    StatusEnum["RequiredToChangePassword"] = "required_to_change_password";
  })(StatusEnum = CompanyNendoDashboardResult.StatusEnum || (CompanyNendoDashboardResult.StatusEnum = {}));
})(CompanyNendoDashboardResult || (CompanyNendoDashboardResult = {}));
/**
 * @export
 * @namespace DailyApplicationResult
 */


export var DailyApplicationResult;

(function (DailyApplicationResult) {
  /**
   * @export
   * @enum {string}
   */
  let StatusEnum;

  (function (StatusEnum) {
    StatusEnum["Success"] = "success";
    StatusEnum["Failure"] = "failure";
    StatusEnum["RequiredToChangePassword"] = "required_to_change_password";
  })(StatusEnum = DailyApplicationResult.StatusEnum || (DailyApplicationResult.StatusEnum = {}));
})(DailyApplicationResult || (DailyApplicationResult = {}));
/**
 * @export
 * @namespace DailyErrorEntity
 */


export var DailyErrorEntity;

(function (DailyErrorEntity) {
  /**
   * @export
   * @enum {string}
   */
  let LevelEnum;

  (function (LevelEnum) {
    LevelEnum["ERROR"] = "ERROR";
    LevelEnum["WARN"] = "WARN";
    LevelEnum["INFO"] = "INFO";
  })(LevelEnum = DailyErrorEntity.LevelEnum || (DailyErrorEntity.LevelEnum = {}));
})(DailyErrorEntity || (DailyErrorEntity = {}));
/**
 * @export
 * @namespace DefaultResetPasswordSuggestionResult
 */


export var DefaultResetPasswordSuggestionResult;

(function (DefaultResetPasswordSuggestionResult) {
  /**
   * @export
   * @enum {string}
   */
  let StatusEnum;

  (function (StatusEnum) {
    StatusEnum["Success"] = "success";
    StatusEnum["Failure"] = "failure";
    StatusEnum["RequiredToChangePassword"] = "required_to_change_password";
  })(StatusEnum = DefaultResetPasswordSuggestionResult.StatusEnum || (DefaultResetPasswordSuggestionResult.StatusEnum = {}));
})(DefaultResetPasswordSuggestionResult || (DefaultResetPasswordSuggestionResult = {}));
/**
 * @export
 * @namespace DepartmentResult
 */


export var DepartmentResult;

(function (DepartmentResult) {
  /**
   * @export
   * @enum {string}
   */
  let StatusEnum;

  (function (StatusEnum) {
    StatusEnum["Success"] = "success";
    StatusEnum["Failure"] = "failure";
    StatusEnum["RequiredToChangePassword"] = "required_to_change_password";
  })(StatusEnum = DepartmentResult.StatusEnum || (DepartmentResult.StatusEnum = {}));
})(DepartmentResult || (DepartmentResult = {}));
/**
 * @export
 * @namespace DeptPersonsInChargeResult
 */


export var DeptPersonsInChargeResult;

(function (DeptPersonsInChargeResult) {
  /**
   * @export
   * @enum {string}
   */
  let StatusEnum;

  (function (StatusEnum) {
    StatusEnum["Success"] = "success";
    StatusEnum["Failure"] = "failure";
    StatusEnum["RequiredToChangePassword"] = "required_to_change_password";
  })(StatusEnum = DeptPersonsInChargeResult.StatusEnum || (DeptPersonsInChargeResult.StatusEnum = {}));
})(DeptPersonsInChargeResult || (DeptPersonsInChargeResult = {}));
/**
 * @export
 * @namespace EchoResult
 */


export var EchoResult;

(function (EchoResult) {
  /**
   * @export
   * @enum {string}
   */
  let StatusEnum;

  (function (StatusEnum) {
    StatusEnum["Success"] = "success";
    StatusEnum["Failure"] = "failure";
    StatusEnum["RequiredToChangePassword"] = "required_to_change_password";
  })(StatusEnum = EchoResult.StatusEnum || (EchoResult.StatusEnum = {}));
})(EchoResult || (EchoResult = {}));
/**
 * @export
 * @namespace FatigueChecksResult
 */


export var FatigueChecksResult;

(function (FatigueChecksResult) {
  /**
   * @export
   * @enum {string}
   */
  let StatusEnum;

  (function (StatusEnum) {
    StatusEnum["Success"] = "success";
    StatusEnum["Failure"] = "failure";
    StatusEnum["RequiredToChangePassword"] = "required_to_change_password";
  })(StatusEnum = FatigueChecksResult.StatusEnum || (FatigueChecksResult.StatusEnum = {}));
})(FatigueChecksResult || (FatigueChecksResult = {}));
/**
 * @export
 * @namespace GivenWorkTimeResult
 */


export var GivenWorkTimeResult;

(function (GivenWorkTimeResult) {
  /**
   * @export
   * @enum {string}
   */
  let StatusEnum;

  (function (StatusEnum) {
    StatusEnum["Success"] = "success";
    StatusEnum["Failure"] = "failure";
    StatusEnum["RequiredToChangePassword"] = "required_to_change_password";
  })(StatusEnum = GivenWorkTimeResult.StatusEnum || (GivenWorkTimeResult.StatusEnum = {}));
})(GivenWorkTimeResult || (GivenWorkTimeResult = {}));
/**
 * @export
 * @namespace HealthCheckResult
 */


export var HealthCheckResult;

(function (HealthCheckResult) {
  /**
   * @export
   * @enum {string}
   */
  let StatusEnum;

  (function (StatusEnum) {
    StatusEnum["Success"] = "success";
    StatusEnum["Failure"] = "failure";
    StatusEnum["RequiredToChangePassword"] = "required_to_change_password";
  })(StatusEnum = HealthCheckResult.StatusEnum || (HealthCheckResult.StatusEnum = {}));
})(HealthCheckResult || (HealthCheckResult = {}));
/**
 * @export
 * @namespace JobNoListResult
 */


export var JobNoListResult;

(function (JobNoListResult) {
  /**
   * @export
   * @enum {string}
   */
  let StatusEnum;

  (function (StatusEnum) {
    StatusEnum["Success"] = "success";
    StatusEnum["Failure"] = "failure";
    StatusEnum["RequiredToChangePassword"] = "required_to_change_password";
  })(StatusEnum = JobNoListResult.StatusEnum || (JobNoListResult.StatusEnum = {}));
})(JobNoListResult || (JobNoListResult = {}));
/**
 * @export
 * @namespace KintaiAggregationsResult
 */


export var KintaiAggregationsResult;

(function (KintaiAggregationsResult) {
  /**
   * @export
   * @enum {string}
   */
  let StatusEnum;

  (function (StatusEnum) {
    StatusEnum["Success"] = "success";
    StatusEnum["Failure"] = "failure";
    StatusEnum["RequiredToChangePassword"] = "required_to_change_password";
  })(StatusEnum = KintaiAggregationsResult.StatusEnum || (KintaiAggregationsResult.StatusEnum = {}));
})(KintaiAggregationsResult || (KintaiAggregationsResult = {}));
/**
 * @export
 * @namespace KintaiBunruiEntity
 */


export var KintaiBunruiEntity;

(function (KintaiBunruiEntity) {
  /**
   * @export
   * @enum {string}
   */
  let ValueEnum;

  (function (ValueEnum) {
    ValueEnum["\u516C\u4F11"] = "\u516C\u4F11";
    ValueEnum["\u5E73\u65E5"] = "\u5E73\u65E5";
    ValueEnum["\u51FA\u5F35"] = "\u51FA\u5F35";
    ValueEnum["\u76F4\u51FA"] = "\u76F4\u51FA";
    ValueEnum["\u76F4\u5E30"] = "\u76F4\u5E30";
    ValueEnum["\u80B2\u4ECB\u5728\u5B85"] = "\u80B2\u4ECB\u5728\u5B85";
    ValueEnum["\u30EA\u4F11"] = "\u30EA\u4F11";
    ValueEnum["\u6709\u4F11"] = "\u6709\u4F11";
    ValueEnum["\u524D\u4F11"] = "\u524D\u4F11";
    ValueEnum["\u5F8C\u4F11"] = "\u5F8C\u4F11";
    ValueEnum["\u6642\u5358\u4F11"] = "\u6642\u5358\u4F11";
    ValueEnum["\u4F11\u51FA"] = "\u4F11\u51FA";
    ValueEnum["\u4EE3\u4F11"] = "\u4EE3\u4F11";
    ValueEnum["\u632F\u51FA"] = "\u632F\u51FA";
    ValueEnum["\u632F\u4F11"] = "\u632F\u4F11";
    ValueEnum["\u5FB9\u591C"] = "\u5FB9\u591C";
    ValueEnum["\u5FB9\u4F11"] = "\u5FB9\u4F11";
    ValueEnum["\u7279\u4F11"] = "\u7279\u4F11";
    ValueEnum["\u5A5A\u4F11"] = "\u5A5A\u4F11";
    ValueEnum["\u914D\u51FA\u4F11"] = "\u914D\u51FA\u4F11";
    ValueEnum["\u5FCC\u4F11"] = "\u5FCC\u4F11";
    ValueEnum["\u707D\u4F11"] = "\u707D\u4F11";
    ValueEnum["\u524D\u7279"] = "\u524D\u7279";
    ValueEnum["\u5F8C\u7279"] = "\u5F8C\u7279";
    ValueEnum["\u5916\u51FA"] = "\u5916\u51FA";
    ValueEnum["\u9045\u523B"] = "\u9045\u523B";
    ValueEnum["\u65E9\u9000"] = "\u65E9\u9000";
    ValueEnum["\u6B20\u52E4"] = "\u6B20\u52E4";
    ValueEnum["\u7279\u52E4"] = "\u7279\u52E4";
    ValueEnum["\u751F\u7406\u4F11\u6687"] = "\u751F\u7406\u4F11\u6687";
    ValueEnum["\u7523\u4F11"] = "\u7523\u4F11";
    ValueEnum["\u4ECB\u4F11"] = "\u4ECB\u4F11";
    ValueEnum["\u770B\u8B77\u4F11\u6687"] = "\u770B\u8B77\u4F11\u6687";
    ValueEnum["\u770B\u8B77\u6642\u4F11"] = "\u770B\u8B77\u6642\u4F11";
    ValueEnum["\u5348\u524D\u770B\u4F11"] = "\u5348\u524D\u770B\u4F11";
    ValueEnum["\u5348\u5F8C\u770B\u4F11"] = "\u5348\u5F8C\u770B\u4F11";
    ValueEnum["\u4ECB\u8B77\u4F11\u6687"] = "\u4ECB\u8B77\u4F11\u6687";
    ValueEnum["\u4ECB\u8B77\u6642\u4F11"] = "\u4ECB\u8B77\u6642\u4F11";
    ValueEnum["\u5348\u524D\u4ECB\u4F11"] = "\u5348\u524D\u4ECB\u4F11";
    ValueEnum["\u5348\u5F8C\u4ECB\u4F11"] = "\u5348\u5F8C\u4ECB\u4F11";
    ValueEnum["\u590F\u4F11"] = "\u590F\u4F11";
    ValueEnum["\u30DD\u4F11"] = "\u30DD\u4F11";
    ValueEnum["\u88C1\u5224\u4F11\u6687"] = "\u88C1\u5224\u4F11\u6687";
    ValueEnum["\u6307\u5B9A\u516C\u4F11"] = "\u6307\u5B9A\u516C\u4F11";
    ValueEnum["\u5165\u524D"] = "\u5165\u524D";
    ValueEnum["\u9000\u5F8C"] = "\u9000\u5F8C";
    ValueEnum["\u7DE8\u96C6"] = "\u7DE8\u96C6";
    ValueEnum["\u80B2\u4F11"] = "\u80B2\u4F11";
    ValueEnum["\u4F11\u8077"] = "\u4F11\u8077";
    ValueEnum["\u51FA\u52E4\u505C\u6B62"] = "\u51FA\u52E4\u505C\u6B62";
    ValueEnum["\u7A4D\u7ACB\u4F11\u6687"] = "\u7A4D\u7ACB\u4F11\u6687";
    ValueEnum["A"] = "A\u52E4";
    ValueEnum["B1"] = "B1\u52E4";
    ValueEnum["B2"] = "B2\u52E4";
    ValueEnum["C"] = "C\u52E4";
    ValueEnum["\u660E\u4F11"] = "\u660E\u4F11";
  })(ValueEnum = KintaiBunruiEntity.ValueEnum || (KintaiBunruiEntity.ValueEnum = {}));
})(KintaiBunruiEntity || (KintaiBunruiEntity = {}));
/**
 * @export
 * @namespace KintaiDepartmentCloseResult
 */


export var KintaiDepartmentCloseResult;

(function (KintaiDepartmentCloseResult) {
  /**
   * @export
   * @enum {string}
   */
  let StatusEnum;

  (function (StatusEnum) {
    StatusEnum["Success"] = "success";
    StatusEnum["Failure"] = "failure";
    StatusEnum["RequiredToChangePassword"] = "required_to_change_password";
  })(StatusEnum = KintaiDepartmentCloseResult.StatusEnum || (KintaiDepartmentCloseResult.StatusEnum = {}));
})(KintaiDepartmentCloseResult || (KintaiDepartmentCloseResult = {}));
/**
 * @export
 * @namespace KintaiDepartmentCloseStatusResult
 */


export var KintaiDepartmentCloseStatusResult;

(function (KintaiDepartmentCloseStatusResult) {
  /**
   * @export
   * @enum {string}
   */
  let StatusEnum;

  (function (StatusEnum) {
    StatusEnum["Success"] = "success";
    StatusEnum["Failure"] = "failure";
    StatusEnum["RequiredToChangePassword"] = "required_to_change_password";
  })(StatusEnum = KintaiDepartmentCloseStatusResult.StatusEnum || (KintaiDepartmentCloseStatusResult.StatusEnum = {}));
})(KintaiDepartmentCloseStatusResult || (KintaiDepartmentCloseStatusResult = {}));
/**
 * @export
 * @namespace KintaiDepartmentReopenResult
 */


export var KintaiDepartmentReopenResult;

(function (KintaiDepartmentReopenResult) {
  /**
   * @export
   * @enum {string}
   */
  let StatusEnum;

  (function (StatusEnum) {
    StatusEnum["Success"] = "success";
    StatusEnum["Failure"] = "failure";
    StatusEnum["RequiredToChangePassword"] = "required_to_change_password";
  })(StatusEnum = KintaiDepartmentReopenResult.StatusEnum || (KintaiDepartmentReopenResult.StatusEnum = {}));
})(KintaiDepartmentReopenResult || (KintaiDepartmentReopenResult = {}));
/**
 * @export
 * @namespace KintaiExportsResult
 */


export var KintaiExportsResult;

(function (KintaiExportsResult) {
  /**
   * @export
   * @enum {string}
   */
  let StatusEnum;

  (function (StatusEnum) {
    StatusEnum["Success"] = "success";
    StatusEnum["Failure"] = "failure";
    StatusEnum["RequiredToChangePassword"] = "required_to_change_password";
  })(StatusEnum = KintaiExportsResult.StatusEnum || (KintaiExportsResult.StatusEnum = {}));
})(KintaiExportsResult || (KintaiExportsResult = {}));
/**
 * @export
 * @namespace KintaiLawOverReportsResult
 */


export var KintaiLawOverReportsResult;

(function (KintaiLawOverReportsResult) {
  /**
   * @export
   * @enum {string}
   */
  let StatusEnum;

  (function (StatusEnum) {
    StatusEnum["Success"] = "success";
    StatusEnum["Failure"] = "failure";
    StatusEnum["RequiredToChangePassword"] = "required_to_change_password";
  })(StatusEnum = KintaiLawOverReportsResult.StatusEnum || (KintaiLawOverReportsResult.StatusEnum = {}));
})(KintaiLawOverReportsResult || (KintaiLawOverReportsResult = {}));
/**
 * @export
 * @namespace KintaiReportResult
 */


export var KintaiReportResult;

(function (KintaiReportResult) {
  /**
   * @export
   * @enum {string}
   */
  let StatusEnum;

  (function (StatusEnum) {
    StatusEnum["Success"] = "success";
    StatusEnum["Failure"] = "failure";
    StatusEnum["RequiredToChangePassword"] = "required_to_change_password";
  })(StatusEnum = KintaiReportResult.StatusEnum || (KintaiReportResult.StatusEnum = {}));
})(KintaiReportResult || (KintaiReportResult = {}));
/**
 * @export
 * @namespace KintaiReportsResult
 */


export var KintaiReportsResult;

(function (KintaiReportsResult) {
  /**
   * @export
   * @enum {string}
   */
  let StatusEnum;

  (function (StatusEnum) {
    StatusEnum["Success"] = "success";
    StatusEnum["Failure"] = "failure";
    StatusEnum["RequiredToChangePassword"] = "required_to_change_password";
  })(StatusEnum = KintaiReportsResult.StatusEnum || (KintaiReportsResult.StatusEnum = {}));
})(KintaiReportsResult || (KintaiReportsResult = {}));
/**
 * @export
 * @namespace KintaiTransDayReportsResult
 */


export var KintaiTransDayReportsResult;

(function (KintaiTransDayReportsResult) {
  /**
   * @export
   * @enum {string}
   */
  let StatusEnum;

  (function (StatusEnum) {
    StatusEnum["Success"] = "success";
    StatusEnum["Failure"] = "failure";
    StatusEnum["RequiredToChangePassword"] = "required_to_change_password";
  })(StatusEnum = KintaiTransDayReportsResult.StatusEnum || (KintaiTransDayReportsResult.StatusEnum = {}));
})(KintaiTransDayReportsResult || (KintaiTransDayReportsResult = {}));
/**
 * @export
 * @namespace LoginResult
 */


export var LoginResult;

(function (LoginResult) {
  /**
   * @export
   * @enum {string}
   */
  let StatusEnum;

  (function (StatusEnum) {
    StatusEnum["Success"] = "success";
    StatusEnum["Failure"] = "failure";
    StatusEnum["RequiredToChangePassword"] = "required_to_change_password";
  })(StatusEnum = LoginResult.StatusEnum || (LoginResult.StatusEnum = {}));
})(LoginResult || (LoginResult = {}));
/**
 * @export
 * @namespace MailStateEntity
 */


export var MailStateEntity;

(function (MailStateEntity) {
  /**
   * @export
   * @enum {string}
   */
  let ValueEnum;

  (function (ValueEnum) {
    ValueEnum["_0"] = "0";
    ValueEnum["_1"] = "1";
    ValueEnum["_2"] = "2";
    ValueEnum["_3"] = "3";
  })(ValueEnum = MailStateEntity.ValueEnum || (MailStateEntity.ValueEnum = {}));
})(MailStateEntity || (MailStateEntity = {}));
/**
 * @export
 * @namespace MeFatigueChecksResult
 */


export var MeFatigueChecksResult;

(function (MeFatigueChecksResult) {
  /**
   * @export
   * @enum {string}
   */
  let StatusEnum;

  (function (StatusEnum) {
    StatusEnum["Success"] = "success";
    StatusEnum["Failure"] = "failure";
    StatusEnum["RequiredToChangePassword"] = "required_to_change_password";
  })(StatusEnum = MeFatigueChecksResult.StatusEnum || (MeFatigueChecksResult.StatusEnum = {}));
})(MeFatigueChecksResult || (MeFatigueChecksResult = {}));
/**
 * @export
 * @namespace MePhysicianInterviewsResult
 */


export var MePhysicianInterviewsResult;

(function (MePhysicianInterviewsResult) {
  /**
   * @export
   * @enum {string}
   */
  let StatusEnum;

  (function (StatusEnum) {
    StatusEnum["Success"] = "success";
    StatusEnum["Failure"] = "failure";
    StatusEnum["RequiredToChangePassword"] = "required_to_change_password";
  })(StatusEnum = MePhysicianInterviewsResult.StatusEnum || (MePhysicianInterviewsResult.StatusEnum = {}));
})(MePhysicianInterviewsResult || (MePhysicianInterviewsResult = {}));
/**
 * @export
 * @namespace MonthlyApplicationsResult
 */


export var MonthlyApplicationsResult;

(function (MonthlyApplicationsResult) {
  /**
   * @export
   * @enum {string}
   */
  let StatusEnum;

  (function (StatusEnum) {
    StatusEnum["Success"] = "success";
    StatusEnum["Failure"] = "failure";
    StatusEnum["RequiredToChangePassword"] = "required_to_change_password";
  })(StatusEnum = MonthlyApplicationsResult.StatusEnum || (MonthlyApplicationsResult.StatusEnum = {}));
})(MonthlyApplicationsResult || (MonthlyApplicationsResult = {}));
/**
 * @export
 * @namespace MonthlyErrorEntity
 */


export var MonthlyErrorEntity;

(function (MonthlyErrorEntity) {
  /**
   * @export
   * @enum {string}
   */
  let LevelEnum;

  (function (LevelEnum) {
    LevelEnum["ERROR"] = "ERROR";
    LevelEnum["WARN"] = "WARN";
    LevelEnum["INFO"] = "INFO";
  })(LevelEnum = MonthlyErrorEntity.LevelEnum || (MonthlyErrorEntity.LevelEnum = {}));
})(MonthlyErrorEntity || (MonthlyErrorEntity = {}));
/**
 * @export
 * @namespace MonthlyReportAttributesEditEntity
 */


export var MonthlyReportAttributesEditEntity;

(function (MonthlyReportAttributesEditEntity) {
  /**
   * @export
   * @enum {string}
   */
  let WorkingTypeEnum;

  (function (WorkingTypeEnum) {
    WorkingTypeEnum["_0"] = "0";
    WorkingTypeEnum["_1"] = "1";
    WorkingTypeEnum["_2"] = "2";
  })(WorkingTypeEnum = MonthlyReportAttributesEditEntity.WorkingTypeEnum || (MonthlyReportAttributesEditEntity.WorkingTypeEnum = {}));
})(MonthlyReportAttributesEditEntity || (MonthlyReportAttributesEditEntity = {}));
/**
 * @export
 * @namespace NotificationResult
 */


export var NotificationResult;

(function (NotificationResult) {
  /**
   * @export
   * @enum {string}
   */
  let StatusEnum;

  (function (StatusEnum) {
    StatusEnum["Success"] = "success";
    StatusEnum["Failure"] = "failure";
    StatusEnum["RequiredToChangePassword"] = "required_to_change_password";
  })(StatusEnum = NotificationResult.StatusEnum || (NotificationResult.StatusEnum = {}));
})(NotificationResult || (NotificationResult = {}));
/**
 * @export
 * @namespace NotificationsResult
 */


export var NotificationsResult;

(function (NotificationsResult) {
  /**
   * @export
   * @enum {string}
   */
  let StatusEnum;

  (function (StatusEnum) {
    StatusEnum["Success"] = "success";
    StatusEnum["Failure"] = "failure";
    StatusEnum["RequiredToChangePassword"] = "required_to_change_password";
  })(StatusEnum = NotificationsResult.StatusEnum || (NotificationsResult.StatusEnum = {}));
})(NotificationsResult || (NotificationsResult = {}));
/**
 * @export
 * @namespace OidcProvidersResult
 */


export var OidcProvidersResult;

(function (OidcProvidersResult) {
  /**
   * @export
   * @enum {string}
   */
  let StatusEnum;

  (function (StatusEnum) {
    StatusEnum["Success"] = "success";
    StatusEnum["Failure"] = "failure";
    StatusEnum["RequiredToChangePassword"] = "required_to_change_password";
  })(StatusEnum = OidcProvidersResult.StatusEnum || (OidcProvidersResult.StatusEnum = {}));
})(OidcProvidersResult || (OidcProvidersResult = {}));
/**
 * @export
 * @namespace PaidVacationsResult
 */


export var PaidVacationsResult;

(function (PaidVacationsResult) {
  /**
   * @export
   * @enum {string}
   */
  let StatusEnum;

  (function (StatusEnum) {
    StatusEnum["Success"] = "success";
    StatusEnum["Failure"] = "failure";
    StatusEnum["RequiredToChangePassword"] = "required_to_change_password";
  })(StatusEnum = PaidVacationsResult.StatusEnum || (PaidVacationsResult.StatusEnum = {}));
})(PaidVacationsResult || (PaidVacationsResult = {}));
/**
 * @export
 * @namespace PersonalMonthlyDashboardResult
 */


export var PersonalMonthlyDashboardResult;

(function (PersonalMonthlyDashboardResult) {
  /**
   * @export
   * @enum {string}
   */
  let StatusEnum;

  (function (StatusEnum) {
    StatusEnum["Success"] = "success";
    StatusEnum["Failure"] = "failure";
    StatusEnum["RequiredToChangePassword"] = "required_to_change_password";
  })(StatusEnum = PersonalMonthlyDashboardResult.StatusEnum || (PersonalMonthlyDashboardResult.StatusEnum = {}));
})(PersonalMonthlyDashboardResult || (PersonalMonthlyDashboardResult = {}));
/**
 * @export
 * @namespace PersonalNendoDashboardResult
 */


export var PersonalNendoDashboardResult;

(function (PersonalNendoDashboardResult) {
  /**
   * @export
   * @enum {string}
   */
  let StatusEnum;

  (function (StatusEnum) {
    StatusEnum["Success"] = "success";
    StatusEnum["Failure"] = "failure";
    StatusEnum["RequiredToChangePassword"] = "required_to_change_password";
  })(StatusEnum = PersonalNendoDashboardResult.StatusEnum || (PersonalNendoDashboardResult.StatusEnum = {}));
})(PersonalNendoDashboardResult || (PersonalNendoDashboardResult = {}));
/**
 * @export
 * @namespace PhysicianInterviewEditEntity
 */


export var PhysicianInterviewEditEntity;

(function (PhysicianInterviewEditEntity) {
  /**
   * @export
   * @enum {string}
   */
  let ReceptionStatusEnum;

  (function (ReceptionStatusEnum) {
    ReceptionStatusEnum["_0"] = "0";
    ReceptionStatusEnum["_1"] = "1";
    ReceptionStatusEnum["_2"] = "2";
    ReceptionStatusEnum["_3"] = "3";
  })(ReceptionStatusEnum = PhysicianInterviewEditEntity.ReceptionStatusEnum || (PhysicianInterviewEditEntity.ReceptionStatusEnum = {}));
})(PhysicianInterviewEditEntity || (PhysicianInterviewEditEntity = {}));
/**
 * @export
 * @namespace PhysicianInterviewsResult
 */


export var PhysicianInterviewsResult;

(function (PhysicianInterviewsResult) {
  /**
   * @export
   * @enum {string}
   */
  let StatusEnum;

  (function (StatusEnum) {
    StatusEnum["Success"] = "success";
    StatusEnum["Failure"] = "failure";
    StatusEnum["RequiredToChangePassword"] = "required_to_change_password";
  })(StatusEnum = PhysicianInterviewsResult.StatusEnum || (PhysicianInterviewsResult.StatusEnum = {}));
})(PhysicianInterviewsResult || (PhysicianInterviewsResult = {}));
/**
 * @export
 * @namespace PositionEntity
 */


export var PositionEntity;

(function (PositionEntity) {
  /**
   * @export
   * @enum {string}
   */
  let ValueEnum;

  (function (ValueEnum) {
    ValueEnum["_0"] = "0";
    ValueEnum["_1"] = "1";
  })(ValueEnum = PositionEntity.ValueEnum || (PositionEntity.ValueEnum = {}));
})(PositionEntity || (PositionEntity = {}));
/**
 * @export
 * @namespace ProjectCloseStatusListResult
 */


export var ProjectCloseStatusListResult;

(function (ProjectCloseStatusListResult) {
  /**
   * @export
   * @enum {string}
   */
  let StatusEnum;

  (function (StatusEnum) {
    StatusEnum["Success"] = "success";
    StatusEnum["Failure"] = "failure";
    StatusEnum["RequiredToChangePassword"] = "required_to_change_password";
  })(StatusEnum = ProjectCloseStatusListResult.StatusEnum || (ProjectCloseStatusListResult.StatusEnum = {}));
})(ProjectCloseStatusListResult || (ProjectCloseStatusListResult = {}));
/**
 * @export
 * @namespace RankAttrEditEntity
 */


export var RankAttrEditEntity;

(function (RankAttrEditEntity) {
  /**
   * @export
   * @enum {string}
   */
  let PositionEnum;

  (function (PositionEnum) {
    PositionEnum["_0"] = "0";
    PositionEnum["_1"] = "1";
  })(PositionEnum = RankAttrEditEntity.PositionEnum || (RankAttrEditEntity.PositionEnum = {}));
  /**
   * @export
   * @enum {string}
   */


  let RoleEnum;

  (function (RoleEnum) {
    RoleEnum["Admin"] = "admin";
    RoleEnum["Exective"] = "exective";
    RoleEnum["Nopj"] = "nopj";
    RoleEnum["Manager"] = "manager";
    RoleEnum["Clerk"] = "clerk";
    RoleEnum["Pl"] = "pl";
    RoleEnum["User"] = "user";
    RoleEnum["Sport"] = "sport";
    RoleEnum["Temp"] = "temp";
  })(RoleEnum = RankAttrEditEntity.RoleEnum || (RankAttrEditEntity.RoleEnum = {}));
  /**
   * @export
   * @enum {string}
   */


  let WorkingTypeEnum;

  (function (WorkingTypeEnum) {
    WorkingTypeEnum["_0"] = "0";
    WorkingTypeEnum["_1"] = "1";
    WorkingTypeEnum["_2"] = "2";
  })(WorkingTypeEnum = RankAttrEditEntity.WorkingTypeEnum || (RankAttrEditEntity.WorkingTypeEnum = {}));
})(RankAttrEditEntity || (RankAttrEditEntity = {}));
/**
 * @export
 * @namespace RankAttrListResult
 */


export var RankAttrListResult;

(function (RankAttrListResult) {
  /**
   * @export
   * @enum {string}
   */
  let StatusEnum;

  (function (StatusEnum) {
    StatusEnum["Success"] = "success";
    StatusEnum["Failure"] = "failure";
    StatusEnum["RequiredToChangePassword"] = "required_to_change_password";
  })(StatusEnum = RankAttrListResult.StatusEnum || (RankAttrListResult.StatusEnum = {}));
})(RankAttrListResult || (RankAttrListResult = {}));
/**
 * @export
 * @namespace RankAttrResult
 */


export var RankAttrResult;

(function (RankAttrResult) {
  /**
   * @export
   * @enum {string}
   */
  let StatusEnum;

  (function (StatusEnum) {
    StatusEnum["Success"] = "success";
    StatusEnum["Failure"] = "failure";
    StatusEnum["RequiredToChangePassword"] = "required_to_change_password";
  })(StatusEnum = RankAttrResult.StatusEnum || (RankAttrResult.StatusEnum = {}));
})(RankAttrResult || (RankAttrResult = {}));
/**
 * @export
 * @namespace ReceptionStatusEntity
 */


export var ReceptionStatusEntity;

(function (ReceptionStatusEntity) {
  /**
   * @export
   * @enum {string}
   */
  let ValueEnum;

  (function (ValueEnum) {
    ValueEnum["_0"] = "0";
    ValueEnum["_1"] = "1";
    ValueEnum["_2"] = "2";
    ValueEnum["_3"] = "3";
  })(ValueEnum = ReceptionStatusEntity.ValueEnum || (ReceptionStatusEntity.ValueEnum = {}));
})(ReceptionStatusEntity || (ReceptionStatusEntity = {}));
/**
 * @export
 * @namespace RegularDailyReportEditEntity
 */


export var RegularDailyReportEditEntity;

(function (RegularDailyReportEditEntity) {
  /**
   * @export
   * @enum {string}
   */
  let TypesEnum;

  (function (TypesEnum) {
    TypesEnum["\u516C\u4F11"] = "\u516C\u4F11";
    TypesEnum["\u5E73\u65E5"] = "\u5E73\u65E5";
    TypesEnum["\u51FA\u5F35"] = "\u51FA\u5F35";
    TypesEnum["\u76F4\u51FA"] = "\u76F4\u51FA";
    TypesEnum["\u76F4\u5E30"] = "\u76F4\u5E30";
    TypesEnum["\u80B2\u4ECB\u5728\u5B85"] = "\u80B2\u4ECB\u5728\u5B85";
    TypesEnum["\u30EA\u4F11"] = "\u30EA\u4F11";
    TypesEnum["\u6709\u4F11"] = "\u6709\u4F11";
    TypesEnum["\u524D\u4F11"] = "\u524D\u4F11";
    TypesEnum["\u5F8C\u4F11"] = "\u5F8C\u4F11";
    TypesEnum["\u6642\u5358\u4F11"] = "\u6642\u5358\u4F11";
    TypesEnum["\u4F11\u51FA"] = "\u4F11\u51FA";
    TypesEnum["\u4EE3\u4F11"] = "\u4EE3\u4F11";
    TypesEnum["\u632F\u51FA"] = "\u632F\u51FA";
    TypesEnum["\u632F\u4F11"] = "\u632F\u4F11";
    TypesEnum["\u5FB9\u591C"] = "\u5FB9\u591C";
    TypesEnum["\u5FB9\u4F11"] = "\u5FB9\u4F11";
    TypesEnum["\u7279\u4F11"] = "\u7279\u4F11";
    TypesEnum["\u5A5A\u4F11"] = "\u5A5A\u4F11";
    TypesEnum["\u914D\u51FA\u4F11"] = "\u914D\u51FA\u4F11";
    TypesEnum["\u5FCC\u4F11"] = "\u5FCC\u4F11";
    TypesEnum["\u707D\u4F11"] = "\u707D\u4F11";
    TypesEnum["\u524D\u7279"] = "\u524D\u7279";
    TypesEnum["\u5F8C\u7279"] = "\u5F8C\u7279";
    TypesEnum["\u5916\u51FA"] = "\u5916\u51FA";
    TypesEnum["\u9045\u523B"] = "\u9045\u523B";
    TypesEnum["\u65E9\u9000"] = "\u65E9\u9000";
    TypesEnum["\u6B20\u52E4"] = "\u6B20\u52E4";
    TypesEnum["\u7279\u52E4"] = "\u7279\u52E4";
    TypesEnum["\u751F\u7406\u4F11\u6687"] = "\u751F\u7406\u4F11\u6687";
    TypesEnum["\u7523\u4F11"] = "\u7523\u4F11";
    TypesEnum["\u4ECB\u4F11"] = "\u4ECB\u4F11";
    TypesEnum["\u770B\u8B77\u4F11\u6687"] = "\u770B\u8B77\u4F11\u6687";
    TypesEnum["\u770B\u8B77\u6642\u4F11"] = "\u770B\u8B77\u6642\u4F11";
    TypesEnum["\u5348\u524D\u770B\u4F11"] = "\u5348\u524D\u770B\u4F11";
    TypesEnum["\u5348\u5F8C\u770B\u4F11"] = "\u5348\u5F8C\u770B\u4F11";
    TypesEnum["\u4ECB\u8B77\u4F11\u6687"] = "\u4ECB\u8B77\u4F11\u6687";
    TypesEnum["\u4ECB\u8B77\u6642\u4F11"] = "\u4ECB\u8B77\u6642\u4F11";
    TypesEnum["\u5348\u524D\u4ECB\u4F11"] = "\u5348\u524D\u4ECB\u4F11";
    TypesEnum["\u5348\u5F8C\u4ECB\u4F11"] = "\u5348\u5F8C\u4ECB\u4F11";
    TypesEnum["\u590F\u4F11"] = "\u590F\u4F11";
    TypesEnum["\u30DD\u4F11"] = "\u30DD\u4F11";
    TypesEnum["\u88C1\u5224\u4F11\u6687"] = "\u88C1\u5224\u4F11\u6687";
    TypesEnum["\u6307\u5B9A\u516C\u4F11"] = "\u6307\u5B9A\u516C\u4F11";
    TypesEnum["\u5165\u524D"] = "\u5165\u524D";
    TypesEnum["\u9000\u5F8C"] = "\u9000\u5F8C";
    TypesEnum["\u7DE8\u96C6"] = "\u7DE8\u96C6";
    TypesEnum["\u80B2\u4F11"] = "\u80B2\u4F11";
    TypesEnum["\u4F11\u8077"] = "\u4F11\u8077";
    TypesEnum["\u51FA\u52E4\u505C\u6B62"] = "\u51FA\u52E4\u505C\u6B62";
    TypesEnum["\u7A4D\u7ACB\u4F11\u6687"] = "\u7A4D\u7ACB\u4F11\u6687";
    TypesEnum["A"] = "A\u52E4";
    TypesEnum["B1"] = "B1\u52E4";
    TypesEnum["B2"] = "B2\u52E4";
    TypesEnum["C"] = "C\u52E4";
    TypesEnum["\u660E\u4F11"] = "\u660E\u4F11";
  })(TypesEnum = RegularDailyReportEditEntity.TypesEnum || (RegularDailyReportEditEntity.TypesEnum = {}));
  /**
   * @export
   * @enum {string}
   */


  let UnusedRestTimesEnum;

  (function (UnusedRestTimesEnum) {
    UnusedRestTimesEnum["_01300200"] = "REST_0130_0200";
    UnusedRestTimesEnum["_05000530"] = "REST_0500_0530";
    UnusedRestTimesEnum["_08300900"] = "REST_0830_0900";
    UnusedRestTimesEnum["_12001300"] = "REST_1200_1300";
    UnusedRestTimesEnum["_18001830"] = "REST_1800_1830";
    UnusedRestTimesEnum["_21002130"] = "REST_2100_2130";
    UnusedRestTimesEnum["_25302600"] = "REST_2530_2600";
    UnusedRestTimesEnum["_29002930"] = "REST_2900_2930";
    UnusedRestTimesEnum["_32303300"] = "REST_3230_3300";
    UnusedRestTimesEnum["_36003700"] = "REST_3600_3700";
    UnusedRestTimesEnum["_42004230"] = "REST_4200_4230";
    UnusedRestTimesEnum["_45004530"] = "REST_4500_4530";
    UnusedRestTimesEnum["_49305000"] = "REST_4930_5000";
    UnusedRestTimesEnum["_53005330"] = "REST_5300_5330";
  })(UnusedRestTimesEnum = RegularDailyReportEditEntity.UnusedRestTimesEnum || (RegularDailyReportEditEntity.UnusedRestTimesEnum = {}));
  /**
   * @export
   * @enum {string}
   */


  let WorkplacesEnum;

  (function (WorkplacesEnum) {
    WorkplacesEnum["ZAITAKULE4H"] = "WORKPLACE_ZAITAKU_LE_4H";
    WorkplacesEnum["ZAITAKUGT4H"] = "WORKPLACE_ZAITAKU_GT_4H";
    WorkplacesEnum["SYUSSYA"] = "WORKPLACE_SYUSSYA";
    WorkplacesEnum["SONOTA"] = "WORKPLACE_SONOTA";
  })(WorkplacesEnum = RegularDailyReportEditEntity.WorkplacesEnum || (RegularDailyReportEditEntity.WorkplacesEnum = {}));
})(RegularDailyReportEditEntity || (RegularDailyReportEditEntity = {}));
/**
 * @export
 * @namespace RestTimeEntity
 */


export var RestTimeEntity;

(function (RestTimeEntity) {
  /**
   * @export
   * @enum {string}
   */
  let ValueEnum;

  (function (ValueEnum) {
    ValueEnum["_01300200"] = "REST_0130_0200";
    ValueEnum["_05000530"] = "REST_0500_0530";
    ValueEnum["_08300900"] = "REST_0830_0900";
    ValueEnum["_12001300"] = "REST_1200_1300";
    ValueEnum["_18001830"] = "REST_1800_1830";
    ValueEnum["_21002130"] = "REST_2100_2130";
    ValueEnum["_25302600"] = "REST_2530_2600";
    ValueEnum["_29002930"] = "REST_2900_2930";
    ValueEnum["_32303300"] = "REST_3230_3300";
    ValueEnum["_36003700"] = "REST_3600_3700";
    ValueEnum["_42004230"] = "REST_4200_4230";
    ValueEnum["_45004530"] = "REST_4500_4530";
    ValueEnum["_49305000"] = "REST_4930_5000";
    ValueEnum["_53005330"] = "REST_5300_5330";
  })(ValueEnum = RestTimeEntity.ValueEnum || (RestTimeEntity.ValueEnum = {}));
})(RestTimeEntity || (RestTimeEntity = {}));
/**
 * @export
 * @namespace RoleEntity
 */


export var RoleEntity;

(function (RoleEntity) {
  /**
   * @export
   * @enum {string}
   */
  let ValueEnum;

  (function (ValueEnum) {
    ValueEnum["Admin"] = "admin";
    ValueEnum["Exective"] = "exective";
    ValueEnum["Nopj"] = "nopj";
    ValueEnum["Manager"] = "manager";
    ValueEnum["Clerk"] = "clerk";
    ValueEnum["Pl"] = "pl";
    ValueEnum["User"] = "user";
    ValueEnum["Sport"] = "sport";
    ValueEnum["Temp"] = "temp";
  })(ValueEnum = RoleEntity.ValueEnum || (RoleEntity.ValueEnum = {}));
})(RoleEntity || (RoleEntity = {}));
/**
 * @export
 * @namespace SPortDailyReportEditEntity
 */


export var SPortDailyReportEditEntity;

(function (SPortDailyReportEditEntity) {
  /**
   * @export
   * @enum {string}
   */
  let TypesEnum;

  (function (TypesEnum) {
    TypesEnum["\u516C\u4F11"] = "\u516C\u4F11";
    TypesEnum["\u5E73\u65E5"] = "\u5E73\u65E5";
    TypesEnum["\u51FA\u5F35"] = "\u51FA\u5F35";
    TypesEnum["\u76F4\u51FA"] = "\u76F4\u51FA";
    TypesEnum["\u76F4\u5E30"] = "\u76F4\u5E30";
    TypesEnum["\u80B2\u4ECB\u5728\u5B85"] = "\u80B2\u4ECB\u5728\u5B85";
    TypesEnum["\u30EA\u4F11"] = "\u30EA\u4F11";
    TypesEnum["\u6709\u4F11"] = "\u6709\u4F11";
    TypesEnum["\u524D\u4F11"] = "\u524D\u4F11";
    TypesEnum["\u5F8C\u4F11"] = "\u5F8C\u4F11";
    TypesEnum["\u6642\u5358\u4F11"] = "\u6642\u5358\u4F11";
    TypesEnum["\u4F11\u51FA"] = "\u4F11\u51FA";
    TypesEnum["\u4EE3\u4F11"] = "\u4EE3\u4F11";
    TypesEnum["\u632F\u51FA"] = "\u632F\u51FA";
    TypesEnum["\u632F\u4F11"] = "\u632F\u4F11";
    TypesEnum["\u5FB9\u591C"] = "\u5FB9\u591C";
    TypesEnum["\u5FB9\u4F11"] = "\u5FB9\u4F11";
    TypesEnum["\u7279\u4F11"] = "\u7279\u4F11";
    TypesEnum["\u5A5A\u4F11"] = "\u5A5A\u4F11";
    TypesEnum["\u914D\u51FA\u4F11"] = "\u914D\u51FA\u4F11";
    TypesEnum["\u5FCC\u4F11"] = "\u5FCC\u4F11";
    TypesEnum["\u707D\u4F11"] = "\u707D\u4F11";
    TypesEnum["\u524D\u7279"] = "\u524D\u7279";
    TypesEnum["\u5F8C\u7279"] = "\u5F8C\u7279";
    TypesEnum["\u5916\u51FA"] = "\u5916\u51FA";
    TypesEnum["\u9045\u523B"] = "\u9045\u523B";
    TypesEnum["\u65E9\u9000"] = "\u65E9\u9000";
    TypesEnum["\u6B20\u52E4"] = "\u6B20\u52E4";
    TypesEnum["\u7279\u52E4"] = "\u7279\u52E4";
    TypesEnum["\u751F\u7406\u4F11\u6687"] = "\u751F\u7406\u4F11\u6687";
    TypesEnum["\u7523\u4F11"] = "\u7523\u4F11";
    TypesEnum["\u4ECB\u4F11"] = "\u4ECB\u4F11";
    TypesEnum["\u770B\u8B77\u4F11\u6687"] = "\u770B\u8B77\u4F11\u6687";
    TypesEnum["\u770B\u8B77\u6642\u4F11"] = "\u770B\u8B77\u6642\u4F11";
    TypesEnum["\u5348\u524D\u770B\u4F11"] = "\u5348\u524D\u770B\u4F11";
    TypesEnum["\u5348\u5F8C\u770B\u4F11"] = "\u5348\u5F8C\u770B\u4F11";
    TypesEnum["\u4ECB\u8B77\u4F11\u6687"] = "\u4ECB\u8B77\u4F11\u6687";
    TypesEnum["\u4ECB\u8B77\u6642\u4F11"] = "\u4ECB\u8B77\u6642\u4F11";
    TypesEnum["\u5348\u524D\u4ECB\u4F11"] = "\u5348\u524D\u4ECB\u4F11";
    TypesEnum["\u5348\u5F8C\u4ECB\u4F11"] = "\u5348\u5F8C\u4ECB\u4F11";
    TypesEnum["\u590F\u4F11"] = "\u590F\u4F11";
    TypesEnum["\u30DD\u4F11"] = "\u30DD\u4F11";
    TypesEnum["\u88C1\u5224\u4F11\u6687"] = "\u88C1\u5224\u4F11\u6687";
    TypesEnum["\u6307\u5B9A\u516C\u4F11"] = "\u6307\u5B9A\u516C\u4F11";
    TypesEnum["\u5165\u524D"] = "\u5165\u524D";
    TypesEnum["\u9000\u5F8C"] = "\u9000\u5F8C";
    TypesEnum["\u7DE8\u96C6"] = "\u7DE8\u96C6";
    TypesEnum["\u80B2\u4F11"] = "\u80B2\u4F11";
    TypesEnum["\u4F11\u8077"] = "\u4F11\u8077";
    TypesEnum["\u51FA\u52E4\u505C\u6B62"] = "\u51FA\u52E4\u505C\u6B62";
    TypesEnum["\u7A4D\u7ACB\u4F11\u6687"] = "\u7A4D\u7ACB\u4F11\u6687";
    TypesEnum["A"] = "A\u52E4";
    TypesEnum["B1"] = "B1\u52E4";
    TypesEnum["B2"] = "B2\u52E4";
    TypesEnum["C"] = "C\u52E4";
    TypesEnum["\u660E\u4F11"] = "\u660E\u4F11";
  })(TypesEnum = SPortDailyReportEditEntity.TypesEnum || (SPortDailyReportEditEntity.TypesEnum = {}));
})(SPortDailyReportEditEntity || (SPortDailyReportEditEntity = {}));
/**
 * @export
 * @namespace UserAbsenceEntity
 */


export var UserAbsenceEntity;

(function (UserAbsenceEntity) {
  /**
   * @export
   * @enum {string}
   */
  let TypeEnum;

  (function (TypeEnum) {
    TypeEnum["\u4F11\u8077"] = "\u4F11\u8077";
    TypeEnum["\u80B2\u4F11"] = "\u80B2\u4F11";
    TypeEnum["\u4ECB\u4F11"] = "\u4ECB\u4F11";
    TypeEnum["\u51FA\u52E4\u505C\u6B62"] = "\u51FA\u52E4\u505C\u6B62";
    TypeEnum["\u7A4D\u7ACB\u4F11\u6687"] = "\u7A4D\u7ACB\u4F11\u6687";
  })(TypeEnum = UserAbsenceEntity.TypeEnum || (UserAbsenceEntity.TypeEnum = {}));
})(UserAbsenceEntity || (UserAbsenceEntity = {}));
/**
 * @export
 * @namespace UserDoorHistoriesResult
 */


export var UserDoorHistoriesResult;

(function (UserDoorHistoriesResult) {
  /**
   * @export
   * @enum {string}
   */
  let StatusEnum;

  (function (StatusEnum) {
    StatusEnum["Success"] = "success";
    StatusEnum["Failure"] = "failure";
    StatusEnum["RequiredToChangePassword"] = "required_to_change_password";
  })(StatusEnum = UserDoorHistoriesResult.StatusEnum || (UserDoorHistoriesResult.StatusEnum = {}));
})(UserDoorHistoriesResult || (UserDoorHistoriesResult = {}));
/**
 * @export
 * @namespace UserEditEntity
 */


export var UserEditEntity;

(function (UserEditEntity) {
  /**
   * @export
   * @enum {string}
   */
  let RolesEnum;

  (function (RolesEnum) {
    RolesEnum["Admin"] = "admin";
    RolesEnum["Exective"] = "exective";
    RolesEnum["Nopj"] = "nopj";
    RolesEnum["Manager"] = "manager";
    RolesEnum["Clerk"] = "clerk";
    RolesEnum["Pl"] = "pl";
    RolesEnum["User"] = "user";
    RolesEnum["Sport"] = "sport";
    RolesEnum["Temp"] = "temp";
  })(RolesEnum = UserEditEntity.RolesEnum || (UserEditEntity.RolesEnum = {}));
})(UserEditEntity || (UserEditEntity = {}));
/**
 * @export
 * @namespace UserResult
 */


export var UserResult;

(function (UserResult) {
  /**
   * @export
   * @enum {string}
   */
  let StatusEnum;

  (function (StatusEnum) {
    StatusEnum["Success"] = "success";
    StatusEnum["Failure"] = "failure";
    StatusEnum["RequiredToChangePassword"] = "required_to_change_password";
  })(StatusEnum = UserResult.StatusEnum || (UserResult.StatusEnum = {}));
})(UserResult || (UserResult = {}));
/**
 * @export
 * @namespace UsersResult
 */


export var UsersResult;

(function (UsersResult) {
  /**
   * @export
   * @enum {string}
   */
  let StatusEnum;

  (function (StatusEnum) {
    StatusEnum["Success"] = "success";
    StatusEnum["Failure"] = "failure";
    StatusEnum["RequiredToChangePassword"] = "required_to_change_password";
  })(StatusEnum = UsersResult.StatusEnum || (UsersResult.StatusEnum = {}));
})(UsersResult || (UsersResult = {}));
/**
 * @export
 * @namespace WorkingTypeEntity
 */


export var WorkingTypeEntity;

(function (WorkingTypeEntity) {
  /**
   * @export
   * @enum {string}
   */
  let ValueEnum;

  (function (ValueEnum) {
    ValueEnum["_0"] = "0";
    ValueEnum["_1"] = "1";
    ValueEnum["_2"] = "2";
  })(ValueEnum = WorkingTypeEntity.ValueEnum || (WorkingTypeEntity.ValueEnum = {}));
})(WorkingTypeEntity || (WorkingTypeEntity = {}));
/**
 * @export
 * @namespace WorkplaceEntity
 */


export var WorkplaceEntity;

(function (WorkplaceEntity) {
  /**
   * @export
   * @enum {string}
   */
  let ValueEnum;

  (function (ValueEnum) {
    ValueEnum["ZAITAKULE4H"] = "WORKPLACE_ZAITAKU_LE_4H";
    ValueEnum["ZAITAKUGT4H"] = "WORKPLACE_ZAITAKU_GT_4H";
    ValueEnum["SYUSSYA"] = "WORKPLACE_SYUSSYA";
    ValueEnum["SONOTA"] = "WORKPLACE_SONOTA";
  })(ValueEnum = WorkplaceEntity.ValueEnum || (WorkplaceEntity.ValueEnum = {}));
})(WorkplaceEntity || (WorkplaceEntity = {}));
/**
 * CalendarControllerApi - fetch parameter creator
 * @export
 */


export const CalendarControllerApiFetchParamCreator = function (configuration) {
  return {
    /**
     *
     * @summary カレンダー取得
     * @param {number} nendo 年度
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCalendar(nendo, options = {}) {
      // verify required parameter 'nendo' is not null or undefined
      if (nendo === null || nendo === undefined) {
        throw new RequiredError('nendo', 'Required parameter nendo was null or undefined when calling getCalendar.');
      }

      const localVarPath = `/api/v1/calendar/{nendo}`.replace(`{${"nendo"}}`, encodeURIComponent(String(nendo)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'GET'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     *
     * @summary カレンダー更新
     * @param {number} nendo 年度
     * @param {CalendarEntity} CalendarEntity 更新する日付リスト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateCalendar(nendo, CalendarEntity, options = {}) {
      // verify required parameter 'nendo' is not null or undefined
      if (nendo === null || nendo === undefined) {
        throw new RequiredError('nendo', 'Required parameter nendo was null or undefined when calling updateCalendar.');
      } // verify required parameter 'CalendarEntity' is not null or undefined


      if (CalendarEntity === null || CalendarEntity === undefined) {
        throw new RequiredError('CalendarEntity', 'Required parameter CalendarEntity was null or undefined when calling updateCalendar.');
      }

      const localVarPath = `/api/v1/calendar/{nendo}`.replace(`{${"nendo"}}`, encodeURIComponent(String(nendo)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'POST'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';
      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization = "CalendarEntity" !== "string" || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(CalendarEntity || {}) : CalendarEntity || "";
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    }

  };
};
/**
 * CalendarControllerApi - functional programming interface
 * @export
 */

export const CalendarControllerApiFp = function (configuration) {
  return {
    /**
     *
     * @summary カレンダー取得
     * @param {number} nendo 年度
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCalendar(nendo, options) {
      const localVarFetchArgs = CalendarControllerApiFetchParamCreator(configuration).getCalendar(nendo, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },

    /**
     *
     * @summary カレンダー更新
     * @param {number} nendo 年度
     * @param {CalendarEntity} CalendarEntity 更新する日付リスト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateCalendar(nendo, CalendarEntity, options) {
      const localVarFetchArgs = CalendarControllerApiFetchParamCreator(configuration).updateCalendar(nendo, CalendarEntity, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    }

  };
};
/**
 * CalendarControllerApi - factory interface
 * @export
 */

export const CalendarControllerApiFactory = function (configuration, fetch, basePath) {
  return {
    /**
     *
     * @summary カレンダー取得
     * @param {number} nendo 年度
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCalendar(nendo, options) {
      return CalendarControllerApiFp(configuration).getCalendar(nendo, options)(fetch, basePath);
    },

    /**
     *
     * @summary カレンダー更新
     * @param {number} nendo 年度
     * @param {CalendarEntity} CalendarEntity 更新する日付リスト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateCalendar(nendo, CalendarEntity, options) {
      return CalendarControllerApiFp(configuration).updateCalendar(nendo, CalendarEntity, options)(fetch, basePath);
    }

  };
};
/**
 * CalendarControllerApi - object-oriented interface
 * @export
 * @class CalendarControllerApi
 * @extends {BaseAPI}
 */

export class CalendarControllerApi extends BaseAPI {
  /**
   *
   * @summary カレンダー取得
   * @param {number} nendo 年度
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CalendarControllerApi
   */
  getCalendar(nendo, options) {
    return CalendarControllerApiFp(this.configuration).getCalendar(nendo, options)(this.fetch, this.basePath);
  }
  /**
   *
   * @summary カレンダー更新
   * @param {number} nendo 年度
   * @param {CalendarEntity} CalendarEntity 更新する日付リスト
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CalendarControllerApi
   */


  updateCalendar(nendo, CalendarEntity, options) {
    return CalendarControllerApiFp(this.configuration).updateCalendar(nendo, CalendarEntity, options)(this.fetch, this.basePath);
  }

}
/**
 * CompanyDashboardControllerApi - fetch parameter creator
 * @export
 */

export const CompanyDashboardControllerApiFetchParamCreator = function (configuration) {
  return {
    /**
     *
     * @summary 通年系パブリックダッシュボード取得
     * @param {number} nendo 年度
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCompanyDashboardByNendo(nendo, options = {}) {
      // verify required parameter 'nendo' is not null or undefined
      if (nendo === null || nendo === undefined) {
        throw new RequiredError('nendo', 'Required parameter nendo was null or undefined when calling getCompanyDashboardByNendo.');
      }

      const localVarPath = `/api/v1/dashboard/company/by_nendo/{nendo}`.replace(`{${"nendo"}}`, encodeURIComponent(String(nendo)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'GET'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    }

  };
};
/**
 * CompanyDashboardControllerApi - functional programming interface
 * @export
 */

export const CompanyDashboardControllerApiFp = function (configuration) {
  return {
    /**
     *
     * @summary 通年系パブリックダッシュボード取得
     * @param {number} nendo 年度
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCompanyDashboardByNendo(nendo, options) {
      const localVarFetchArgs = CompanyDashboardControllerApiFetchParamCreator(configuration).getCompanyDashboardByNendo(nendo, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    }

  };
};
/**
 * CompanyDashboardControllerApi - factory interface
 * @export
 */

export const CompanyDashboardControllerApiFactory = function (configuration, fetch, basePath) {
  return {
    /**
     *
     * @summary 通年系パブリックダッシュボード取得
     * @param {number} nendo 年度
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCompanyDashboardByNendo(nendo, options) {
      return CompanyDashboardControllerApiFp(configuration).getCompanyDashboardByNendo(nendo, options)(fetch, basePath);
    }

  };
};
/**
 * CompanyDashboardControllerApi - object-oriented interface
 * @export
 * @class CompanyDashboardControllerApi
 * @extends {BaseAPI}
 */

export class CompanyDashboardControllerApi extends BaseAPI {
  /**
   *
   * @summary 通年系パブリックダッシュボード取得
   * @param {number} nendo 年度
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompanyDashboardControllerApi
   */
  getCompanyDashboardByNendo(nendo, options) {
    return CompanyDashboardControllerApiFp(this.configuration).getCompanyDashboardByNendo(nendo, options)(this.fetch, this.basePath);
  }

}
/**
 * DefaultResetPasswordSuggestionControllerApi - fetch parameter creator
 * @export
 */

export const DefaultResetPasswordSuggestionControllerApiFetchParamCreator = function (configuration) {
  return {
    /**
     *
     * @summary リセットパスワードデフォルト値提案
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    suggestDefaultResetPassword(options = {}) {
      const localVarPath = `/api/v1/password/defaultResetPassword`;
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'GET'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    }

  };
};
/**
 * DefaultResetPasswordSuggestionControllerApi - functional programming interface
 * @export
 */

export const DefaultResetPasswordSuggestionControllerApiFp = function (configuration) {
  return {
    /**
     *
     * @summary リセットパスワードデフォルト値提案
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    suggestDefaultResetPassword(options) {
      const localVarFetchArgs = DefaultResetPasswordSuggestionControllerApiFetchParamCreator(configuration).suggestDefaultResetPassword(options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    }

  };
};
/**
 * DefaultResetPasswordSuggestionControllerApi - factory interface
 * @export
 */

export const DefaultResetPasswordSuggestionControllerApiFactory = function (configuration, fetch, basePath) {
  return {
    /**
     *
     * @summary リセットパスワードデフォルト値提案
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    suggestDefaultResetPassword(options) {
      return DefaultResetPasswordSuggestionControllerApiFp(configuration).suggestDefaultResetPassword(options)(fetch, basePath);
    }

  };
};
/**
 * DefaultResetPasswordSuggestionControllerApi - object-oriented interface
 * @export
 * @class DefaultResetPasswordSuggestionControllerApi
 * @extends {BaseAPI}
 */

export class DefaultResetPasswordSuggestionControllerApi extends BaseAPI {
  /**
   *
   * @summary リセットパスワードデフォルト値提案
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultResetPasswordSuggestionControllerApi
   */
  suggestDefaultResetPassword(options) {
    return DefaultResetPasswordSuggestionControllerApiFp(this.configuration).suggestDefaultResetPassword(options)(this.fetch, this.basePath);
  }

}
/**
 * DepartmentsControllerApi - fetch parameter creator
 * @export
 */

export const DepartmentsControllerApiFetchParamCreator = function (configuration) {
  return {
    /**
     *
     * @summary 部門情報取得
     * @param {number} nendo 年度
     * @param {string} depCode 部門コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDepartment(nendo, depCode, options = {}) {
      // verify required parameter 'nendo' is not null or undefined
      if (nendo === null || nendo === undefined) {
        throw new RequiredError('nendo', 'Required parameter nendo was null or undefined when calling getDepartment.');
      } // verify required parameter 'depCode' is not null or undefined


      if (depCode === null || depCode === undefined) {
        throw new RequiredError('depCode', 'Required parameter depCode was null or undefined when calling getDepartment.');
      }

      const localVarPath = `/api/v1/departments/{nendo}/{depCode}`.replace(`{${"nendo"}}`, encodeURIComponent(String(nendo))).replace(`{${"depCode"}}`, encodeURIComponent(String(depCode)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'GET'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     *
     * @summary 全部門情報取得
     * @param {number} nendo 年度
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDepartments(nendo, options = {}) {
      // verify required parameter 'nendo' is not null or undefined
      if (nendo === null || nendo === undefined) {
        throw new RequiredError('nendo', 'Required parameter nendo was null or undefined when calling getDepartments.');
      }

      const localVarPath = `/api/v1/departments/{nendo}`.replace(`{${"nendo"}}`, encodeURIComponent(String(nendo)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'GET'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     *
     * @summary 部門勤怠管理責任者取得
     * @param {number} nendo 年度
     * @param {string} [depCode] 部門コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPersonsInCharge(nendo, depCode, options = {}) {
      // verify required parameter 'nendo' is not null or undefined
      if (nendo === null || nendo === undefined) {
        throw new RequiredError('nendo', 'Required parameter nendo was null or undefined when calling getPersonsInCharge.');
      }

      const localVarPath = `/api/v1/departments/{nendo}/persons_in_charge`.replace(`{${"nendo"}}`, encodeURIComponent(String(nendo)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'GET'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      if (depCode !== undefined) {
        localVarQueryParameter['depCode'] = depCode;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     *
     * @summary 部門勤怠管理責任者更新
     * @param {number} nendo 年度
     * @param {DeptPersonsInChargeUpdateRequest} DeptPersonsInChargeUpdateRequest 部門勤怠管理責任者リスト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePersonsInCharge(nendo, DeptPersonsInChargeUpdateRequest, options = {}) {
      // verify required parameter 'nendo' is not null or undefined
      if (nendo === null || nendo === undefined) {
        throw new RequiredError('nendo', 'Required parameter nendo was null or undefined when calling updatePersonsInCharge.');
      } // verify required parameter 'DeptPersonsInChargeUpdateRequest' is not null or undefined


      if (DeptPersonsInChargeUpdateRequest === null || DeptPersonsInChargeUpdateRequest === undefined) {
        throw new RequiredError('DeptPersonsInChargeUpdateRequest', 'Required parameter DeptPersonsInChargeUpdateRequest was null or undefined when calling updatePersonsInCharge.');
      }

      const localVarPath = `/api/v1/departments/{nendo}/persons_in_charge`.replace(`{${"nendo"}}`, encodeURIComponent(String(nendo)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'POST'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';
      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization = "DeptPersonsInChargeUpdateRequest" !== "string" || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(DeptPersonsInChargeUpdateRequest || {}) : DeptPersonsInChargeUpdateRequest || "";
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    }

  };
};
/**
 * DepartmentsControllerApi - functional programming interface
 * @export
 */

export const DepartmentsControllerApiFp = function (configuration) {
  return {
    /**
     *
     * @summary 部門情報取得
     * @param {number} nendo 年度
     * @param {string} depCode 部門コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDepartment(nendo, depCode, options) {
      const localVarFetchArgs = DepartmentsControllerApiFetchParamCreator(configuration).getDepartment(nendo, depCode, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },

    /**
     *
     * @summary 全部門情報取得
     * @param {number} nendo 年度
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDepartments(nendo, options) {
      const localVarFetchArgs = DepartmentsControllerApiFetchParamCreator(configuration).getDepartments(nendo, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },

    /**
     *
     * @summary 部門勤怠管理責任者取得
     * @param {number} nendo 年度
     * @param {string} [depCode] 部門コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPersonsInCharge(nendo, depCode, options) {
      const localVarFetchArgs = DepartmentsControllerApiFetchParamCreator(configuration).getPersonsInCharge(nendo, depCode, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },

    /**
     *
     * @summary 部門勤怠管理責任者更新
     * @param {number} nendo 年度
     * @param {DeptPersonsInChargeUpdateRequest} DeptPersonsInChargeUpdateRequest 部門勤怠管理責任者リスト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePersonsInCharge(nendo, DeptPersonsInChargeUpdateRequest, options) {
      const localVarFetchArgs = DepartmentsControllerApiFetchParamCreator(configuration).updatePersonsInCharge(nendo, DeptPersonsInChargeUpdateRequest, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    }

  };
};
/**
 * DepartmentsControllerApi - factory interface
 * @export
 */

export const DepartmentsControllerApiFactory = function (configuration, fetch, basePath) {
  return {
    /**
     *
     * @summary 部門情報取得
     * @param {number} nendo 年度
     * @param {string} depCode 部門コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDepartment(nendo, depCode, options) {
      return DepartmentsControllerApiFp(configuration).getDepartment(nendo, depCode, options)(fetch, basePath);
    },

    /**
     *
     * @summary 全部門情報取得
     * @param {number} nendo 年度
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDepartments(nendo, options) {
      return DepartmentsControllerApiFp(configuration).getDepartments(nendo, options)(fetch, basePath);
    },

    /**
     *
     * @summary 部門勤怠管理責任者取得
     * @param {number} nendo 年度
     * @param {string} [depCode] 部門コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPersonsInCharge(nendo, depCode, options) {
      return DepartmentsControllerApiFp(configuration).getPersonsInCharge(nendo, depCode, options)(fetch, basePath);
    },

    /**
     *
     * @summary 部門勤怠管理責任者更新
     * @param {number} nendo 年度
     * @param {DeptPersonsInChargeUpdateRequest} DeptPersonsInChargeUpdateRequest 部門勤怠管理責任者リスト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePersonsInCharge(nendo, DeptPersonsInChargeUpdateRequest, options) {
      return DepartmentsControllerApiFp(configuration).updatePersonsInCharge(nendo, DeptPersonsInChargeUpdateRequest, options)(fetch, basePath);
    }

  };
};
/**
 * DepartmentsControllerApi - object-oriented interface
 * @export
 * @class DepartmentsControllerApi
 * @extends {BaseAPI}
 */

export class DepartmentsControllerApi extends BaseAPI {
  /**
   *
   * @summary 部門情報取得
   * @param {number} nendo 年度
   * @param {string} depCode 部門コード
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DepartmentsControllerApi
   */
  getDepartment(nendo, depCode, options) {
    return DepartmentsControllerApiFp(this.configuration).getDepartment(nendo, depCode, options)(this.fetch, this.basePath);
  }
  /**
   *
   * @summary 全部門情報取得
   * @param {number} nendo 年度
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DepartmentsControllerApi
   */


  getDepartments(nendo, options) {
    return DepartmentsControllerApiFp(this.configuration).getDepartments(nendo, options)(this.fetch, this.basePath);
  }
  /**
   *
   * @summary 部門勤怠管理責任者取得
   * @param {number} nendo 年度
   * @param {string} [depCode] 部門コード
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DepartmentsControllerApi
   */


  getPersonsInCharge(nendo, depCode, options) {
    return DepartmentsControllerApiFp(this.configuration).getPersonsInCharge(nendo, depCode, options)(this.fetch, this.basePath);
  }
  /**
   *
   * @summary 部門勤怠管理責任者更新
   * @param {number} nendo 年度
   * @param {DeptPersonsInChargeUpdateRequest} DeptPersonsInChargeUpdateRequest 部門勤怠管理責任者リスト
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DepartmentsControllerApi
   */


  updatePersonsInCharge(nendo, DeptPersonsInChargeUpdateRequest, options) {
    return DepartmentsControllerApiFp(this.configuration).updatePersonsInCharge(nendo, DeptPersonsInChargeUpdateRequest, options)(this.fetch, this.basePath);
  }

}
/**
 * DoorHistoriesControllerApi - fetch parameter creator
 * @export
 */

export const DoorHistoriesControllerApiFetchParamCreator = function (configuration) {
  return {
    /**
     *
     * @summary ユーザ入退室履歴取得
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserDoorHistories(userId, year, month, options = {}) {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling getUserDoorHistories.');
      } // verify required parameter 'year' is not null or undefined


      if (year === null || year === undefined) {
        throw new RequiredError('year', 'Required parameter year was null or undefined when calling getUserDoorHistories.');
      } // verify required parameter 'month' is not null or undefined


      if (month === null || month === undefined) {
        throw new RequiredError('month', 'Required parameter month was null or undefined when calling getUserDoorHistories.');
      }

      const localVarPath = `/api/v1/doorhistories/{userId}/{year}/{month}`.replace(`{${"userId"}}`, encodeURIComponent(String(userId))).replace(`{${"year"}}`, encodeURIComponent(String(year))).replace(`{${"month"}}`, encodeURIComponent(String(month)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'GET'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    }

  };
};
/**
 * DoorHistoriesControllerApi - functional programming interface
 * @export
 */

export const DoorHistoriesControllerApiFp = function (configuration) {
  return {
    /**
     *
     * @summary ユーザ入退室履歴取得
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserDoorHistories(userId, year, month, options) {
      const localVarFetchArgs = DoorHistoriesControllerApiFetchParamCreator(configuration).getUserDoorHistories(userId, year, month, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    }

  };
};
/**
 * DoorHistoriesControllerApi - factory interface
 * @export
 */

export const DoorHistoriesControllerApiFactory = function (configuration, fetch, basePath) {
  return {
    /**
     *
     * @summary ユーザ入退室履歴取得
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserDoorHistories(userId, year, month, options) {
      return DoorHistoriesControllerApiFp(configuration).getUserDoorHistories(userId, year, month, options)(fetch, basePath);
    }

  };
};
/**
 * DoorHistoriesControllerApi - object-oriented interface
 * @export
 * @class DoorHistoriesControllerApi
 * @extends {BaseAPI}
 */

export class DoorHistoriesControllerApi extends BaseAPI {
  /**
   *
   * @summary ユーザ入退室履歴取得
   * @param {string} userId ユーザID
   * @param {number} year 年
   * @param {number} month 月
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DoorHistoriesControllerApi
   */
  getUserDoorHistories(userId, year, month, options) {
    return DoorHistoriesControllerApiFp(this.configuration).getUserDoorHistories(userId, year, month, options)(this.fetch, this.basePath);
  }

}
/**
 * EchoControllerApi - fetch parameter creator
 * @export
 */

export const EchoControllerApiFetchParamCreator = function (configuration) {
  return {
    /**
     *
     * @summary 応答チェック用
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEcho(options = {}) {
      const localVarPath = `/api/v1/echo`;
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'GET'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     *
     * @summary ログイン後の応答用
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLoginEcho(options = {}) {
      const localVarPath = `/api/v1/login/echo`;
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'GET'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    }

  };
};
/**
 * EchoControllerApi - functional programming interface
 * @export
 */

export const EchoControllerApiFp = function (configuration) {
  return {
    /**
     *
     * @summary 応答チェック用
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEcho(options) {
      const localVarFetchArgs = EchoControllerApiFetchParamCreator(configuration).getEcho(options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },

    /**
     *
     * @summary ログイン後の応答用
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLoginEcho(options) {
      const localVarFetchArgs = EchoControllerApiFetchParamCreator(configuration).getLoginEcho(options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    }

  };
};
/**
 * EchoControllerApi - factory interface
 * @export
 */

export const EchoControllerApiFactory = function (configuration, fetch, basePath) {
  return {
    /**
     *
     * @summary 応答チェック用
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEcho(options) {
      return EchoControllerApiFp(configuration).getEcho(options)(fetch, basePath);
    },

    /**
     *
     * @summary ログイン後の応答用
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLoginEcho(options) {
      return EchoControllerApiFp(configuration).getLoginEcho(options)(fetch, basePath);
    }

  };
};
/**
 * EchoControllerApi - object-oriented interface
 * @export
 * @class EchoControllerApi
 * @extends {BaseAPI}
 */

export class EchoControllerApi extends BaseAPI {
  /**
   *
   * @summary 応答チェック用
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EchoControllerApi
   */
  getEcho(options) {
    return EchoControllerApiFp(this.configuration).getEcho(options)(this.fetch, this.basePath);
  }
  /**
   *
   * @summary ログイン後の応答用
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EchoControllerApi
   */


  getLoginEcho(options) {
    return EchoControllerApiFp(this.configuration).getLoginEcho(options)(this.fetch, this.basePath);
  }

}
/**
 * FatigueChecksControllerApi - fetch parameter creator
 * @export
 */

export const FatigueChecksControllerApiFetchParamCreator = function (configuration) {
  return {
    /**
     *
     * @summary 疲労蓄積度チェック情報作成
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {FatigueCheckEditEntity} FatigueCheckEditEntity 作成する疲労蓄積度チェック情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createFatigueCheck(userId, year, month, FatigueCheckEditEntity, options = {}) {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling createFatigueCheck.');
      } // verify required parameter 'year' is not null or undefined


      if (year === null || year === undefined) {
        throw new RequiredError('year', 'Required parameter year was null or undefined when calling createFatigueCheck.');
      } // verify required parameter 'month' is not null or undefined


      if (month === null || month === undefined) {
        throw new RequiredError('month', 'Required parameter month was null or undefined when calling createFatigueCheck.');
      } // verify required parameter 'FatigueCheckEditEntity' is not null or undefined


      if (FatigueCheckEditEntity === null || FatigueCheckEditEntity === undefined) {
        throw new RequiredError('FatigueCheckEditEntity', 'Required parameter FatigueCheckEditEntity was null or undefined when calling createFatigueCheck.');
      }

      const localVarPath = `/api/v1/fatigue_checks/{userId}/{year}/{month}/create`.replace(`{${"userId"}}`, encodeURIComponent(String(userId))).replace(`{${"year"}}`, encodeURIComponent(String(year))).replace(`{${"month"}}`, encodeURIComponent(String(month)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'POST'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';
      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization = "FatigueCheckEditEntity" !== "string" || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(FatigueCheckEditEntity || {}) : FatigueCheckEditEntity || "";
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     *
     * @summary 疲労蓄積度チェック情報削除
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteFatigueCheck(userId, year, month, options = {}) {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling deleteFatigueCheck.');
      } // verify required parameter 'year' is not null or undefined


      if (year === null || year === undefined) {
        throw new RequiredError('year', 'Required parameter year was null or undefined when calling deleteFatigueCheck.');
      } // verify required parameter 'month' is not null or undefined


      if (month === null || month === undefined) {
        throw new RequiredError('month', 'Required parameter month was null or undefined when calling deleteFatigueCheck.');
      }

      const localVarPath = `/api/v1/fatigue_checks/{userId}/{year}/{month}`.replace(`{${"userId"}}`, encodeURIComponent(String(userId))).replace(`{${"year"}}`, encodeURIComponent(String(year))).replace(`{${"month"}}`, encodeURIComponent(String(month)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'DELETE'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     *
     * @summary 疲労蓄積度チェック対象者一覧取得
     * @param {number} nendo 年度
     * @param {string} [depCode] 部門コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFatigueChecks(nendo, depCode, options = {}) {
      // verify required parameter 'nendo' is not null or undefined
      if (nendo === null || nendo === undefined) {
        throw new RequiredError('nendo', 'Required parameter nendo was null or undefined when calling getFatigueChecks.');
      }

      const localVarPath = `/api/v1/fatigue_checks/{nendo}`.replace(`{${"nendo"}}`, encodeURIComponent(String(nendo)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'GET'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      if (depCode !== undefined) {
        localVarQueryParameter['depCode'] = depCode;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     *
     * @summary 疲労蓄積度チェック情報更新
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {FatigueCheckEditEntity} FatigueCheckEditEntity 更新する疲労蓄積度チェック情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateFatigueCheck(userId, year, month, FatigueCheckEditEntity, options = {}) {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling updateFatigueCheck.');
      } // verify required parameter 'year' is not null or undefined


      if (year === null || year === undefined) {
        throw new RequiredError('year', 'Required parameter year was null or undefined when calling updateFatigueCheck.');
      } // verify required parameter 'month' is not null or undefined


      if (month === null || month === undefined) {
        throw new RequiredError('month', 'Required parameter month was null or undefined when calling updateFatigueCheck.');
      } // verify required parameter 'FatigueCheckEditEntity' is not null or undefined


      if (FatigueCheckEditEntity === null || FatigueCheckEditEntity === undefined) {
        throw new RequiredError('FatigueCheckEditEntity', 'Required parameter FatigueCheckEditEntity was null or undefined when calling updateFatigueCheck.');
      }

      const localVarPath = `/api/v1/fatigue_checks/{userId}/{year}/{month}`.replace(`{${"userId"}}`, encodeURIComponent(String(userId))).replace(`{${"year"}}`, encodeURIComponent(String(year))).replace(`{${"month"}}`, encodeURIComponent(String(month)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'POST'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';
      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization = "FatigueCheckEditEntity" !== "string" || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(FatigueCheckEditEntity || {}) : FatigueCheckEditEntity || "";
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    }

  };
};
/**
 * FatigueChecksControllerApi - functional programming interface
 * @export
 */

export const FatigueChecksControllerApiFp = function (configuration) {
  return {
    /**
     *
     * @summary 疲労蓄積度チェック情報作成
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {FatigueCheckEditEntity} FatigueCheckEditEntity 作成する疲労蓄積度チェック情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createFatigueCheck(userId, year, month, FatigueCheckEditEntity, options) {
      const localVarFetchArgs = FatigueChecksControllerApiFetchParamCreator(configuration).createFatigueCheck(userId, year, month, FatigueCheckEditEntity, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },

    /**
     *
     * @summary 疲労蓄積度チェック情報削除
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteFatigueCheck(userId, year, month, options) {
      const localVarFetchArgs = FatigueChecksControllerApiFetchParamCreator(configuration).deleteFatigueCheck(userId, year, month, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },

    /**
     *
     * @summary 疲労蓄積度チェック対象者一覧取得
     * @param {number} nendo 年度
     * @param {string} [depCode] 部門コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFatigueChecks(nendo, depCode, options) {
      const localVarFetchArgs = FatigueChecksControllerApiFetchParamCreator(configuration).getFatigueChecks(nendo, depCode, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },

    /**
     *
     * @summary 疲労蓄積度チェック情報更新
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {FatigueCheckEditEntity} FatigueCheckEditEntity 更新する疲労蓄積度チェック情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateFatigueCheck(userId, year, month, FatigueCheckEditEntity, options) {
      const localVarFetchArgs = FatigueChecksControllerApiFetchParamCreator(configuration).updateFatigueCheck(userId, year, month, FatigueCheckEditEntity, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    }

  };
};
/**
 * FatigueChecksControllerApi - factory interface
 * @export
 */

export const FatigueChecksControllerApiFactory = function (configuration, fetch, basePath) {
  return {
    /**
     *
     * @summary 疲労蓄積度チェック情報作成
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {FatigueCheckEditEntity} FatigueCheckEditEntity 作成する疲労蓄積度チェック情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createFatigueCheck(userId, year, month, FatigueCheckEditEntity, options) {
      return FatigueChecksControllerApiFp(configuration).createFatigueCheck(userId, year, month, FatigueCheckEditEntity, options)(fetch, basePath);
    },

    /**
     *
     * @summary 疲労蓄積度チェック情報削除
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteFatigueCheck(userId, year, month, options) {
      return FatigueChecksControllerApiFp(configuration).deleteFatigueCheck(userId, year, month, options)(fetch, basePath);
    },

    /**
     *
     * @summary 疲労蓄積度チェック対象者一覧取得
     * @param {number} nendo 年度
     * @param {string} [depCode] 部門コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFatigueChecks(nendo, depCode, options) {
      return FatigueChecksControllerApiFp(configuration).getFatigueChecks(nendo, depCode, options)(fetch, basePath);
    },

    /**
     *
     * @summary 疲労蓄積度チェック情報更新
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {FatigueCheckEditEntity} FatigueCheckEditEntity 更新する疲労蓄積度チェック情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateFatigueCheck(userId, year, month, FatigueCheckEditEntity, options) {
      return FatigueChecksControllerApiFp(configuration).updateFatigueCheck(userId, year, month, FatigueCheckEditEntity, options)(fetch, basePath);
    }

  };
};
/**
 * FatigueChecksControllerApi - object-oriented interface
 * @export
 * @class FatigueChecksControllerApi
 * @extends {BaseAPI}
 */

export class FatigueChecksControllerApi extends BaseAPI {
  /**
   *
   * @summary 疲労蓄積度チェック情報作成
   * @param {string} userId ユーザID
   * @param {number} year 年
   * @param {number} month 月
   * @param {FatigueCheckEditEntity} FatigueCheckEditEntity 作成する疲労蓄積度チェック情報
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FatigueChecksControllerApi
   */
  createFatigueCheck(userId, year, month, FatigueCheckEditEntity, options) {
    return FatigueChecksControllerApiFp(this.configuration).createFatigueCheck(userId, year, month, FatigueCheckEditEntity, options)(this.fetch, this.basePath);
  }
  /**
   *
   * @summary 疲労蓄積度チェック情報削除
   * @param {string} userId ユーザID
   * @param {number} year 年
   * @param {number} month 月
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FatigueChecksControllerApi
   */


  deleteFatigueCheck(userId, year, month, options) {
    return FatigueChecksControllerApiFp(this.configuration).deleteFatigueCheck(userId, year, month, options)(this.fetch, this.basePath);
  }
  /**
   *
   * @summary 疲労蓄積度チェック対象者一覧取得
   * @param {number} nendo 年度
   * @param {string} [depCode] 部門コード
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FatigueChecksControllerApi
   */


  getFatigueChecks(nendo, depCode, options) {
    return FatigueChecksControllerApiFp(this.configuration).getFatigueChecks(nendo, depCode, options)(this.fetch, this.basePath);
  }
  /**
   *
   * @summary 疲労蓄積度チェック情報更新
   * @param {string} userId ユーザID
   * @param {number} year 年
   * @param {number} month 月
   * @param {FatigueCheckEditEntity} FatigueCheckEditEntity 更新する疲労蓄積度チェック情報
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FatigueChecksControllerApi
   */


  updateFatigueCheck(userId, year, month, FatigueCheckEditEntity, options) {
    return FatigueChecksControllerApiFp(this.configuration).updateFatigueCheck(userId, year, month, FatigueCheckEditEntity, options)(this.fetch, this.basePath);
  }

}
/**
 * GivenWorkTimeControllerApi - fetch parameter creator
 * @export
 */

export const GivenWorkTimeControllerApiFetchParamCreator = function (configuration) {
  return {
    /**
     *
     * @summary S-Port所定就業時間削除
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSPortGivenWorkTime(year, month, options = {}) {
      // verify required parameter 'year' is not null or undefined
      if (year === null || year === undefined) {
        throw new RequiredError('year', 'Required parameter year was null or undefined when calling deleteSPortGivenWorkTime.');
      } // verify required parameter 'month' is not null or undefined


      if (month === null || month === undefined) {
        throw new RequiredError('month', 'Required parameter month was null or undefined when calling deleteSPortGivenWorkTime.');
      }

      const localVarPath = `/api/v1/calendar/{year}/{month}/worktimes/sport`.replace(`{${"year"}}`, encodeURIComponent(String(year))).replace(`{${"month"}}`, encodeURIComponent(String(month)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'DELETE'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     *
     * @summary S-Port所定就業時間取得
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSPortGivenWorkTime(year, month, options = {}) {
      // verify required parameter 'year' is not null or undefined
      if (year === null || year === undefined) {
        throw new RequiredError('year', 'Required parameter year was null or undefined when calling getSPortGivenWorkTime.');
      } // verify required parameter 'month' is not null or undefined


      if (month === null || month === undefined) {
        throw new RequiredError('month', 'Required parameter month was null or undefined when calling getSPortGivenWorkTime.');
      }

      const localVarPath = `/api/v1/calendar/{year}/{month}/worktimes/sport`.replace(`{${"year"}}`, encodeURIComponent(String(year))).replace(`{${"month"}}`, encodeURIComponent(String(month)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'GET'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     *
     * @summary S-Port所定就業時間更新
     * @param {number} year 年
     * @param {number} month 月
     * @param {GivenWorkTimeEntity} GivenWorkTimeEntity 所定就業時間
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSPortGivenWorkTime(year, month, GivenWorkTimeEntity, options = {}) {
      // verify required parameter 'year' is not null or undefined
      if (year === null || year === undefined) {
        throw new RequiredError('year', 'Required parameter year was null or undefined when calling updateSPortGivenWorkTime.');
      } // verify required parameter 'month' is not null or undefined


      if (month === null || month === undefined) {
        throw new RequiredError('month', 'Required parameter month was null or undefined when calling updateSPortGivenWorkTime.');
      } // verify required parameter 'GivenWorkTimeEntity' is not null or undefined


      if (GivenWorkTimeEntity === null || GivenWorkTimeEntity === undefined) {
        throw new RequiredError('GivenWorkTimeEntity', 'Required parameter GivenWorkTimeEntity was null or undefined when calling updateSPortGivenWorkTime.');
      }

      const localVarPath = `/api/v1/calendar/{year}/{month}/worktimes/sport`.replace(`{${"year"}}`, encodeURIComponent(String(year))).replace(`{${"month"}}`, encodeURIComponent(String(month)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'POST'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';
      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization = "GivenWorkTimeEntity" !== "string" || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(GivenWorkTimeEntity || {}) : GivenWorkTimeEntity || "";
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    }

  };
};
/**
 * GivenWorkTimeControllerApi - functional programming interface
 * @export
 */

export const GivenWorkTimeControllerApiFp = function (configuration) {
  return {
    /**
     *
     * @summary S-Port所定就業時間削除
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSPortGivenWorkTime(year, month, options) {
      const localVarFetchArgs = GivenWorkTimeControllerApiFetchParamCreator(configuration).deleteSPortGivenWorkTime(year, month, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },

    /**
     *
     * @summary S-Port所定就業時間取得
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSPortGivenWorkTime(year, month, options) {
      const localVarFetchArgs = GivenWorkTimeControllerApiFetchParamCreator(configuration).getSPortGivenWorkTime(year, month, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },

    /**
     *
     * @summary S-Port所定就業時間更新
     * @param {number} year 年
     * @param {number} month 月
     * @param {GivenWorkTimeEntity} GivenWorkTimeEntity 所定就業時間
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSPortGivenWorkTime(year, month, GivenWorkTimeEntity, options) {
      const localVarFetchArgs = GivenWorkTimeControllerApiFetchParamCreator(configuration).updateSPortGivenWorkTime(year, month, GivenWorkTimeEntity, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    }

  };
};
/**
 * GivenWorkTimeControllerApi - factory interface
 * @export
 */

export const GivenWorkTimeControllerApiFactory = function (configuration, fetch, basePath) {
  return {
    /**
     *
     * @summary S-Port所定就業時間削除
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSPortGivenWorkTime(year, month, options) {
      return GivenWorkTimeControllerApiFp(configuration).deleteSPortGivenWorkTime(year, month, options)(fetch, basePath);
    },

    /**
     *
     * @summary S-Port所定就業時間取得
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSPortGivenWorkTime(year, month, options) {
      return GivenWorkTimeControllerApiFp(configuration).getSPortGivenWorkTime(year, month, options)(fetch, basePath);
    },

    /**
     *
     * @summary S-Port所定就業時間更新
     * @param {number} year 年
     * @param {number} month 月
     * @param {GivenWorkTimeEntity} GivenWorkTimeEntity 所定就業時間
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSPortGivenWorkTime(year, month, GivenWorkTimeEntity, options) {
      return GivenWorkTimeControllerApiFp(configuration).updateSPortGivenWorkTime(year, month, GivenWorkTimeEntity, options)(fetch, basePath);
    }

  };
};
/**
 * GivenWorkTimeControllerApi - object-oriented interface
 * @export
 * @class GivenWorkTimeControllerApi
 * @extends {BaseAPI}
 */

export class GivenWorkTimeControllerApi extends BaseAPI {
  /**
   *
   * @summary S-Port所定就業時間削除
   * @param {number} year 年
   * @param {number} month 月
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GivenWorkTimeControllerApi
   */
  deleteSPortGivenWorkTime(year, month, options) {
    return GivenWorkTimeControllerApiFp(this.configuration).deleteSPortGivenWorkTime(year, month, options)(this.fetch, this.basePath);
  }
  /**
   *
   * @summary S-Port所定就業時間取得
   * @param {number} year 年
   * @param {number} month 月
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GivenWorkTimeControllerApi
   */


  getSPortGivenWorkTime(year, month, options) {
    return GivenWorkTimeControllerApiFp(this.configuration).getSPortGivenWorkTime(year, month, options)(this.fetch, this.basePath);
  }
  /**
   *
   * @summary S-Port所定就業時間更新
   * @param {number} year 年
   * @param {number} month 月
   * @param {GivenWorkTimeEntity} GivenWorkTimeEntity 所定就業時間
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GivenWorkTimeControllerApi
   */


  updateSPortGivenWorkTime(year, month, GivenWorkTimeEntity, options) {
    return GivenWorkTimeControllerApiFp(this.configuration).updateSPortGivenWorkTime(year, month, GivenWorkTimeEntity, options)(this.fetch, this.basePath);
  }

}
/**
 * HealthCheckControllerApi - fetch parameter creator
 * @export
 */

export const HealthCheckControllerApiFetchParamCreator = function (configuration) {
  return {
    /**
     *
     * @summary ヘルスチェック用
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getHealthCheck(options = {}) {
      const localVarPath = `/api/v1/hc`;
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'GET'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    }

  };
};
/**
 * HealthCheckControllerApi - functional programming interface
 * @export
 */

export const HealthCheckControllerApiFp = function (configuration) {
  return {
    /**
     *
     * @summary ヘルスチェック用
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getHealthCheck(options) {
      const localVarFetchArgs = HealthCheckControllerApiFetchParamCreator(configuration).getHealthCheck(options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    }

  };
};
/**
 * HealthCheckControllerApi - factory interface
 * @export
 */

export const HealthCheckControllerApiFactory = function (configuration, fetch, basePath) {
  return {
    /**
     *
     * @summary ヘルスチェック用
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getHealthCheck(options) {
      return HealthCheckControllerApiFp(configuration).getHealthCheck(options)(fetch, basePath);
    }

  };
};
/**
 * HealthCheckControllerApi - object-oriented interface
 * @export
 * @class HealthCheckControllerApi
 * @extends {BaseAPI}
 */

export class HealthCheckControllerApi extends BaseAPI {
  /**
   *
   * @summary ヘルスチェック用
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HealthCheckControllerApi
   */
  getHealthCheck(options) {
    return HealthCheckControllerApiFp(this.configuration).getHealthCheck(options)(this.fetch, this.basePath);
  }

}
/**
 * JobNosControllerApi - fetch parameter creator
 * @export
 */

export const JobNosControllerApiFetchParamCreator = function (configuration) {
  return {
    /**
     *
     * @summary 利用可能ジョブNo.一覧取得
     * @param {number} year 年
     * @param {number} month 月
     * @param {string} userId ユーザID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAvailableJobNoList(year, month, userId, options = {}) {
      // verify required parameter 'year' is not null or undefined
      if (year === null || year === undefined) {
        throw new RequiredError('year', 'Required parameter year was null or undefined when calling getAvailableJobNoList.');
      } // verify required parameter 'month' is not null or undefined


      if (month === null || month === undefined) {
        throw new RequiredError('month', 'Required parameter month was null or undefined when calling getAvailableJobNoList.');
      } // verify required parameter 'userId' is not null or undefined


      if (userId === null || userId === undefined) {
        throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling getAvailableJobNoList.');
      }

      const localVarPath = `/api/v1/jobnos/{year}/{month}/{userId}`.replace(`{${"year"}}`, encodeURIComponent(String(year))).replace(`{${"month"}}`, encodeURIComponent(String(month))).replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'GET'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    }

  };
};
/**
 * JobNosControllerApi - functional programming interface
 * @export
 */

export const JobNosControllerApiFp = function (configuration) {
  return {
    /**
     *
     * @summary 利用可能ジョブNo.一覧取得
     * @param {number} year 年
     * @param {number} month 月
     * @param {string} userId ユーザID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAvailableJobNoList(year, month, userId, options) {
      const localVarFetchArgs = JobNosControllerApiFetchParamCreator(configuration).getAvailableJobNoList(year, month, userId, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    }

  };
};
/**
 * JobNosControllerApi - factory interface
 * @export
 */

export const JobNosControllerApiFactory = function (configuration, fetch, basePath) {
  return {
    /**
     *
     * @summary 利用可能ジョブNo.一覧取得
     * @param {number} year 年
     * @param {number} month 月
     * @param {string} userId ユーザID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAvailableJobNoList(year, month, userId, options) {
      return JobNosControllerApiFp(configuration).getAvailableJobNoList(year, month, userId, options)(fetch, basePath);
    }

  };
};
/**
 * JobNosControllerApi - object-oriented interface
 * @export
 * @class JobNosControllerApi
 * @extends {BaseAPI}
 */

export class JobNosControllerApi extends BaseAPI {
  /**
   *
   * @summary 利用可能ジョブNo.一覧取得
   * @param {number} year 年
   * @param {number} month 月
   * @param {string} userId ユーザID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobNosControllerApi
   */
  getAvailableJobNoList(year, month, userId, options) {
    return JobNosControllerApiFp(this.configuration).getAvailableJobNoList(year, month, userId, options)(this.fetch, this.basePath);
  }

}
/**
 * KintaiApplicationsControllerApi - fetch parameter creator
 * @export
 */

export const KintaiApplicationsControllerApiFetchParamCreator = function (configuration) {
  return {
    /**
     *
     * @summary 勤怠申請承認
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {number} day 日
     * @param {DailyApplicationApprovalEntity} DailyApplicationApprovalEntity 申請承認情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    approveDailyApplication(userId, year, month, day, DailyApplicationApprovalEntity, options = {}) {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling approveDailyApplication.');
      } // verify required parameter 'year' is not null or undefined


      if (year === null || year === undefined) {
        throw new RequiredError('year', 'Required parameter year was null or undefined when calling approveDailyApplication.');
      } // verify required parameter 'month' is not null or undefined


      if (month === null || month === undefined) {
        throw new RequiredError('month', 'Required parameter month was null or undefined when calling approveDailyApplication.');
      } // verify required parameter 'day' is not null or undefined


      if (day === null || day === undefined) {
        throw new RequiredError('day', 'Required parameter day was null or undefined when calling approveDailyApplication.');
      } // verify required parameter 'DailyApplicationApprovalEntity' is not null or undefined


      if (DailyApplicationApprovalEntity === null || DailyApplicationApprovalEntity === undefined) {
        throw new RequiredError('DailyApplicationApprovalEntity', 'Required parameter DailyApplicationApprovalEntity was null or undefined when calling approveDailyApplication.');
      }

      const localVarPath = `/api/v1/kintai/applications/{userId}/{year}/{month}/{day}/approve`.replace(`{${"userId"}}`, encodeURIComponent(String(userId))).replace(`{${"year"}}`, encodeURIComponent(String(year))).replace(`{${"month"}}`, encodeURIComponent(String(month))).replace(`{${"day"}}`, encodeURIComponent(String(day)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'POST'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';
      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization = "DailyApplicationApprovalEntity" !== "string" || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(DailyApplicationApprovalEntity || {}) : DailyApplicationApprovalEntity || "";
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     *
     * @summary 勤怠申請キャンセル
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {number} day 日
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelDailyApplication(userId, year, month, day, options = {}) {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling cancelDailyApplication.');
      } // verify required parameter 'year' is not null or undefined


      if (year === null || year === undefined) {
        throw new RequiredError('year', 'Required parameter year was null or undefined when calling cancelDailyApplication.');
      } // verify required parameter 'month' is not null or undefined


      if (month === null || month === undefined) {
        throw new RequiredError('month', 'Required parameter month was null or undefined when calling cancelDailyApplication.');
      } // verify required parameter 'day' is not null or undefined


      if (day === null || day === undefined) {
        throw new RequiredError('day', 'Required parameter day was null or undefined when calling cancelDailyApplication.');
      }

      const localVarPath = `/api/v1/kintai/applications/{userId}/{year}/{month}/{day}/cancel`.replace(`{${"userId"}}`, encodeURIComponent(String(userId))).replace(`{${"year"}}`, encodeURIComponent(String(year))).replace(`{${"month"}}`, encodeURIComponent(String(month))).replace(`{${"day"}}`, encodeURIComponent(String(day)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'POST'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     *
     * @summary 日の勤怠申請情報取得
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {number} day 日
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDailyApplication(userId, year, month, day, options = {}) {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling getDailyApplication.');
      } // verify required parameter 'year' is not null or undefined


      if (year === null || year === undefined) {
        throw new RequiredError('year', 'Required parameter year was null or undefined when calling getDailyApplication.');
      } // verify required parameter 'month' is not null or undefined


      if (month === null || month === undefined) {
        throw new RequiredError('month', 'Required parameter month was null or undefined when calling getDailyApplication.');
      } // verify required parameter 'day' is not null or undefined


      if (day === null || day === undefined) {
        throw new RequiredError('day', 'Required parameter day was null or undefined when calling getDailyApplication.');
      }

      const localVarPath = `/api/v1/kintai/applications/{userId}/{year}/{month}/{day}`.replace(`{${"userId"}}`, encodeURIComponent(String(userId))).replace(`{${"year"}}`, encodeURIComponent(String(year))).replace(`{${"month"}}`, encodeURIComponent(String(month))).replace(`{${"day"}}`, encodeURIComponent(String(day)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'GET'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     *
     * @summary 月の勤怠申請情報取得
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMonthlyApplications(userId, year, month, options = {}) {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling getMonthlyApplications.');
      } // verify required parameter 'year' is not null or undefined


      if (year === null || year === undefined) {
        throw new RequiredError('year', 'Required parameter year was null or undefined when calling getMonthlyApplications.');
      } // verify required parameter 'month' is not null or undefined


      if (month === null || month === undefined) {
        throw new RequiredError('month', 'Required parameter month was null or undefined when calling getMonthlyApplications.');
      }

      const localVarPath = `/api/v1/kintai/applications/{userId}/{year}/{month}`.replace(`{${"userId"}}`, encodeURIComponent(String(userId))).replace(`{${"year"}}`, encodeURIComponent(String(year))).replace(`{${"month"}}`, encodeURIComponent(String(month)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'GET'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     *
     * @summary 勤怠申請却下
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {number} day 日
     * @param {DailyApplicationApprovalEntity} DailyApplicationApprovalEntity 申請承認情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rejectDailyApplication(userId, year, month, day, DailyApplicationApprovalEntity, options = {}) {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling rejectDailyApplication.');
      } // verify required parameter 'year' is not null or undefined


      if (year === null || year === undefined) {
        throw new RequiredError('year', 'Required parameter year was null or undefined when calling rejectDailyApplication.');
      } // verify required parameter 'month' is not null or undefined


      if (month === null || month === undefined) {
        throw new RequiredError('month', 'Required parameter month was null or undefined when calling rejectDailyApplication.');
      } // verify required parameter 'day' is not null or undefined


      if (day === null || day === undefined) {
        throw new RequiredError('day', 'Required parameter day was null or undefined when calling rejectDailyApplication.');
      } // verify required parameter 'DailyApplicationApprovalEntity' is not null or undefined


      if (DailyApplicationApprovalEntity === null || DailyApplicationApprovalEntity === undefined) {
        throw new RequiredError('DailyApplicationApprovalEntity', 'Required parameter DailyApplicationApprovalEntity was null or undefined when calling rejectDailyApplication.');
      }

      const localVarPath = `/api/v1/kintai/applications/{userId}/{year}/{month}/{day}/reject`.replace(`{${"userId"}}`, encodeURIComponent(String(userId))).replace(`{${"year"}}`, encodeURIComponent(String(year))).replace(`{${"month"}}`, encodeURIComponent(String(month))).replace(`{${"day"}}`, encodeURIComponent(String(day)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'POST'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';
      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization = "DailyApplicationApprovalEntity" !== "string" || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(DailyApplicationApprovalEntity || {}) : DailyApplicationApprovalEntity || "";
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     *
     * @summary 勤怠申請
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {number} day 日
     * @param {DailyApplicationRequestEntity} DailyApplicationRequestEntity 申請情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    requestDailyApplication(userId, year, month, day, DailyApplicationRequestEntity, options = {}) {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling requestDailyApplication.');
      } // verify required parameter 'year' is not null or undefined


      if (year === null || year === undefined) {
        throw new RequiredError('year', 'Required parameter year was null or undefined when calling requestDailyApplication.');
      } // verify required parameter 'month' is not null or undefined


      if (month === null || month === undefined) {
        throw new RequiredError('month', 'Required parameter month was null or undefined when calling requestDailyApplication.');
      } // verify required parameter 'day' is not null or undefined


      if (day === null || day === undefined) {
        throw new RequiredError('day', 'Required parameter day was null or undefined when calling requestDailyApplication.');
      } // verify required parameter 'DailyApplicationRequestEntity' is not null or undefined


      if (DailyApplicationRequestEntity === null || DailyApplicationRequestEntity === undefined) {
        throw new RequiredError('DailyApplicationRequestEntity', 'Required parameter DailyApplicationRequestEntity was null or undefined when calling requestDailyApplication.');
      }

      const localVarPath = `/api/v1/kintai/applications/{userId}/{year}/{month}/{day}`.replace(`{${"userId"}}`, encodeURIComponent(String(userId))).replace(`{${"year"}}`, encodeURIComponent(String(year))).replace(`{${"month"}}`, encodeURIComponent(String(month))).replace(`{${"day"}}`, encodeURIComponent(String(day)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'POST'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';
      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization = "DailyApplicationRequestEntity" !== "string" || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(DailyApplicationRequestEntity || {}) : DailyApplicationRequestEntity || "";
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    }

  };
};
/**
 * KintaiApplicationsControllerApi - functional programming interface
 * @export
 */

export const KintaiApplicationsControllerApiFp = function (configuration) {
  return {
    /**
     *
     * @summary 勤怠申請承認
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {number} day 日
     * @param {DailyApplicationApprovalEntity} DailyApplicationApprovalEntity 申請承認情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    approveDailyApplication(userId, year, month, day, DailyApplicationApprovalEntity, options) {
      const localVarFetchArgs = KintaiApplicationsControllerApiFetchParamCreator(configuration).approveDailyApplication(userId, year, month, day, DailyApplicationApprovalEntity, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },

    /**
     *
     * @summary 勤怠申請キャンセル
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {number} day 日
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelDailyApplication(userId, year, month, day, options) {
      const localVarFetchArgs = KintaiApplicationsControllerApiFetchParamCreator(configuration).cancelDailyApplication(userId, year, month, day, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },

    /**
     *
     * @summary 日の勤怠申請情報取得
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {number} day 日
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDailyApplication(userId, year, month, day, options) {
      const localVarFetchArgs = KintaiApplicationsControllerApiFetchParamCreator(configuration).getDailyApplication(userId, year, month, day, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },

    /**
     *
     * @summary 月の勤怠申請情報取得
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMonthlyApplications(userId, year, month, options) {
      const localVarFetchArgs = KintaiApplicationsControllerApiFetchParamCreator(configuration).getMonthlyApplications(userId, year, month, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },

    /**
     *
     * @summary 勤怠申請却下
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {number} day 日
     * @param {DailyApplicationApprovalEntity} DailyApplicationApprovalEntity 申請承認情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rejectDailyApplication(userId, year, month, day, DailyApplicationApprovalEntity, options) {
      const localVarFetchArgs = KintaiApplicationsControllerApiFetchParamCreator(configuration).rejectDailyApplication(userId, year, month, day, DailyApplicationApprovalEntity, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },

    /**
     *
     * @summary 勤怠申請
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {number} day 日
     * @param {DailyApplicationRequestEntity} DailyApplicationRequestEntity 申請情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    requestDailyApplication(userId, year, month, day, DailyApplicationRequestEntity, options) {
      const localVarFetchArgs = KintaiApplicationsControllerApiFetchParamCreator(configuration).requestDailyApplication(userId, year, month, day, DailyApplicationRequestEntity, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    }

  };
};
/**
 * KintaiApplicationsControllerApi - factory interface
 * @export
 */

export const KintaiApplicationsControllerApiFactory = function (configuration, fetch, basePath) {
  return {
    /**
     *
     * @summary 勤怠申請承認
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {number} day 日
     * @param {DailyApplicationApprovalEntity} DailyApplicationApprovalEntity 申請承認情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    approveDailyApplication(userId, year, month, day, DailyApplicationApprovalEntity, options) {
      return KintaiApplicationsControllerApiFp(configuration).approveDailyApplication(userId, year, month, day, DailyApplicationApprovalEntity, options)(fetch, basePath);
    },

    /**
     *
     * @summary 勤怠申請キャンセル
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {number} day 日
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelDailyApplication(userId, year, month, day, options) {
      return KintaiApplicationsControllerApiFp(configuration).cancelDailyApplication(userId, year, month, day, options)(fetch, basePath);
    },

    /**
     *
     * @summary 日の勤怠申請情報取得
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {number} day 日
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDailyApplication(userId, year, month, day, options) {
      return KintaiApplicationsControllerApiFp(configuration).getDailyApplication(userId, year, month, day, options)(fetch, basePath);
    },

    /**
     *
     * @summary 月の勤怠申請情報取得
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMonthlyApplications(userId, year, month, options) {
      return KintaiApplicationsControllerApiFp(configuration).getMonthlyApplications(userId, year, month, options)(fetch, basePath);
    },

    /**
     *
     * @summary 勤怠申請却下
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {number} day 日
     * @param {DailyApplicationApprovalEntity} DailyApplicationApprovalEntity 申請承認情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rejectDailyApplication(userId, year, month, day, DailyApplicationApprovalEntity, options) {
      return KintaiApplicationsControllerApiFp(configuration).rejectDailyApplication(userId, year, month, day, DailyApplicationApprovalEntity, options)(fetch, basePath);
    },

    /**
     *
     * @summary 勤怠申請
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {number} day 日
     * @param {DailyApplicationRequestEntity} DailyApplicationRequestEntity 申請情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    requestDailyApplication(userId, year, month, day, DailyApplicationRequestEntity, options) {
      return KintaiApplicationsControllerApiFp(configuration).requestDailyApplication(userId, year, month, day, DailyApplicationRequestEntity, options)(fetch, basePath);
    }

  };
};
/**
 * KintaiApplicationsControllerApi - object-oriented interface
 * @export
 * @class KintaiApplicationsControllerApi
 * @extends {BaseAPI}
 */

export class KintaiApplicationsControllerApi extends BaseAPI {
  /**
   *
   * @summary 勤怠申請承認
   * @param {string} userId ユーザID
   * @param {number} year 年
   * @param {number} month 月
   * @param {number} day 日
   * @param {DailyApplicationApprovalEntity} DailyApplicationApprovalEntity 申請承認情報
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KintaiApplicationsControllerApi
   */
  approveDailyApplication(userId, year, month, day, DailyApplicationApprovalEntity, options) {
    return KintaiApplicationsControllerApiFp(this.configuration).approveDailyApplication(userId, year, month, day, DailyApplicationApprovalEntity, options)(this.fetch, this.basePath);
  }
  /**
   *
   * @summary 勤怠申請キャンセル
   * @param {string} userId ユーザID
   * @param {number} year 年
   * @param {number} month 月
   * @param {number} day 日
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KintaiApplicationsControllerApi
   */


  cancelDailyApplication(userId, year, month, day, options) {
    return KintaiApplicationsControllerApiFp(this.configuration).cancelDailyApplication(userId, year, month, day, options)(this.fetch, this.basePath);
  }
  /**
   *
   * @summary 日の勤怠申請情報取得
   * @param {string} userId ユーザID
   * @param {number} year 年
   * @param {number} month 月
   * @param {number} day 日
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KintaiApplicationsControllerApi
   */


  getDailyApplication(userId, year, month, day, options) {
    return KintaiApplicationsControllerApiFp(this.configuration).getDailyApplication(userId, year, month, day, options)(this.fetch, this.basePath);
  }
  /**
   *
   * @summary 月の勤怠申請情報取得
   * @param {string} userId ユーザID
   * @param {number} year 年
   * @param {number} month 月
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KintaiApplicationsControllerApi
   */


  getMonthlyApplications(userId, year, month, options) {
    return KintaiApplicationsControllerApiFp(this.configuration).getMonthlyApplications(userId, year, month, options)(this.fetch, this.basePath);
  }
  /**
   *
   * @summary 勤怠申請却下
   * @param {string} userId ユーザID
   * @param {number} year 年
   * @param {number} month 月
   * @param {number} day 日
   * @param {DailyApplicationApprovalEntity} DailyApplicationApprovalEntity 申請承認情報
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KintaiApplicationsControllerApi
   */


  rejectDailyApplication(userId, year, month, day, DailyApplicationApprovalEntity, options) {
    return KintaiApplicationsControllerApiFp(this.configuration).rejectDailyApplication(userId, year, month, day, DailyApplicationApprovalEntity, options)(this.fetch, this.basePath);
  }
  /**
   *
   * @summary 勤怠申請
   * @param {string} userId ユーザID
   * @param {number} year 年
   * @param {number} month 月
   * @param {number} day 日
   * @param {DailyApplicationRequestEntity} DailyApplicationRequestEntity 申請情報
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KintaiApplicationsControllerApi
   */


  requestDailyApplication(userId, year, month, day, DailyApplicationRequestEntity, options) {
    return KintaiApplicationsControllerApiFp(this.configuration).requestDailyApplication(userId, year, month, day, DailyApplicationRequestEntity, options)(this.fetch, this.basePath);
  }

}
/**
 * KintaiDepartmentsReportControllerApi - fetch parameter creator
 * @export
 */

export const KintaiDepartmentsReportControllerApiFetchParamCreator = function (configuration) {
  return {
    /**
     *
     * @summary 部門勤怠月締め
     * @param {string} depCode 部門コード
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    closeDepartmentReports(depCode, year, month, options = {}) {
      // verify required parameter 'depCode' is not null or undefined
      if (depCode === null || depCode === undefined) {
        throw new RequiredError('depCode', 'Required parameter depCode was null or undefined when calling closeDepartmentReports.');
      } // verify required parameter 'year' is not null or undefined


      if (year === null || year === undefined) {
        throw new RequiredError('year', 'Required parameter year was null or undefined when calling closeDepartmentReports.');
      } // verify required parameter 'month' is not null or undefined


      if (month === null || month === undefined) {
        throw new RequiredError('month', 'Required parameter month was null or undefined when calling closeDepartmentReports.');
      }

      const localVarPath = `/api/v1/kintai/departments/{depCode}/{year}/{month}/close`.replace(`{${"depCode"}}`, encodeURIComponent(String(depCode))).replace(`{${"year"}}`, encodeURIComponent(String(year))).replace(`{${"month"}}`, encodeURIComponent(String(month)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'POST'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     *
     * @summary 部門勤怠月締めステータス取得
     * @param {string} depCode 部門コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDepartmentReportsCloseStatus(depCode, options = {}) {
      // verify required parameter 'depCode' is not null or undefined
      if (depCode === null || depCode === undefined) {
        throw new RequiredError('depCode', 'Required parameter depCode was null or undefined when calling getDepartmentReportsCloseStatus.');
      }

      const localVarPath = `/api/v1/kintai/departments/{depCode}/close_status`.replace(`{${"depCode"}}`, encodeURIComponent(String(depCode)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'GET'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     *
     * @summary 部門勤怠一覧取得
     * @param {string} depCode 部門コード
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getKintaiReports(depCode, year, month, options = {}) {
      // verify required parameter 'depCode' is not null or undefined
      if (depCode === null || depCode === undefined) {
        throw new RequiredError('depCode', 'Required parameter depCode was null or undefined when calling getKintaiReports.');
      } // verify required parameter 'year' is not null or undefined


      if (year === null || year === undefined) {
        throw new RequiredError('year', 'Required parameter year was null or undefined when calling getKintaiReports.');
      } // verify required parameter 'month' is not null or undefined


      if (month === null || month === undefined) {
        throw new RequiredError('month', 'Required parameter month was null or undefined when calling getKintaiReports.');
      }

      const localVarPath = `/api/v1/kintai/departments/{depCode}/{year}/{month}`.replace(`{${"depCode"}}`, encodeURIComponent(String(depCode))).replace(`{${"year"}}`, encodeURIComponent(String(year))).replace(`{${"month"}}`, encodeURIComponent(String(month)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'GET'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     *
     * @summary 部門勤怠月締め再オープン
     * @param {string} depCode 部門コード
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reopenDepartmentReports(depCode, year, month, options = {}) {
      // verify required parameter 'depCode' is not null or undefined
      if (depCode === null || depCode === undefined) {
        throw new RequiredError('depCode', 'Required parameter depCode was null or undefined when calling reopenDepartmentReports.');
      } // verify required parameter 'year' is not null or undefined


      if (year === null || year === undefined) {
        throw new RequiredError('year', 'Required parameter year was null or undefined when calling reopenDepartmentReports.');
      } // verify required parameter 'month' is not null or undefined


      if (month === null || month === undefined) {
        throw new RequiredError('month', 'Required parameter month was null or undefined when calling reopenDepartmentReports.');
      }

      const localVarPath = `/api/v1/kintai/departments/{depCode}/{year}/{month}/reopen`.replace(`{${"depCode"}}`, encodeURIComponent(String(depCode))).replace(`{${"year"}}`, encodeURIComponent(String(year))).replace(`{${"month"}}`, encodeURIComponent(String(month)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'POST'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    }

  };
};
/**
 * KintaiDepartmentsReportControllerApi - functional programming interface
 * @export
 */

export const KintaiDepartmentsReportControllerApiFp = function (configuration) {
  return {
    /**
     *
     * @summary 部門勤怠月締め
     * @param {string} depCode 部門コード
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    closeDepartmentReports(depCode, year, month, options) {
      const localVarFetchArgs = KintaiDepartmentsReportControllerApiFetchParamCreator(configuration).closeDepartmentReports(depCode, year, month, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },

    /**
     *
     * @summary 部門勤怠月締めステータス取得
     * @param {string} depCode 部門コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDepartmentReportsCloseStatus(depCode, options) {
      const localVarFetchArgs = KintaiDepartmentsReportControllerApiFetchParamCreator(configuration).getDepartmentReportsCloseStatus(depCode, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },

    /**
     *
     * @summary 部門勤怠一覧取得
     * @param {string} depCode 部門コード
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getKintaiReports(depCode, year, month, options) {
      const localVarFetchArgs = KintaiDepartmentsReportControllerApiFetchParamCreator(configuration).getKintaiReports(depCode, year, month, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },

    /**
     *
     * @summary 部門勤怠月締め再オープン
     * @param {string} depCode 部門コード
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reopenDepartmentReports(depCode, year, month, options) {
      const localVarFetchArgs = KintaiDepartmentsReportControllerApiFetchParamCreator(configuration).reopenDepartmentReports(depCode, year, month, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    }

  };
};
/**
 * KintaiDepartmentsReportControllerApi - factory interface
 * @export
 */

export const KintaiDepartmentsReportControllerApiFactory = function (configuration, fetch, basePath) {
  return {
    /**
     *
     * @summary 部門勤怠月締め
     * @param {string} depCode 部門コード
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    closeDepartmentReports(depCode, year, month, options) {
      return KintaiDepartmentsReportControllerApiFp(configuration).closeDepartmentReports(depCode, year, month, options)(fetch, basePath);
    },

    /**
     *
     * @summary 部門勤怠月締めステータス取得
     * @param {string} depCode 部門コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDepartmentReportsCloseStatus(depCode, options) {
      return KintaiDepartmentsReportControllerApiFp(configuration).getDepartmentReportsCloseStatus(depCode, options)(fetch, basePath);
    },

    /**
     *
     * @summary 部門勤怠一覧取得
     * @param {string} depCode 部門コード
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getKintaiReports(depCode, year, month, options) {
      return KintaiDepartmentsReportControllerApiFp(configuration).getKintaiReports(depCode, year, month, options)(fetch, basePath);
    },

    /**
     *
     * @summary 部門勤怠月締め再オープン
     * @param {string} depCode 部門コード
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reopenDepartmentReports(depCode, year, month, options) {
      return KintaiDepartmentsReportControllerApiFp(configuration).reopenDepartmentReports(depCode, year, month, options)(fetch, basePath);
    }

  };
};
/**
 * KintaiDepartmentsReportControllerApi - object-oriented interface
 * @export
 * @class KintaiDepartmentsReportControllerApi
 * @extends {BaseAPI}
 */

export class KintaiDepartmentsReportControllerApi extends BaseAPI {
  /**
   *
   * @summary 部門勤怠月締め
   * @param {string} depCode 部門コード
   * @param {number} year 年
   * @param {number} month 月
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KintaiDepartmentsReportControllerApi
   */
  closeDepartmentReports(depCode, year, month, options) {
    return KintaiDepartmentsReportControllerApiFp(this.configuration).closeDepartmentReports(depCode, year, month, options)(this.fetch, this.basePath);
  }
  /**
   *
   * @summary 部門勤怠月締めステータス取得
   * @param {string} depCode 部門コード
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KintaiDepartmentsReportControllerApi
   */


  getDepartmentReportsCloseStatus(depCode, options) {
    return KintaiDepartmentsReportControllerApiFp(this.configuration).getDepartmentReportsCloseStatus(depCode, options)(this.fetch, this.basePath);
  }
  /**
   *
   * @summary 部門勤怠一覧取得
   * @param {string} depCode 部門コード
   * @param {number} year 年
   * @param {number} month 月
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KintaiDepartmentsReportControllerApi
   */


  getKintaiReports(depCode, year, month, options) {
    return KintaiDepartmentsReportControllerApiFp(this.configuration).getKintaiReports(depCode, year, month, options)(this.fetch, this.basePath);
  }
  /**
   *
   * @summary 部門勤怠月締め再オープン
   * @param {string} depCode 部門コード
   * @param {number} year 年
   * @param {number} month 月
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KintaiDepartmentsReportControllerApi
   */


  reopenDepartmentReports(depCode, year, month, options) {
    return KintaiDepartmentsReportControllerApiFp(this.configuration).reopenDepartmentReports(depCode, year, month, options)(this.fetch, this.basePath);
  }

}
/**
 * KintaiProjectControllerApi - fetch parameter creator
 * @export
 */

export const KintaiProjectControllerApiFetchParamCreator = function (configuration) {
  return {
    /**
     *
     * @summary 月報月締め情報リスト取得
     * @param {number} nendo 年度
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProjectCloseStatusList(nendo, options = {}) {
      // verify required parameter 'nendo' is not null or undefined
      if (nendo === null || nendo === undefined) {
        throw new RequiredError('nendo', 'Required parameter nendo was null or undefined when calling getProjectCloseStatusList.');
      }

      const localVarPath = `/api/v1/kintai/projects/close_status/{nendo}`.replace(`{${"nendo"}}`, encodeURIComponent(String(nendo)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'GET'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     *
     * @summary 月報月締め情報リスト保存
     * @param {Array<ProjectCloseEntity>} [ProjectCloseEntity] 月報月締め情報リスト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveProjectCloseStatusList(ProjectCloseEntity, options = {}) {
      const localVarPath = `/api/v1/kintai/projects/close_status`;
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'POST'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';
      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization = "Array&lt;ProjectCloseEntity&gt;" !== "string" || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(ProjectCloseEntity || {}) : ProjectCloseEntity || "";
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    }

  };
};
/**
 * KintaiProjectControllerApi - functional programming interface
 * @export
 */

export const KintaiProjectControllerApiFp = function (configuration) {
  return {
    /**
     *
     * @summary 月報月締め情報リスト取得
     * @param {number} nendo 年度
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProjectCloseStatusList(nendo, options) {
      const localVarFetchArgs = KintaiProjectControllerApiFetchParamCreator(configuration).getProjectCloseStatusList(nendo, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },

    /**
     *
     * @summary 月報月締め情報リスト保存
     * @param {Array<ProjectCloseEntity>} [ProjectCloseEntity] 月報月締め情報リスト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveProjectCloseStatusList(ProjectCloseEntity, options) {
      const localVarFetchArgs = KintaiProjectControllerApiFetchParamCreator(configuration).saveProjectCloseStatusList(ProjectCloseEntity, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    }

  };
};
/**
 * KintaiProjectControllerApi - factory interface
 * @export
 */

export const KintaiProjectControllerApiFactory = function (configuration, fetch, basePath) {
  return {
    /**
     *
     * @summary 月報月締め情報リスト取得
     * @param {number} nendo 年度
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProjectCloseStatusList(nendo, options) {
      return KintaiProjectControllerApiFp(configuration).getProjectCloseStatusList(nendo, options)(fetch, basePath);
    },

    /**
     *
     * @summary 月報月締め情報リスト保存
     * @param {Array<ProjectCloseEntity>} [ProjectCloseEntity] 月報月締め情報リスト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveProjectCloseStatusList(ProjectCloseEntity, options) {
      return KintaiProjectControllerApiFp(configuration).saveProjectCloseStatusList(ProjectCloseEntity, options)(fetch, basePath);
    }

  };
};
/**
 * KintaiProjectControllerApi - object-oriented interface
 * @export
 * @class KintaiProjectControllerApi
 * @extends {BaseAPI}
 */

export class KintaiProjectControllerApi extends BaseAPI {
  /**
   *
   * @summary 月報月締め情報リスト取得
   * @param {number} nendo 年度
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KintaiProjectControllerApi
   */
  getProjectCloseStatusList(nendo, options) {
    return KintaiProjectControllerApiFp(this.configuration).getProjectCloseStatusList(nendo, options)(this.fetch, this.basePath);
  }
  /**
   *
   * @summary 月報月締め情報リスト保存
   * @param {Array<ProjectCloseEntity>} [ProjectCloseEntity] 月報月締め情報リスト
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KintaiProjectControllerApi
   */


  saveProjectCloseStatusList(ProjectCloseEntity, options) {
    return KintaiProjectControllerApiFp(this.configuration).saveProjectCloseStatusList(ProjectCloseEntity, options)(this.fetch, this.basePath);
  }

}
/**
 * KintaiReportsControllerApi - fetch parameter creator
 * @export
 */

export const KintaiReportsControllerApiFetchParamCreator = function (configuration) {
  return {
    /**
     *
     * @summary 勤怠エクスポート
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportReports(year, month, options = {}) {
      // verify required parameter 'year' is not null or undefined
      if (year === null || year === undefined) {
        throw new RequiredError('year', 'Required parameter year was null or undefined when calling exportReports.');
      } // verify required parameter 'month' is not null or undefined


      if (month === null || month === undefined) {
        throw new RequiredError('month', 'Required parameter month was null or undefined when calling exportReports.');
      }

      const localVarPath = `/api/v1/kintai/reports/exports/{year}/{month}`.replace(`{${"year"}}`, encodeURIComponent(String(year))).replace(`{${"month"}}`, encodeURIComponent(String(month)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'GET'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     *
     * @summary 勤怠集計取得
     * @param {number} nendo 年度
     * @param {string} [depCode] 部門コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getKintaiAggregations(nendo, depCode, options = {}) {
      // verify required parameter 'nendo' is not null or undefined
      if (nendo === null || nendo === undefined) {
        throw new RequiredError('nendo', 'Required parameter nendo was null or undefined when calling getKintaiAggregations.');
      }

      const localVarPath = `/api/v1/kintai/reports/aggregations/{nendo}`.replace(`{${"nendo"}}`, encodeURIComponent(String(nendo)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'GET'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      if (depCode !== undefined) {
        localVarQueryParameter['depCode'] = depCode;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     *
     * @summary 勤怠一覧取得
     * @param {number} year 年
     * @param {number} month 月
     * @param {Array<string>} userId ユーザIDリスト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getKintaiReportsByUserIds(year, month, userId, options = {}) {
      // verify required parameter 'year' is not null or undefined
      if (year === null || year === undefined) {
        throw new RequiredError('year', 'Required parameter year was null or undefined when calling getKintaiReportsByUserIds.');
      } // verify required parameter 'month' is not null or undefined


      if (month === null || month === undefined) {
        throw new RequiredError('month', 'Required parameter month was null or undefined when calling getKintaiReportsByUserIds.');
      } // verify required parameter 'userId' is not null or undefined


      if (userId === null || userId === undefined) {
        throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling getKintaiReportsByUserIds.');
      }

      const localVarPath = `/api/v1/kintai/reports/{year}/{month}`.replace(`{${"year"}}`, encodeURIComponent(String(year))).replace(`{${"month"}}`, encodeURIComponent(String(month)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'GET'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      if (userId) {
        localVarQueryParameter['userId'] = userId;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     *
     * @summary 三六協定協議対象者一覧取得
     * @param {number} year 年
     * @param {number} month 月
     * @param {string} [depCode] 部門コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLawOverReports(year, month, depCode, options = {}) {
      // verify required parameter 'year' is not null or undefined
      if (year === null || year === undefined) {
        throw new RequiredError('year', 'Required parameter year was null or undefined when calling getLawOverReports.');
      } // verify required parameter 'month' is not null or undefined


      if (month === null || month === undefined) {
        throw new RequiredError('month', 'Required parameter month was null or undefined when calling getLawOverReports.');
      }

      const localVarPath = `/api/v1/kintai/reports/law_overs/{year}/{month}`.replace(`{${"year"}}`, encodeURIComponent(String(year))).replace(`{${"month"}}`, encodeURIComponent(String(month)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'GET'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      if (depCode !== undefined) {
        localVarQueryParameter['depCode'] = depCode;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     *
     * @summary 振替日一覧取得
     * @param {string} from 対象日（開始）
     * @param {string} to 対象日（終了）
     * @param {string} [depCode] 部門コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTransDayReports(from, to, depCode, options = {}) {
      // verify required parameter 'from' is not null or undefined
      if (from === null || from === undefined) {
        throw new RequiredError('from', 'Required parameter from was null or undefined when calling getTransDayReports.');
      } // verify required parameter 'to' is not null or undefined


      if (to === null || to === undefined) {
        throw new RequiredError('to', 'Required parameter to was null or undefined when calling getTransDayReports.');
      }

      const localVarPath = `/api/v1/kintai/reports/trans_days`;
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'GET'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      if (from !== undefined) {
        localVarQueryParameter['from'] = from;
      }

      if (to !== undefined) {
        localVarQueryParameter['to'] = to;
      }

      if (depCode !== undefined) {
        localVarQueryParameter['depCode'] = depCode;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     *
     * @summary 勤怠ユーザ検索
     * @param {number} year 年
     * @param {number} month 月
     * @param {string} [depCode] 部門コード
     * @param {string} [approverId] 査閲者・承認者ユーザID
     * @param {boolean} [onlyHavingPrivilegedKintaiKubun] 管理者による承認が必要な勤怠区分を含む
     * @param {boolean} [noEntryUser] 未入力者
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchUsers(year, month, depCode, approverId, onlyHavingPrivilegedKintaiKubun, noEntryUser, options = {}) {
      // verify required parameter 'year' is not null or undefined
      if (year === null || year === undefined) {
        throw new RequiredError('year', 'Required parameter year was null or undefined when calling searchUsers.');
      } // verify required parameter 'month' is not null or undefined


      if (month === null || month === undefined) {
        throw new RequiredError('month', 'Required parameter month was null or undefined when calling searchUsers.');
      }

      const localVarPath = `/api/v1/kintai/reports/users`;
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'GET'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      if (year !== undefined) {
        localVarQueryParameter['year'] = year;
      }

      if (month !== undefined) {
        localVarQueryParameter['month'] = month;
      }

      if (depCode !== undefined) {
        localVarQueryParameter['depCode'] = depCode;
      }

      if (approverId !== undefined) {
        localVarQueryParameter['approverId'] = approverId;
      }

      if (onlyHavingPrivilegedKintaiKubun !== undefined) {
        localVarQueryParameter['onlyHavingPrivilegedKintaiKubun'] = onlyHavingPrivilegedKintaiKubun;
      }

      if (noEntryUser !== undefined) {
        localVarQueryParameter['noEntryUser'] = noEntryUser;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    }

  };
};
/**
 * KintaiReportsControllerApi - functional programming interface
 * @export
 */

export const KintaiReportsControllerApiFp = function (configuration) {
  return {
    /**
     *
     * @summary 勤怠エクスポート
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportReports(year, month, options) {
      const localVarFetchArgs = KintaiReportsControllerApiFetchParamCreator(configuration).exportReports(year, month, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },

    /**
     *
     * @summary 勤怠集計取得
     * @param {number} nendo 年度
     * @param {string} [depCode] 部門コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getKintaiAggregations(nendo, depCode, options) {
      const localVarFetchArgs = KintaiReportsControllerApiFetchParamCreator(configuration).getKintaiAggregations(nendo, depCode, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },

    /**
     *
     * @summary 勤怠一覧取得
     * @param {number} year 年
     * @param {number} month 月
     * @param {Array<string>} userId ユーザIDリスト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getKintaiReportsByUserIds(year, month, userId, options) {
      const localVarFetchArgs = KintaiReportsControllerApiFetchParamCreator(configuration).getKintaiReportsByUserIds(year, month, userId, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },

    /**
     *
     * @summary 三六協定協議対象者一覧取得
     * @param {number} year 年
     * @param {number} month 月
     * @param {string} [depCode] 部門コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLawOverReports(year, month, depCode, options) {
      const localVarFetchArgs = KintaiReportsControllerApiFetchParamCreator(configuration).getLawOverReports(year, month, depCode, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },

    /**
     *
     * @summary 振替日一覧取得
     * @param {string} from 対象日（開始）
     * @param {string} to 対象日（終了）
     * @param {string} [depCode] 部門コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTransDayReports(from, to, depCode, options) {
      const localVarFetchArgs = KintaiReportsControllerApiFetchParamCreator(configuration).getTransDayReports(from, to, depCode, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },

    /**
     *
     * @summary 勤怠ユーザ検索
     * @param {number} year 年
     * @param {number} month 月
     * @param {string} [depCode] 部門コード
     * @param {string} [approverId] 査閲者・承認者ユーザID
     * @param {boolean} [onlyHavingPrivilegedKintaiKubun] 管理者による承認が必要な勤怠区分を含む
     * @param {boolean} [noEntryUser] 未入力者
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchUsers(year, month, depCode, approverId, onlyHavingPrivilegedKintaiKubun, noEntryUser, options) {
      const localVarFetchArgs = KintaiReportsControllerApiFetchParamCreator(configuration).searchUsers(year, month, depCode, approverId, onlyHavingPrivilegedKintaiKubun, noEntryUser, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    }

  };
};
/**
 * KintaiReportsControllerApi - factory interface
 * @export
 */

export const KintaiReportsControllerApiFactory = function (configuration, fetch, basePath) {
  return {
    /**
     *
     * @summary 勤怠エクスポート
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportReports(year, month, options) {
      return KintaiReportsControllerApiFp(configuration).exportReports(year, month, options)(fetch, basePath);
    },

    /**
     *
     * @summary 勤怠集計取得
     * @param {number} nendo 年度
     * @param {string} [depCode] 部門コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getKintaiAggregations(nendo, depCode, options) {
      return KintaiReportsControllerApiFp(configuration).getKintaiAggregations(nendo, depCode, options)(fetch, basePath);
    },

    /**
     *
     * @summary 勤怠一覧取得
     * @param {number} year 年
     * @param {number} month 月
     * @param {Array<string>} userId ユーザIDリスト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getKintaiReportsByUserIds(year, month, userId, options) {
      return KintaiReportsControllerApiFp(configuration).getKintaiReportsByUserIds(year, month, userId, options)(fetch, basePath);
    },

    /**
     *
     * @summary 三六協定協議対象者一覧取得
     * @param {number} year 年
     * @param {number} month 月
     * @param {string} [depCode] 部門コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLawOverReports(year, month, depCode, options) {
      return KintaiReportsControllerApiFp(configuration).getLawOverReports(year, month, depCode, options)(fetch, basePath);
    },

    /**
     *
     * @summary 振替日一覧取得
     * @param {string} from 対象日（開始）
     * @param {string} to 対象日（終了）
     * @param {string} [depCode] 部門コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTransDayReports(from, to, depCode, options) {
      return KintaiReportsControllerApiFp(configuration).getTransDayReports(from, to, depCode, options)(fetch, basePath);
    },

    /**
     *
     * @summary 勤怠ユーザ検索
     * @param {number} year 年
     * @param {number} month 月
     * @param {string} [depCode] 部門コード
     * @param {string} [approverId] 査閲者・承認者ユーザID
     * @param {boolean} [onlyHavingPrivilegedKintaiKubun] 管理者による承認が必要な勤怠区分を含む
     * @param {boolean} [noEntryUser] 未入力者
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchUsers(year, month, depCode, approverId, onlyHavingPrivilegedKintaiKubun, noEntryUser, options) {
      return KintaiReportsControllerApiFp(configuration).searchUsers(year, month, depCode, approverId, onlyHavingPrivilegedKintaiKubun, noEntryUser, options)(fetch, basePath);
    }

  };
};
/**
 * KintaiReportsControllerApi - object-oriented interface
 * @export
 * @class KintaiReportsControllerApi
 * @extends {BaseAPI}
 */

export class KintaiReportsControllerApi extends BaseAPI {
  /**
   *
   * @summary 勤怠エクスポート
   * @param {number} year 年
   * @param {number} month 月
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KintaiReportsControllerApi
   */
  exportReports(year, month, options) {
    return KintaiReportsControllerApiFp(this.configuration).exportReports(year, month, options)(this.fetch, this.basePath);
  }
  /**
   *
   * @summary 勤怠集計取得
   * @param {number} nendo 年度
   * @param {string} [depCode] 部門コード
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KintaiReportsControllerApi
   */


  getKintaiAggregations(nendo, depCode, options) {
    return KintaiReportsControllerApiFp(this.configuration).getKintaiAggregations(nendo, depCode, options)(this.fetch, this.basePath);
  }
  /**
   *
   * @summary 勤怠一覧取得
   * @param {number} year 年
   * @param {number} month 月
   * @param {Array<string>} userId ユーザIDリスト
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KintaiReportsControllerApi
   */


  getKintaiReportsByUserIds(year, month, userId, options) {
    return KintaiReportsControllerApiFp(this.configuration).getKintaiReportsByUserIds(year, month, userId, options)(this.fetch, this.basePath);
  }
  /**
   *
   * @summary 三六協定協議対象者一覧取得
   * @param {number} year 年
   * @param {number} month 月
   * @param {string} [depCode] 部門コード
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KintaiReportsControllerApi
   */


  getLawOverReports(year, month, depCode, options) {
    return KintaiReportsControllerApiFp(this.configuration).getLawOverReports(year, month, depCode, options)(this.fetch, this.basePath);
  }
  /**
   *
   * @summary 振替日一覧取得
   * @param {string} from 対象日（開始）
   * @param {string} to 対象日（終了）
   * @param {string} [depCode] 部門コード
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KintaiReportsControllerApi
   */


  getTransDayReports(from, to, depCode, options) {
    return KintaiReportsControllerApiFp(this.configuration).getTransDayReports(from, to, depCode, options)(this.fetch, this.basePath);
  }
  /**
   *
   * @summary 勤怠ユーザ検索
   * @param {number} year 年
   * @param {number} month 月
   * @param {string} [depCode] 部門コード
   * @param {string} [approverId] 査閲者・承認者ユーザID
   * @param {boolean} [onlyHavingPrivilegedKintaiKubun] 管理者による承認が必要な勤怠区分を含む
   * @param {boolean} [noEntryUser] 未入力者
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KintaiReportsControllerApi
   */


  searchUsers(year, month, depCode, approverId, onlyHavingPrivilegedKintaiKubun, noEntryUser, options) {
    return KintaiReportsControllerApiFp(this.configuration).searchUsers(year, month, depCode, approverId, onlyHavingPrivilegedKintaiKubun, noEntryUser, options)(this.fetch, this.basePath);
  }

}
/**
 * KintaiTimestampsControllerApi - fetch parameter creator
 * @export
 */

export const KintaiTimestampsControllerApiFetchParamCreator = function (configuration) {
  return {
    /**
     *
     * @summary 事務担当タイムスタンプ取消
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clearClerkTimestamp(userId, year, month, options = {}) {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling clearClerkTimestamp.');
      } // verify required parameter 'year' is not null or undefined


      if (year === null || year === undefined) {
        throw new RequiredError('year', 'Required parameter year was null or undefined when calling clearClerkTimestamp.');
      } // verify required parameter 'month' is not null or undefined


      if (month === null || month === undefined) {
        throw new RequiredError('month', 'Required parameter month was null or undefined when calling clearClerkTimestamp.');
      }

      const localVarPath = `/api/v1/kintai/users/{userId}/{year}/{month}/timestamps/clerk`.replace(`{${"userId"}}`, encodeURIComponent(String(userId))).replace(`{${"year"}}`, encodeURIComponent(String(year))).replace(`{${"month"}}`, encodeURIComponent(String(month)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'DELETE'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     *
     * @summary 部門承認タイムスタンプ取消
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clearDepartmentTimestamp(userId, year, month, options = {}) {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling clearDepartmentTimestamp.');
      } // verify required parameter 'year' is not null or undefined


      if (year === null || year === undefined) {
        throw new RequiredError('year', 'Required parameter year was null or undefined when calling clearDepartmentTimestamp.');
      } // verify required parameter 'month' is not null or undefined


      if (month === null || month === undefined) {
        throw new RequiredError('month', 'Required parameter month was null or undefined when calling clearDepartmentTimestamp.');
      }

      const localVarPath = `/api/v1/kintai/users/{userId}/{year}/{month}/timestamps/department`.replace(`{${"userId"}}`, encodeURIComponent(String(userId))).replace(`{${"year"}}`, encodeURIComponent(String(year))).replace(`{${"month"}}`, encodeURIComponent(String(month)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'DELETE'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     *
     * @summary 総務人事部承認タイムスタンプ取消
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clearHRMTimestamp(userId, year, month, options = {}) {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling clearHRMTimestamp.');
      } // verify required parameter 'year' is not null or undefined


      if (year === null || year === undefined) {
        throw new RequiredError('year', 'Required parameter year was null or undefined when calling clearHRMTimestamp.');
      } // verify required parameter 'month' is not null or undefined


      if (month === null || month === undefined) {
        throw new RequiredError('month', 'Required parameter month was null or undefined when calling clearHRMTimestamp.');
      }

      const localVarPath = `/api/v1/kintai/users/{userId}/{year}/{month}/timestamps/hrm`.replace(`{${"userId"}}`, encodeURIComponent(String(userId))).replace(`{${"year"}}`, encodeURIComponent(String(year))).replace(`{${"month"}}`, encodeURIComponent(String(month)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'DELETE'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     *
     * @summary 査閲タイムスタンプ取消
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clearReviewTimestamp(userId, year, month, options = {}) {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling clearReviewTimestamp.');
      } // verify required parameter 'year' is not null or undefined


      if (year === null || year === undefined) {
        throw new RequiredError('year', 'Required parameter year was null or undefined when calling clearReviewTimestamp.');
      } // verify required parameter 'month' is not null or undefined


      if (month === null || month === undefined) {
        throw new RequiredError('month', 'Required parameter month was null or undefined when calling clearReviewTimestamp.');
      }

      const localVarPath = `/api/v1/kintai/users/{userId}/{year}/{month}/timestamps/review`.replace(`{${"userId"}}`, encodeURIComponent(String(userId))).replace(`{${"year"}}`, encodeURIComponent(String(year))).replace(`{${"month"}}`, encodeURIComponent(String(month)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'DELETE'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     *
     * @summary 自己タイムスタンプ取消
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clearSelfTimestamp(userId, year, month, options = {}) {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling clearSelfTimestamp.');
      } // verify required parameter 'year' is not null or undefined


      if (year === null || year === undefined) {
        throw new RequiredError('year', 'Required parameter year was null or undefined when calling clearSelfTimestamp.');
      } // verify required parameter 'month' is not null or undefined


      if (month === null || month === undefined) {
        throw new RequiredError('month', 'Required parameter month was null or undefined when calling clearSelfTimestamp.');
      }

      const localVarPath = `/api/v1/kintai/users/{userId}/{year}/{month}/timestamps/self`.replace(`{${"userId"}}`, encodeURIComponent(String(userId))).replace(`{${"year"}}`, encodeURIComponent(String(year))).replace(`{${"month"}}`, encodeURIComponent(String(month)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'DELETE'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     *
     * @summary 査閲者・承認者候補一覧
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getApprovers(userId, year, month, options = {}) {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling getApprovers.');
      } // verify required parameter 'year' is not null or undefined


      if (year === null || year === undefined) {
        throw new RequiredError('year', 'Required parameter year was null or undefined when calling getApprovers.');
      } // verify required parameter 'month' is not null or undefined


      if (month === null || month === undefined) {
        throw new RequiredError('month', 'Required parameter month was null or undefined when calling getApprovers.');
      }

      const localVarPath = `/api/v1/kintai/users/{userId}/{year}/{month}/approvers`.replace(`{${"userId"}}`, encodeURIComponent(String(userId))).replace(`{${"year"}}`, encodeURIComponent(String(year))).replace(`{${"month"}}`, encodeURIComponent(String(month)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'GET'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     *
     * @summary 事務担当タイムスタンプ押下
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setClerkTimestamp(userId, year, month, options = {}) {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling setClerkTimestamp.');
      } // verify required parameter 'year' is not null or undefined


      if (year === null || year === undefined) {
        throw new RequiredError('year', 'Required parameter year was null or undefined when calling setClerkTimestamp.');
      } // verify required parameter 'month' is not null or undefined


      if (month === null || month === undefined) {
        throw new RequiredError('month', 'Required parameter month was null or undefined when calling setClerkTimestamp.');
      }

      const localVarPath = `/api/v1/kintai/users/{userId}/{year}/{month}/timestamps/clerk`.replace(`{${"userId"}}`, encodeURIComponent(String(userId))).replace(`{${"year"}}`, encodeURIComponent(String(year))).replace(`{${"month"}}`, encodeURIComponent(String(month)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'POST'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     *
     * @summary 部門承認タイムスタンプ押下
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setDepartmentTimestamp(userId, year, month, options = {}) {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling setDepartmentTimestamp.');
      } // verify required parameter 'year' is not null or undefined


      if (year === null || year === undefined) {
        throw new RequiredError('year', 'Required parameter year was null or undefined when calling setDepartmentTimestamp.');
      } // verify required parameter 'month' is not null or undefined


      if (month === null || month === undefined) {
        throw new RequiredError('month', 'Required parameter month was null or undefined when calling setDepartmentTimestamp.');
      }

      const localVarPath = `/api/v1/kintai/users/{userId}/{year}/{month}/timestamps/department`.replace(`{${"userId"}}`, encodeURIComponent(String(userId))).replace(`{${"year"}}`, encodeURIComponent(String(year))).replace(`{${"month"}}`, encodeURIComponent(String(month)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'POST'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     *
     * @summary 総務人事部承認タイムスタンプ押下
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setHRMTimestamp(userId, year, month, options = {}) {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling setHRMTimestamp.');
      } // verify required parameter 'year' is not null or undefined


      if (year === null || year === undefined) {
        throw new RequiredError('year', 'Required parameter year was null or undefined when calling setHRMTimestamp.');
      } // verify required parameter 'month' is not null or undefined


      if (month === null || month === undefined) {
        throw new RequiredError('month', 'Required parameter month was null or undefined when calling setHRMTimestamp.');
      }

      const localVarPath = `/api/v1/kintai/users/{userId}/{year}/{month}/timestamps/hrm`.replace(`{${"userId"}}`, encodeURIComponent(String(userId))).replace(`{${"year"}}`, encodeURIComponent(String(year))).replace(`{${"month"}}`, encodeURIComponent(String(month)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'POST'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     *
     * @summary 査閲タイムスタンプ押下
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setReviewTimestamp(userId, year, month, options = {}) {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling setReviewTimestamp.');
      } // verify required parameter 'year' is not null or undefined


      if (year === null || year === undefined) {
        throw new RequiredError('year', 'Required parameter year was null or undefined when calling setReviewTimestamp.');
      } // verify required parameter 'month' is not null or undefined


      if (month === null || month === undefined) {
        throw new RequiredError('month', 'Required parameter month was null or undefined when calling setReviewTimestamp.');
      }

      const localVarPath = `/api/v1/kintai/users/{userId}/{year}/{month}/timestamps/review`.replace(`{${"userId"}}`, encodeURIComponent(String(userId))).replace(`{${"year"}}`, encodeURIComponent(String(year))).replace(`{${"month"}}`, encodeURIComponent(String(month)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'POST'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     *
     * @summary 自己タイムスタンプ押下
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setSelfTimestamp(userId, year, month, options = {}) {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling setSelfTimestamp.');
      } // verify required parameter 'year' is not null or undefined


      if (year === null || year === undefined) {
        throw new RequiredError('year', 'Required parameter year was null or undefined when calling setSelfTimestamp.');
      } // verify required parameter 'month' is not null or undefined


      if (month === null || month === undefined) {
        throw new RequiredError('month', 'Required parameter month was null or undefined when calling setSelfTimestamp.');
      }

      const localVarPath = `/api/v1/kintai/users/{userId}/{year}/{month}/timestamps/self`.replace(`{${"userId"}}`, encodeURIComponent(String(userId))).replace(`{${"year"}}`, encodeURIComponent(String(year))).replace(`{${"month"}}`, encodeURIComponent(String(month)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'POST'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     *
     * @summary 査閲者・承認者更新
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {MonthlyReportApproversEditEntity} MonthlyReportApproversEditEntity 査閲者・承認者
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateApprovers(userId, year, month, MonthlyReportApproversEditEntity, options = {}) {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling updateApprovers.');
      } // verify required parameter 'year' is not null or undefined


      if (year === null || year === undefined) {
        throw new RequiredError('year', 'Required parameter year was null or undefined when calling updateApprovers.');
      } // verify required parameter 'month' is not null or undefined


      if (month === null || month === undefined) {
        throw new RequiredError('month', 'Required parameter month was null or undefined when calling updateApprovers.');
      } // verify required parameter 'MonthlyReportApproversEditEntity' is not null or undefined


      if (MonthlyReportApproversEditEntity === null || MonthlyReportApproversEditEntity === undefined) {
        throw new RequiredError('MonthlyReportApproversEditEntity', 'Required parameter MonthlyReportApproversEditEntity was null or undefined when calling updateApprovers.');
      }

      const localVarPath = `/api/v1/kintai/users/{userId}/{year}/{month}/approvers`.replace(`{${"userId"}}`, encodeURIComponent(String(userId))).replace(`{${"year"}}`, encodeURIComponent(String(year))).replace(`{${"month"}}`, encodeURIComponent(String(month)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'POST'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';
      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization = "MonthlyReportApproversEditEntity" !== "string" || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(MonthlyReportApproversEditEntity || {}) : MonthlyReportApproversEditEntity || "";
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    }

  };
};
/**
 * KintaiTimestampsControllerApi - functional programming interface
 * @export
 */

export const KintaiTimestampsControllerApiFp = function (configuration) {
  return {
    /**
     *
     * @summary 事務担当タイムスタンプ取消
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clearClerkTimestamp(userId, year, month, options) {
      const localVarFetchArgs = KintaiTimestampsControllerApiFetchParamCreator(configuration).clearClerkTimestamp(userId, year, month, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },

    /**
     *
     * @summary 部門承認タイムスタンプ取消
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clearDepartmentTimestamp(userId, year, month, options) {
      const localVarFetchArgs = KintaiTimestampsControllerApiFetchParamCreator(configuration).clearDepartmentTimestamp(userId, year, month, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },

    /**
     *
     * @summary 総務人事部承認タイムスタンプ取消
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clearHRMTimestamp(userId, year, month, options) {
      const localVarFetchArgs = KintaiTimestampsControllerApiFetchParamCreator(configuration).clearHRMTimestamp(userId, year, month, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },

    /**
     *
     * @summary 査閲タイムスタンプ取消
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clearReviewTimestamp(userId, year, month, options) {
      const localVarFetchArgs = KintaiTimestampsControllerApiFetchParamCreator(configuration).clearReviewTimestamp(userId, year, month, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },

    /**
     *
     * @summary 自己タイムスタンプ取消
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clearSelfTimestamp(userId, year, month, options) {
      const localVarFetchArgs = KintaiTimestampsControllerApiFetchParamCreator(configuration).clearSelfTimestamp(userId, year, month, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },

    /**
     *
     * @summary 査閲者・承認者候補一覧
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getApprovers(userId, year, month, options) {
      const localVarFetchArgs = KintaiTimestampsControllerApiFetchParamCreator(configuration).getApprovers(userId, year, month, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },

    /**
     *
     * @summary 事務担当タイムスタンプ押下
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setClerkTimestamp(userId, year, month, options) {
      const localVarFetchArgs = KintaiTimestampsControllerApiFetchParamCreator(configuration).setClerkTimestamp(userId, year, month, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },

    /**
     *
     * @summary 部門承認タイムスタンプ押下
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setDepartmentTimestamp(userId, year, month, options) {
      const localVarFetchArgs = KintaiTimestampsControllerApiFetchParamCreator(configuration).setDepartmentTimestamp(userId, year, month, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },

    /**
     *
     * @summary 総務人事部承認タイムスタンプ押下
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setHRMTimestamp(userId, year, month, options) {
      const localVarFetchArgs = KintaiTimestampsControllerApiFetchParamCreator(configuration).setHRMTimestamp(userId, year, month, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },

    /**
     *
     * @summary 査閲タイムスタンプ押下
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setReviewTimestamp(userId, year, month, options) {
      const localVarFetchArgs = KintaiTimestampsControllerApiFetchParamCreator(configuration).setReviewTimestamp(userId, year, month, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },

    /**
     *
     * @summary 自己タイムスタンプ押下
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setSelfTimestamp(userId, year, month, options) {
      const localVarFetchArgs = KintaiTimestampsControllerApiFetchParamCreator(configuration).setSelfTimestamp(userId, year, month, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },

    /**
     *
     * @summary 査閲者・承認者更新
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {MonthlyReportApproversEditEntity} MonthlyReportApproversEditEntity 査閲者・承認者
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateApprovers(userId, year, month, MonthlyReportApproversEditEntity, options) {
      const localVarFetchArgs = KintaiTimestampsControllerApiFetchParamCreator(configuration).updateApprovers(userId, year, month, MonthlyReportApproversEditEntity, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    }

  };
};
/**
 * KintaiTimestampsControllerApi - factory interface
 * @export
 */

export const KintaiTimestampsControllerApiFactory = function (configuration, fetch, basePath) {
  return {
    /**
     *
     * @summary 事務担当タイムスタンプ取消
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clearClerkTimestamp(userId, year, month, options) {
      return KintaiTimestampsControllerApiFp(configuration).clearClerkTimestamp(userId, year, month, options)(fetch, basePath);
    },

    /**
     *
     * @summary 部門承認タイムスタンプ取消
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clearDepartmentTimestamp(userId, year, month, options) {
      return KintaiTimestampsControllerApiFp(configuration).clearDepartmentTimestamp(userId, year, month, options)(fetch, basePath);
    },

    /**
     *
     * @summary 総務人事部承認タイムスタンプ取消
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clearHRMTimestamp(userId, year, month, options) {
      return KintaiTimestampsControllerApiFp(configuration).clearHRMTimestamp(userId, year, month, options)(fetch, basePath);
    },

    /**
     *
     * @summary 査閲タイムスタンプ取消
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clearReviewTimestamp(userId, year, month, options) {
      return KintaiTimestampsControllerApiFp(configuration).clearReviewTimestamp(userId, year, month, options)(fetch, basePath);
    },

    /**
     *
     * @summary 自己タイムスタンプ取消
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clearSelfTimestamp(userId, year, month, options) {
      return KintaiTimestampsControllerApiFp(configuration).clearSelfTimestamp(userId, year, month, options)(fetch, basePath);
    },

    /**
     *
     * @summary 査閲者・承認者候補一覧
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getApprovers(userId, year, month, options) {
      return KintaiTimestampsControllerApiFp(configuration).getApprovers(userId, year, month, options)(fetch, basePath);
    },

    /**
     *
     * @summary 事務担当タイムスタンプ押下
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setClerkTimestamp(userId, year, month, options) {
      return KintaiTimestampsControllerApiFp(configuration).setClerkTimestamp(userId, year, month, options)(fetch, basePath);
    },

    /**
     *
     * @summary 部門承認タイムスタンプ押下
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setDepartmentTimestamp(userId, year, month, options) {
      return KintaiTimestampsControllerApiFp(configuration).setDepartmentTimestamp(userId, year, month, options)(fetch, basePath);
    },

    /**
     *
     * @summary 総務人事部承認タイムスタンプ押下
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setHRMTimestamp(userId, year, month, options) {
      return KintaiTimestampsControllerApiFp(configuration).setHRMTimestamp(userId, year, month, options)(fetch, basePath);
    },

    /**
     *
     * @summary 査閲タイムスタンプ押下
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setReviewTimestamp(userId, year, month, options) {
      return KintaiTimestampsControllerApiFp(configuration).setReviewTimestamp(userId, year, month, options)(fetch, basePath);
    },

    /**
     *
     * @summary 自己タイムスタンプ押下
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setSelfTimestamp(userId, year, month, options) {
      return KintaiTimestampsControllerApiFp(configuration).setSelfTimestamp(userId, year, month, options)(fetch, basePath);
    },

    /**
     *
     * @summary 査閲者・承認者更新
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {MonthlyReportApproversEditEntity} MonthlyReportApproversEditEntity 査閲者・承認者
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateApprovers(userId, year, month, MonthlyReportApproversEditEntity, options) {
      return KintaiTimestampsControllerApiFp(configuration).updateApprovers(userId, year, month, MonthlyReportApproversEditEntity, options)(fetch, basePath);
    }

  };
};
/**
 * KintaiTimestampsControllerApi - object-oriented interface
 * @export
 * @class KintaiTimestampsControllerApi
 * @extends {BaseAPI}
 */

export class KintaiTimestampsControllerApi extends BaseAPI {
  /**
   *
   * @summary 事務担当タイムスタンプ取消
   * @param {string} userId ユーザID
   * @param {number} year 年
   * @param {number} month 月
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KintaiTimestampsControllerApi
   */
  clearClerkTimestamp(userId, year, month, options) {
    return KintaiTimestampsControllerApiFp(this.configuration).clearClerkTimestamp(userId, year, month, options)(this.fetch, this.basePath);
  }
  /**
   *
   * @summary 部門承認タイムスタンプ取消
   * @param {string} userId ユーザID
   * @param {number} year 年
   * @param {number} month 月
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KintaiTimestampsControllerApi
   */


  clearDepartmentTimestamp(userId, year, month, options) {
    return KintaiTimestampsControllerApiFp(this.configuration).clearDepartmentTimestamp(userId, year, month, options)(this.fetch, this.basePath);
  }
  /**
   *
   * @summary 総務人事部承認タイムスタンプ取消
   * @param {string} userId ユーザID
   * @param {number} year 年
   * @param {number} month 月
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KintaiTimestampsControllerApi
   */


  clearHRMTimestamp(userId, year, month, options) {
    return KintaiTimestampsControllerApiFp(this.configuration).clearHRMTimestamp(userId, year, month, options)(this.fetch, this.basePath);
  }
  /**
   *
   * @summary 査閲タイムスタンプ取消
   * @param {string} userId ユーザID
   * @param {number} year 年
   * @param {number} month 月
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KintaiTimestampsControllerApi
   */


  clearReviewTimestamp(userId, year, month, options) {
    return KintaiTimestampsControllerApiFp(this.configuration).clearReviewTimestamp(userId, year, month, options)(this.fetch, this.basePath);
  }
  /**
   *
   * @summary 自己タイムスタンプ取消
   * @param {string} userId ユーザID
   * @param {number} year 年
   * @param {number} month 月
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KintaiTimestampsControllerApi
   */


  clearSelfTimestamp(userId, year, month, options) {
    return KintaiTimestampsControllerApiFp(this.configuration).clearSelfTimestamp(userId, year, month, options)(this.fetch, this.basePath);
  }
  /**
   *
   * @summary 査閲者・承認者候補一覧
   * @param {string} userId ユーザID
   * @param {number} year 年
   * @param {number} month 月
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KintaiTimestampsControllerApi
   */


  getApprovers(userId, year, month, options) {
    return KintaiTimestampsControllerApiFp(this.configuration).getApprovers(userId, year, month, options)(this.fetch, this.basePath);
  }
  /**
   *
   * @summary 事務担当タイムスタンプ押下
   * @param {string} userId ユーザID
   * @param {number} year 年
   * @param {number} month 月
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KintaiTimestampsControllerApi
   */


  setClerkTimestamp(userId, year, month, options) {
    return KintaiTimestampsControllerApiFp(this.configuration).setClerkTimestamp(userId, year, month, options)(this.fetch, this.basePath);
  }
  /**
   *
   * @summary 部門承認タイムスタンプ押下
   * @param {string} userId ユーザID
   * @param {number} year 年
   * @param {number} month 月
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KintaiTimestampsControllerApi
   */


  setDepartmentTimestamp(userId, year, month, options) {
    return KintaiTimestampsControllerApiFp(this.configuration).setDepartmentTimestamp(userId, year, month, options)(this.fetch, this.basePath);
  }
  /**
   *
   * @summary 総務人事部承認タイムスタンプ押下
   * @param {string} userId ユーザID
   * @param {number} year 年
   * @param {number} month 月
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KintaiTimestampsControllerApi
   */


  setHRMTimestamp(userId, year, month, options) {
    return KintaiTimestampsControllerApiFp(this.configuration).setHRMTimestamp(userId, year, month, options)(this.fetch, this.basePath);
  }
  /**
   *
   * @summary 査閲タイムスタンプ押下
   * @param {string} userId ユーザID
   * @param {number} year 年
   * @param {number} month 月
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KintaiTimestampsControllerApi
   */


  setReviewTimestamp(userId, year, month, options) {
    return KintaiTimestampsControllerApiFp(this.configuration).setReviewTimestamp(userId, year, month, options)(this.fetch, this.basePath);
  }
  /**
   *
   * @summary 自己タイムスタンプ押下
   * @param {string} userId ユーザID
   * @param {number} year 年
   * @param {number} month 月
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KintaiTimestampsControllerApi
   */


  setSelfTimestamp(userId, year, month, options) {
    return KintaiTimestampsControllerApiFp(this.configuration).setSelfTimestamp(userId, year, month, options)(this.fetch, this.basePath);
  }
  /**
   *
   * @summary 査閲者・承認者更新
   * @param {string} userId ユーザID
   * @param {number} year 年
   * @param {number} month 月
   * @param {MonthlyReportApproversEditEntity} MonthlyReportApproversEditEntity 査閲者・承認者
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KintaiTimestampsControllerApi
   */


  updateApprovers(userId, year, month, MonthlyReportApproversEditEntity, options) {
    return KintaiTimestampsControllerApiFp(this.configuration).updateApprovers(userId, year, month, MonthlyReportApproversEditEntity, options)(this.fetch, this.basePath);
  }

}
/**
 * KintaiUsersReportControllerApi - fetch parameter creator
 * @export
 */

export const KintaiUsersReportControllerApiFetchParamCreator = function (configuration) {
  return {
    /**
     *
     * @summary 勤怠情報取得
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getKintaiReport(userId, year, month, options = {}) {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling getKintaiReport.');
      } // verify required parameter 'year' is not null or undefined


      if (year === null || year === undefined) {
        throw new RequiredError('year', 'Required parameter year was null or undefined when calling getKintaiReport.');
      } // verify required parameter 'month' is not null or undefined


      if (month === null || month === undefined) {
        throw new RequiredError('month', 'Required parameter month was null or undefined when calling getKintaiReport.');
      }

      const localVarPath = `/api/v1/kintai/users/{userId}/{year}/{month}`.replace(`{${"userId"}}`, encodeURIComponent(String(userId))).replace(`{${"year"}}`, encodeURIComponent(String(year))).replace(`{${"month"}}`, encodeURIComponent(String(month)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'GET'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     *
     * @summary 勤怠属性再計算
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refreshKintaiAttributes(userId, year, month, options = {}) {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling refreshKintaiAttributes.');
      } // verify required parameter 'year' is not null or undefined


      if (year === null || year === undefined) {
        throw new RequiredError('year', 'Required parameter year was null or undefined when calling refreshKintaiAttributes.');
      } // verify required parameter 'month' is not null or undefined


      if (month === null || month === undefined) {
        throw new RequiredError('month', 'Required parameter month was null or undefined when calling refreshKintaiAttributes.');
      }

      const localVarPath = `/api/v1/kintai/users/{userId}/{year}/{month}/attributes/refresh`.replace(`{${"userId"}}`, encodeURIComponent(String(userId))).replace(`{${"year"}}`, encodeURIComponent(String(year))).replace(`{${"month"}}`, encodeURIComponent(String(month)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'POST'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     *
     * @summary 勤怠再計算
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refreshReport(userId, year, month, options = {}) {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling refreshReport.');
      } // verify required parameter 'year' is not null or undefined


      if (year === null || year === undefined) {
        throw new RequiredError('year', 'Required parameter year was null or undefined when calling refreshReport.');
      } // verify required parameter 'month' is not null or undefined


      if (month === null || month === undefined) {
        throw new RequiredError('month', 'Required parameter month was null or undefined when calling refreshReport.');
      }

      const localVarPath = `/api/v1/kintai/users/{userId}/{year}/{month}/refresh`.replace(`{${"userId"}}`, encodeURIComponent(String(userId))).replace(`{${"year"}}`, encodeURIComponent(String(year))).replace(`{${"month"}}`, encodeURIComponent(String(month)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'POST'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     *
     * @summary 勤怠情報削除
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeKintaiReport(userId, year, month, options = {}) {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling removeKintaiReport.');
      } // verify required parameter 'year' is not null or undefined


      if (year === null || year === undefined) {
        throw new RequiredError('year', 'Required parameter year was null or undefined when calling removeKintaiReport.');
      } // verify required parameter 'month' is not null or undefined


      if (month === null || month === undefined) {
        throw new RequiredError('month', 'Required parameter month was null or undefined when calling removeKintaiReport.');
      }

      const localVarPath = `/api/v1/kintai/users/{userId}/{year}/{month}`.replace(`{${"userId"}}`, encodeURIComponent(String(userId))).replace(`{${"year"}}`, encodeURIComponent(String(year))).replace(`{${"month"}}`, encodeURIComponent(String(month)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'DELETE'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     *
     * @summary 勤怠情報保存
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {KintaiReportEditEntity} KintaiReportEditEntity 保存する勤怠情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveKintaiReport(userId, year, month, KintaiReportEditEntity, options = {}) {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling saveKintaiReport.');
      } // verify required parameter 'year' is not null or undefined


      if (year === null || year === undefined) {
        throw new RequiredError('year', 'Required parameter year was null or undefined when calling saveKintaiReport.');
      } // verify required parameter 'month' is not null or undefined


      if (month === null || month === undefined) {
        throw new RequiredError('month', 'Required parameter month was null or undefined when calling saveKintaiReport.');
      } // verify required parameter 'KintaiReportEditEntity' is not null or undefined


      if (KintaiReportEditEntity === null || KintaiReportEditEntity === undefined) {
        throw new RequiredError('KintaiReportEditEntity', 'Required parameter KintaiReportEditEntity was null or undefined when calling saveKintaiReport.');
      }

      const localVarPath = `/api/v1/kintai/users/{userId}/{year}/{month}/save`.replace(`{${"userId"}}`, encodeURIComponent(String(userId))).replace(`{${"year"}}`, encodeURIComponent(String(year))).replace(`{${"month"}}`, encodeURIComponent(String(month)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'POST'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';
      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization = "KintaiReportEditEntity" !== "string" || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(KintaiReportEditEntity || {}) : KintaiReportEditEntity || "";
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     *
     * @summary 出勤打刻
     * @param {string} userId ユーザID
     * @param {boolean} [allowOverwrite] 上書き許可
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setTodayArrivalStamp(userId, allowOverwrite, options = {}) {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling setTodayArrivalStamp.');
      }

      const localVarPath = `/api/v1/kintai/users/{userId}/today/arrival_stamp`.replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'POST'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      if (allowOverwrite !== undefined) {
        localVarQueryParameter['allowOverwrite'] = allowOverwrite;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     *
     * @summary 退勤打刻
     * @param {string} userId ユーザID
     * @param {boolean} [allowOverwrite] 上書き許可
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setTodayLeaveStamp(userId, allowOverwrite, options = {}) {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling setTodayLeaveStamp.');
      }

      const localVarPath = `/api/v1/kintai/users/{userId}/today/leave_stamp`.replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'POST'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      if (allowOverwrite !== undefined) {
        localVarQueryParameter['allowOverwrite'] = allowOverwrite;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     *
     * @summary 勤怠属性更新
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {MonthlyReportAttributesEditEntity} MonthlyReportAttributesEditEntity 勤怠属性
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateKintaiAttributes(userId, year, month, MonthlyReportAttributesEditEntity, options = {}) {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling updateKintaiAttributes.');
      } // verify required parameter 'year' is not null or undefined


      if (year === null || year === undefined) {
        throw new RequiredError('year', 'Required parameter year was null or undefined when calling updateKintaiAttributes.');
      } // verify required parameter 'month' is not null or undefined


      if (month === null || month === undefined) {
        throw new RequiredError('month', 'Required parameter month was null or undefined when calling updateKintaiAttributes.');
      } // verify required parameter 'MonthlyReportAttributesEditEntity' is not null or undefined


      if (MonthlyReportAttributesEditEntity === null || MonthlyReportAttributesEditEntity === undefined) {
        throw new RequiredError('MonthlyReportAttributesEditEntity', 'Required parameter MonthlyReportAttributesEditEntity was null or undefined when calling updateKintaiAttributes.');
      }

      const localVarPath = `/api/v1/kintai/users/{userId}/{year}/{month}/attributes`.replace(`{${"userId"}}`, encodeURIComponent(String(userId))).replace(`{${"year"}}`, encodeURIComponent(String(year))).replace(`{${"month"}}`, encodeURIComponent(String(month)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'POST'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';
      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization = "MonthlyReportAttributesEditEntity" !== "string" || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(MonthlyReportAttributesEditEntity || {}) : MonthlyReportAttributesEditEntity || "";
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     *
     * @summary 勤怠情報バリデーション
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {KintaiReportEditEntity} KintaiReportEditEntity 検証する勤怠情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validateKintaiReport(userId, year, month, KintaiReportEditEntity, options = {}) {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling validateKintaiReport.');
      } // verify required parameter 'year' is not null or undefined


      if (year === null || year === undefined) {
        throw new RequiredError('year', 'Required parameter year was null or undefined when calling validateKintaiReport.');
      } // verify required parameter 'month' is not null or undefined


      if (month === null || month === undefined) {
        throw new RequiredError('month', 'Required parameter month was null or undefined when calling validateKintaiReport.');
      } // verify required parameter 'KintaiReportEditEntity' is not null or undefined


      if (KintaiReportEditEntity === null || KintaiReportEditEntity === undefined) {
        throw new RequiredError('KintaiReportEditEntity', 'Required parameter KintaiReportEditEntity was null or undefined when calling validateKintaiReport.');
      }

      const localVarPath = `/api/v1/kintai/users/{userId}/{year}/{month}/validate`.replace(`{${"userId"}}`, encodeURIComponent(String(userId))).replace(`{${"year"}}`, encodeURIComponent(String(year))).replace(`{${"month"}}`, encodeURIComponent(String(month)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'POST'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';
      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization = "KintaiReportEditEntity" !== "string" || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(KintaiReportEditEntity || {}) : KintaiReportEditEntity || "";
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    }

  };
};
/**
 * KintaiUsersReportControllerApi - functional programming interface
 * @export
 */

export const KintaiUsersReportControllerApiFp = function (configuration) {
  return {
    /**
     *
     * @summary 勤怠情報取得
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getKintaiReport(userId, year, month, options) {
      const localVarFetchArgs = KintaiUsersReportControllerApiFetchParamCreator(configuration).getKintaiReport(userId, year, month, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },

    /**
     *
     * @summary 勤怠属性再計算
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refreshKintaiAttributes(userId, year, month, options) {
      const localVarFetchArgs = KintaiUsersReportControllerApiFetchParamCreator(configuration).refreshKintaiAttributes(userId, year, month, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },

    /**
     *
     * @summary 勤怠再計算
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refreshReport(userId, year, month, options) {
      const localVarFetchArgs = KintaiUsersReportControllerApiFetchParamCreator(configuration).refreshReport(userId, year, month, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },

    /**
     *
     * @summary 勤怠情報削除
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeKintaiReport(userId, year, month, options) {
      const localVarFetchArgs = KintaiUsersReportControllerApiFetchParamCreator(configuration).removeKintaiReport(userId, year, month, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },

    /**
     *
     * @summary 勤怠情報保存
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {KintaiReportEditEntity} KintaiReportEditEntity 保存する勤怠情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveKintaiReport(userId, year, month, KintaiReportEditEntity, options) {
      const localVarFetchArgs = KintaiUsersReportControllerApiFetchParamCreator(configuration).saveKintaiReport(userId, year, month, KintaiReportEditEntity, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },

    /**
     *
     * @summary 出勤打刻
     * @param {string} userId ユーザID
     * @param {boolean} [allowOverwrite] 上書き許可
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setTodayArrivalStamp(userId, allowOverwrite, options) {
      const localVarFetchArgs = KintaiUsersReportControllerApiFetchParamCreator(configuration).setTodayArrivalStamp(userId, allowOverwrite, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },

    /**
     *
     * @summary 退勤打刻
     * @param {string} userId ユーザID
     * @param {boolean} [allowOverwrite] 上書き許可
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setTodayLeaveStamp(userId, allowOverwrite, options) {
      const localVarFetchArgs = KintaiUsersReportControllerApiFetchParamCreator(configuration).setTodayLeaveStamp(userId, allowOverwrite, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },

    /**
     *
     * @summary 勤怠属性更新
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {MonthlyReportAttributesEditEntity} MonthlyReportAttributesEditEntity 勤怠属性
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateKintaiAttributes(userId, year, month, MonthlyReportAttributesEditEntity, options) {
      const localVarFetchArgs = KintaiUsersReportControllerApiFetchParamCreator(configuration).updateKintaiAttributes(userId, year, month, MonthlyReportAttributesEditEntity, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },

    /**
     *
     * @summary 勤怠情報バリデーション
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {KintaiReportEditEntity} KintaiReportEditEntity 検証する勤怠情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validateKintaiReport(userId, year, month, KintaiReportEditEntity, options) {
      const localVarFetchArgs = KintaiUsersReportControllerApiFetchParamCreator(configuration).validateKintaiReport(userId, year, month, KintaiReportEditEntity, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    }

  };
};
/**
 * KintaiUsersReportControllerApi - factory interface
 * @export
 */

export const KintaiUsersReportControllerApiFactory = function (configuration, fetch, basePath) {
  return {
    /**
     *
     * @summary 勤怠情報取得
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getKintaiReport(userId, year, month, options) {
      return KintaiUsersReportControllerApiFp(configuration).getKintaiReport(userId, year, month, options)(fetch, basePath);
    },

    /**
     *
     * @summary 勤怠属性再計算
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refreshKintaiAttributes(userId, year, month, options) {
      return KintaiUsersReportControllerApiFp(configuration).refreshKintaiAttributes(userId, year, month, options)(fetch, basePath);
    },

    /**
     *
     * @summary 勤怠再計算
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refreshReport(userId, year, month, options) {
      return KintaiUsersReportControllerApiFp(configuration).refreshReport(userId, year, month, options)(fetch, basePath);
    },

    /**
     *
     * @summary 勤怠情報削除
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeKintaiReport(userId, year, month, options) {
      return KintaiUsersReportControllerApiFp(configuration).removeKintaiReport(userId, year, month, options)(fetch, basePath);
    },

    /**
     *
     * @summary 勤怠情報保存
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {KintaiReportEditEntity} KintaiReportEditEntity 保存する勤怠情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveKintaiReport(userId, year, month, KintaiReportEditEntity, options) {
      return KintaiUsersReportControllerApiFp(configuration).saveKintaiReport(userId, year, month, KintaiReportEditEntity, options)(fetch, basePath);
    },

    /**
     *
     * @summary 出勤打刻
     * @param {string} userId ユーザID
     * @param {boolean} [allowOverwrite] 上書き許可
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setTodayArrivalStamp(userId, allowOverwrite, options) {
      return KintaiUsersReportControllerApiFp(configuration).setTodayArrivalStamp(userId, allowOverwrite, options)(fetch, basePath);
    },

    /**
     *
     * @summary 退勤打刻
     * @param {string} userId ユーザID
     * @param {boolean} [allowOverwrite] 上書き許可
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setTodayLeaveStamp(userId, allowOverwrite, options) {
      return KintaiUsersReportControllerApiFp(configuration).setTodayLeaveStamp(userId, allowOverwrite, options)(fetch, basePath);
    },

    /**
     *
     * @summary 勤怠属性更新
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {MonthlyReportAttributesEditEntity} MonthlyReportAttributesEditEntity 勤怠属性
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateKintaiAttributes(userId, year, month, MonthlyReportAttributesEditEntity, options) {
      return KintaiUsersReportControllerApiFp(configuration).updateKintaiAttributes(userId, year, month, MonthlyReportAttributesEditEntity, options)(fetch, basePath);
    },

    /**
     *
     * @summary 勤怠情報バリデーション
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {KintaiReportEditEntity} KintaiReportEditEntity 検証する勤怠情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validateKintaiReport(userId, year, month, KintaiReportEditEntity, options) {
      return KintaiUsersReportControllerApiFp(configuration).validateKintaiReport(userId, year, month, KintaiReportEditEntity, options)(fetch, basePath);
    }

  };
};
/**
 * KintaiUsersReportControllerApi - object-oriented interface
 * @export
 * @class KintaiUsersReportControllerApi
 * @extends {BaseAPI}
 */

export class KintaiUsersReportControllerApi extends BaseAPI {
  /**
   *
   * @summary 勤怠情報取得
   * @param {string} userId ユーザID
   * @param {number} year 年
   * @param {number} month 月
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KintaiUsersReportControllerApi
   */
  getKintaiReport(userId, year, month, options) {
    return KintaiUsersReportControllerApiFp(this.configuration).getKintaiReport(userId, year, month, options)(this.fetch, this.basePath);
  }
  /**
   *
   * @summary 勤怠属性再計算
   * @param {string} userId ユーザID
   * @param {number} year 年
   * @param {number} month 月
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KintaiUsersReportControllerApi
   */


  refreshKintaiAttributes(userId, year, month, options) {
    return KintaiUsersReportControllerApiFp(this.configuration).refreshKintaiAttributes(userId, year, month, options)(this.fetch, this.basePath);
  }
  /**
   *
   * @summary 勤怠再計算
   * @param {string} userId ユーザID
   * @param {number} year 年
   * @param {number} month 月
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KintaiUsersReportControllerApi
   */


  refreshReport(userId, year, month, options) {
    return KintaiUsersReportControllerApiFp(this.configuration).refreshReport(userId, year, month, options)(this.fetch, this.basePath);
  }
  /**
   *
   * @summary 勤怠情報削除
   * @param {string} userId ユーザID
   * @param {number} year 年
   * @param {number} month 月
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KintaiUsersReportControllerApi
   */


  removeKintaiReport(userId, year, month, options) {
    return KintaiUsersReportControllerApiFp(this.configuration).removeKintaiReport(userId, year, month, options)(this.fetch, this.basePath);
  }
  /**
   *
   * @summary 勤怠情報保存
   * @param {string} userId ユーザID
   * @param {number} year 年
   * @param {number} month 月
   * @param {KintaiReportEditEntity} KintaiReportEditEntity 保存する勤怠情報
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KintaiUsersReportControllerApi
   */


  saveKintaiReport(userId, year, month, KintaiReportEditEntity, options) {
    return KintaiUsersReportControllerApiFp(this.configuration).saveKintaiReport(userId, year, month, KintaiReportEditEntity, options)(this.fetch, this.basePath);
  }
  /**
   *
   * @summary 出勤打刻
   * @param {string} userId ユーザID
   * @param {boolean} [allowOverwrite] 上書き許可
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KintaiUsersReportControllerApi
   */


  setTodayArrivalStamp(userId, allowOverwrite, options) {
    return KintaiUsersReportControllerApiFp(this.configuration).setTodayArrivalStamp(userId, allowOverwrite, options)(this.fetch, this.basePath);
  }
  /**
   *
   * @summary 退勤打刻
   * @param {string} userId ユーザID
   * @param {boolean} [allowOverwrite] 上書き許可
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KintaiUsersReportControllerApi
   */


  setTodayLeaveStamp(userId, allowOverwrite, options) {
    return KintaiUsersReportControllerApiFp(this.configuration).setTodayLeaveStamp(userId, allowOverwrite, options)(this.fetch, this.basePath);
  }
  /**
   *
   * @summary 勤怠属性更新
   * @param {string} userId ユーザID
   * @param {number} year 年
   * @param {number} month 月
   * @param {MonthlyReportAttributesEditEntity} MonthlyReportAttributesEditEntity 勤怠属性
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KintaiUsersReportControllerApi
   */


  updateKintaiAttributes(userId, year, month, MonthlyReportAttributesEditEntity, options) {
    return KintaiUsersReportControllerApiFp(this.configuration).updateKintaiAttributes(userId, year, month, MonthlyReportAttributesEditEntity, options)(this.fetch, this.basePath);
  }
  /**
   *
   * @summary 勤怠情報バリデーション
   * @param {string} userId ユーザID
   * @param {number} year 年
   * @param {number} month 月
   * @param {KintaiReportEditEntity} KintaiReportEditEntity 検証する勤怠情報
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof KintaiUsersReportControllerApi
   */


  validateKintaiReport(userId, year, month, KintaiReportEditEntity, options) {
    return KintaiUsersReportControllerApiFp(this.configuration).validateKintaiReport(userId, year, month, KintaiReportEditEntity, options)(this.fetch, this.basePath);
  }

}
/**
 * LoginControllerApi - fetch parameter creator
 * @export
 */

export const LoginControllerApiFetchParamCreator = function (configuration) {
  return {
    /**
     *
     * @summary ログイン
     * @param {LoginParams} LoginParams ログインパラメータ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLoginToken(LoginParams, options = {}) {
      // verify required parameter 'LoginParams' is not null or undefined
      if (LoginParams === null || LoginParams === undefined) {
        throw new RequiredError('LoginParams', 'Required parameter LoginParams was null or undefined when calling getLoginToken.');
      }

      const localVarPath = `/api/v1/login`;
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'POST'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      localVarHeaderParameter['Content-Type'] = 'application/json';
      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization = "LoginParams" !== "string" || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(LoginParams || {}) : LoginParams || "";
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     *
     * @summary ログアウト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    logout(options = {}) {
      const localVarPath = `/api/v1/logout`;
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'POST'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    }

  };
};
/**
 * LoginControllerApi - functional programming interface
 * @export
 */

export const LoginControllerApiFp = function (configuration) {
  return {
    /**
     *
     * @summary ログイン
     * @param {LoginParams} LoginParams ログインパラメータ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLoginToken(LoginParams, options) {
      const localVarFetchArgs = LoginControllerApiFetchParamCreator(configuration).getLoginToken(LoginParams, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },

    /**
     *
     * @summary ログアウト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    logout(options) {
      const localVarFetchArgs = LoginControllerApiFetchParamCreator(configuration).logout(options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    }

  };
};
/**
 * LoginControllerApi - factory interface
 * @export
 */

export const LoginControllerApiFactory = function (configuration, fetch, basePath) {
  return {
    /**
     *
     * @summary ログイン
     * @param {LoginParams} LoginParams ログインパラメータ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLoginToken(LoginParams, options) {
      return LoginControllerApiFp(configuration).getLoginToken(LoginParams, options)(fetch, basePath);
    },

    /**
     *
     * @summary ログアウト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    logout(options) {
      return LoginControllerApiFp(configuration).logout(options)(fetch, basePath);
    }

  };
};
/**
 * LoginControllerApi - object-oriented interface
 * @export
 * @class LoginControllerApi
 * @extends {BaseAPI}
 */

export class LoginControllerApi extends BaseAPI {
  /**
   *
   * @summary ログイン
   * @param {LoginParams} LoginParams ログインパラメータ
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LoginControllerApi
   */
  getLoginToken(LoginParams, options) {
    return LoginControllerApiFp(this.configuration).getLoginToken(LoginParams, options)(this.fetch, this.basePath);
  }
  /**
   *
   * @summary ログアウト
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LoginControllerApi
   */


  logout(options) {
    return LoginControllerApiFp(this.configuration).logout(options)(this.fetch, this.basePath);
  }

}
/**
 * MeControllerApi - fetch parameter creator
 * @export
 */

export const MeControllerApiFetchParamCreator = function (configuration) {
  return {
    /**
     *
     * @summary 通知取得
     * @param {number} id 通知ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNotification(id, options = {}) {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling getNotification.');
      }

      const localVarPath = `/api/v1/me/notifications/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'GET'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     *
     * @summary 通知一覧取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNotifications(options = {}) {
      const localVarPath = `/api/v1/me/notifications`;
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'GET'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     *
     * @summary プロファイル取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProfile(options = {}) {
      const localVarPath = `/api/v1/me`;
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'GET'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     *
     * @summary 疲労蓄積度チェック情報一覧（本人用）取得
     * @param {number} nendo 年度
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserFatigueChecks(nendo, options = {}) {
      // verify required parameter 'nendo' is not null or undefined
      if (nendo === null || nendo === undefined) {
        throw new RequiredError('nendo', 'Required parameter nendo was null or undefined when calling getUserFatigueChecks.');
      }

      const localVarPath = `/api/v1/me/fatigue_checks/{nendo}/user`.replace(`{${"nendo"}}`, encodeURIComponent(String(nendo)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'GET'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     *
     * @summary 産業医面談一覧（本人用）取得
     * @param {number} nendo 年度
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserPhysicianInterviews(nendo, options = {}) {
      // verify required parameter 'nendo' is not null or undefined
      if (nendo === null || nendo === undefined) {
        throw new RequiredError('nendo', 'Required parameter nendo was null or undefined when calling getUserPhysicianInterviews.');
      }

      const localVarPath = `/api/v1/me/physician_interviews/{nendo}/user`.replace(`{${"nendo"}}`, encodeURIComponent(String(nendo)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'GET'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     *
     * @summary 通知既読セット
     * @param {Array<NotificationReadEntity>} NotificationReadEntity 通知既読設定リスト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readNotifications(NotificationReadEntity, options = {}) {
      // verify required parameter 'NotificationReadEntity' is not null or undefined
      if (NotificationReadEntity === null || NotificationReadEntity === undefined) {
        throw new RequiredError('NotificationReadEntity', 'Required parameter NotificationReadEntity was null or undefined when calling readNotifications.');
      }

      const localVarPath = `/api/v1/me/notifications/read`;
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'POST'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';
      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization = "Array&lt;NotificationReadEntity&gt;" !== "string" || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(NotificationReadEntity || {}) : NotificationReadEntity || "";
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     *
     * @summary 産業医面談予約
     * @param {number} year 年
     * @param {number} month 月
     * @param {PhysicianInterviewReserveRequest} PhysicianInterviewReserveRequest 産業医面談予約リクエスト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reserveUserPhysicianInterview(year, month, PhysicianInterviewReserveRequest, options = {}) {
      // verify required parameter 'year' is not null or undefined
      if (year === null || year === undefined) {
        throw new RequiredError('year', 'Required parameter year was null or undefined when calling reserveUserPhysicianInterview.');
      } // verify required parameter 'month' is not null or undefined


      if (month === null || month === undefined) {
        throw new RequiredError('month', 'Required parameter month was null or undefined when calling reserveUserPhysicianInterview.');
      } // verify required parameter 'PhysicianInterviewReserveRequest' is not null or undefined


      if (PhysicianInterviewReserveRequest === null || PhysicianInterviewReserveRequest === undefined) {
        throw new RequiredError('PhysicianInterviewReserveRequest', 'Required parameter PhysicianInterviewReserveRequest was null or undefined when calling reserveUserPhysicianInterview.');
      }

      const localVarPath = `/api/v1/me/physician_interviews/{year}/{month}/user`.replace(`{${"year"}}`, encodeURIComponent(String(year))).replace(`{${"month"}}`, encodeURIComponent(String(month)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'POST'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';
      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization = "PhysicianInterviewReserveRequest" !== "string" || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(PhysicianInterviewReserveRequest || {}) : PhysicianInterviewReserveRequest || "";
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     *
     * @summary 疲労蓄積度チェックリスト提出日設定
     * @param {number} year 年
     * @param {number} month 月
     * @param {FatigueCheckSubmitRequest} FatigueCheckSubmitRequest 疲労蓄積度チェックリスト提出日リクエスト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setUserFatigueCheckSubmittedDate(year, month, FatigueCheckSubmitRequest, options = {}) {
      // verify required parameter 'year' is not null or undefined
      if (year === null || year === undefined) {
        throw new RequiredError('year', 'Required parameter year was null or undefined when calling setUserFatigueCheckSubmittedDate.');
      } // verify required parameter 'month' is not null or undefined


      if (month === null || month === undefined) {
        throw new RequiredError('month', 'Required parameter month was null or undefined when calling setUserFatigueCheckSubmittedDate.');
      } // verify required parameter 'FatigueCheckSubmitRequest' is not null or undefined


      if (FatigueCheckSubmitRequest === null || FatigueCheckSubmitRequest === undefined) {
        throw new RequiredError('FatigueCheckSubmitRequest', 'Required parameter FatigueCheckSubmitRequest was null or undefined when calling setUserFatigueCheckSubmittedDate.');
      }

      const localVarPath = `/api/v1/me/fatigue_checks/{year}/{month}/user`.replace(`{${"year"}}`, encodeURIComponent(String(year))).replace(`{${"month"}}`, encodeURIComponent(String(month)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'POST'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';
      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization = "FatigueCheckSubmitRequest" !== "string" || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(FatigueCheckSubmitRequest || {}) : FatigueCheckSubmitRequest || "";
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     *
     * @summary ユーザプロファイル更新
     * @param {ProfileUpdateRequest} ProfileUpdateRequest 更新するユーザプロファイル
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateProfile(ProfileUpdateRequest, options = {}) {
      // verify required parameter 'ProfileUpdateRequest' is not null or undefined
      if (ProfileUpdateRequest === null || ProfileUpdateRequest === undefined) {
        throw new RequiredError('ProfileUpdateRequest', 'Required parameter ProfileUpdateRequest was null or undefined when calling updateProfile.');
      }

      const localVarPath = `/api/v1/me/profile`;
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'POST'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';
      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization = "ProfileUpdateRequest" !== "string" || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(ProfileUpdateRequest || {}) : ProfileUpdateRequest || "";
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    }

  };
};
/**
 * MeControllerApi - functional programming interface
 * @export
 */

export const MeControllerApiFp = function (configuration) {
  return {
    /**
     *
     * @summary 通知取得
     * @param {number} id 通知ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNotification(id, options) {
      const localVarFetchArgs = MeControllerApiFetchParamCreator(configuration).getNotification(id, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },

    /**
     *
     * @summary 通知一覧取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNotifications(options) {
      const localVarFetchArgs = MeControllerApiFetchParamCreator(configuration).getNotifications(options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },

    /**
     *
     * @summary プロファイル取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProfile(options) {
      const localVarFetchArgs = MeControllerApiFetchParamCreator(configuration).getProfile(options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },

    /**
     *
     * @summary 疲労蓄積度チェック情報一覧（本人用）取得
     * @param {number} nendo 年度
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserFatigueChecks(nendo, options) {
      const localVarFetchArgs = MeControllerApiFetchParamCreator(configuration).getUserFatigueChecks(nendo, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },

    /**
     *
     * @summary 産業医面談一覧（本人用）取得
     * @param {number} nendo 年度
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserPhysicianInterviews(nendo, options) {
      const localVarFetchArgs = MeControllerApiFetchParamCreator(configuration).getUserPhysicianInterviews(nendo, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },

    /**
     *
     * @summary 通知既読セット
     * @param {Array<NotificationReadEntity>} NotificationReadEntity 通知既読設定リスト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readNotifications(NotificationReadEntity, options) {
      const localVarFetchArgs = MeControllerApiFetchParamCreator(configuration).readNotifications(NotificationReadEntity, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },

    /**
     *
     * @summary 産業医面談予約
     * @param {number} year 年
     * @param {number} month 月
     * @param {PhysicianInterviewReserveRequest} PhysicianInterviewReserveRequest 産業医面談予約リクエスト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reserveUserPhysicianInterview(year, month, PhysicianInterviewReserveRequest, options) {
      const localVarFetchArgs = MeControllerApiFetchParamCreator(configuration).reserveUserPhysicianInterview(year, month, PhysicianInterviewReserveRequest, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },

    /**
     *
     * @summary 疲労蓄積度チェックリスト提出日設定
     * @param {number} year 年
     * @param {number} month 月
     * @param {FatigueCheckSubmitRequest} FatigueCheckSubmitRequest 疲労蓄積度チェックリスト提出日リクエスト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setUserFatigueCheckSubmittedDate(year, month, FatigueCheckSubmitRequest, options) {
      const localVarFetchArgs = MeControllerApiFetchParamCreator(configuration).setUserFatigueCheckSubmittedDate(year, month, FatigueCheckSubmitRequest, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },

    /**
     *
     * @summary ユーザプロファイル更新
     * @param {ProfileUpdateRequest} ProfileUpdateRequest 更新するユーザプロファイル
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateProfile(ProfileUpdateRequest, options) {
      const localVarFetchArgs = MeControllerApiFetchParamCreator(configuration).updateProfile(ProfileUpdateRequest, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    }

  };
};
/**
 * MeControllerApi - factory interface
 * @export
 */

export const MeControllerApiFactory = function (configuration, fetch, basePath) {
  return {
    /**
     *
     * @summary 通知取得
     * @param {number} id 通知ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNotification(id, options) {
      return MeControllerApiFp(configuration).getNotification(id, options)(fetch, basePath);
    },

    /**
     *
     * @summary 通知一覧取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNotifications(options) {
      return MeControllerApiFp(configuration).getNotifications(options)(fetch, basePath);
    },

    /**
     *
     * @summary プロファイル取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProfile(options) {
      return MeControllerApiFp(configuration).getProfile(options)(fetch, basePath);
    },

    /**
     *
     * @summary 疲労蓄積度チェック情報一覧（本人用）取得
     * @param {number} nendo 年度
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserFatigueChecks(nendo, options) {
      return MeControllerApiFp(configuration).getUserFatigueChecks(nendo, options)(fetch, basePath);
    },

    /**
     *
     * @summary 産業医面談一覧（本人用）取得
     * @param {number} nendo 年度
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserPhysicianInterviews(nendo, options) {
      return MeControllerApiFp(configuration).getUserPhysicianInterviews(nendo, options)(fetch, basePath);
    },

    /**
     *
     * @summary 通知既読セット
     * @param {Array<NotificationReadEntity>} NotificationReadEntity 通知既読設定リスト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readNotifications(NotificationReadEntity, options) {
      return MeControllerApiFp(configuration).readNotifications(NotificationReadEntity, options)(fetch, basePath);
    },

    /**
     *
     * @summary 産業医面談予約
     * @param {number} year 年
     * @param {number} month 月
     * @param {PhysicianInterviewReserveRequest} PhysicianInterviewReserveRequest 産業医面談予約リクエスト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reserveUserPhysicianInterview(year, month, PhysicianInterviewReserveRequest, options) {
      return MeControllerApiFp(configuration).reserveUserPhysicianInterview(year, month, PhysicianInterviewReserveRequest, options)(fetch, basePath);
    },

    /**
     *
     * @summary 疲労蓄積度チェックリスト提出日設定
     * @param {number} year 年
     * @param {number} month 月
     * @param {FatigueCheckSubmitRequest} FatigueCheckSubmitRequest 疲労蓄積度チェックリスト提出日リクエスト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setUserFatigueCheckSubmittedDate(year, month, FatigueCheckSubmitRequest, options) {
      return MeControllerApiFp(configuration).setUserFatigueCheckSubmittedDate(year, month, FatigueCheckSubmitRequest, options)(fetch, basePath);
    },

    /**
     *
     * @summary ユーザプロファイル更新
     * @param {ProfileUpdateRequest} ProfileUpdateRequest 更新するユーザプロファイル
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateProfile(ProfileUpdateRequest, options) {
      return MeControllerApiFp(configuration).updateProfile(ProfileUpdateRequest, options)(fetch, basePath);
    }

  };
};
/**
 * MeControllerApi - object-oriented interface
 * @export
 * @class MeControllerApi
 * @extends {BaseAPI}
 */

export class MeControllerApi extends BaseAPI {
  /**
   *
   * @summary 通知取得
   * @param {number} id 通知ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MeControllerApi
   */
  getNotification(id, options) {
    return MeControllerApiFp(this.configuration).getNotification(id, options)(this.fetch, this.basePath);
  }
  /**
   *
   * @summary 通知一覧取得
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MeControllerApi
   */


  getNotifications(options) {
    return MeControllerApiFp(this.configuration).getNotifications(options)(this.fetch, this.basePath);
  }
  /**
   *
   * @summary プロファイル取得
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MeControllerApi
   */


  getProfile(options) {
    return MeControllerApiFp(this.configuration).getProfile(options)(this.fetch, this.basePath);
  }
  /**
   *
   * @summary 疲労蓄積度チェック情報一覧（本人用）取得
   * @param {number} nendo 年度
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MeControllerApi
   */


  getUserFatigueChecks(nendo, options) {
    return MeControllerApiFp(this.configuration).getUserFatigueChecks(nendo, options)(this.fetch, this.basePath);
  }
  /**
   *
   * @summary 産業医面談一覧（本人用）取得
   * @param {number} nendo 年度
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MeControllerApi
   */


  getUserPhysicianInterviews(nendo, options) {
    return MeControllerApiFp(this.configuration).getUserPhysicianInterviews(nendo, options)(this.fetch, this.basePath);
  }
  /**
   *
   * @summary 通知既読セット
   * @param {Array<NotificationReadEntity>} NotificationReadEntity 通知既読設定リスト
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MeControllerApi
   */


  readNotifications(NotificationReadEntity, options) {
    return MeControllerApiFp(this.configuration).readNotifications(NotificationReadEntity, options)(this.fetch, this.basePath);
  }
  /**
   *
   * @summary 産業医面談予約
   * @param {number} year 年
   * @param {number} month 月
   * @param {PhysicianInterviewReserveRequest} PhysicianInterviewReserveRequest 産業医面談予約リクエスト
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MeControllerApi
   */


  reserveUserPhysicianInterview(year, month, PhysicianInterviewReserveRequest, options) {
    return MeControllerApiFp(this.configuration).reserveUserPhysicianInterview(year, month, PhysicianInterviewReserveRequest, options)(this.fetch, this.basePath);
  }
  /**
   *
   * @summary 疲労蓄積度チェックリスト提出日設定
   * @param {number} year 年
   * @param {number} month 月
   * @param {FatigueCheckSubmitRequest} FatigueCheckSubmitRequest 疲労蓄積度チェックリスト提出日リクエスト
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MeControllerApi
   */


  setUserFatigueCheckSubmittedDate(year, month, FatigueCheckSubmitRequest, options) {
    return MeControllerApiFp(this.configuration).setUserFatigueCheckSubmittedDate(year, month, FatigueCheckSubmitRequest, options)(this.fetch, this.basePath);
  }
  /**
   *
   * @summary ユーザプロファイル更新
   * @param {ProfileUpdateRequest} ProfileUpdateRequest 更新するユーザプロファイル
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MeControllerApi
   */


  updateProfile(ProfileUpdateRequest, options) {
    return MeControllerApiFp(this.configuration).updateProfile(ProfileUpdateRequest, options)(this.fetch, this.basePath);
  }

}
/**
 * OidcControllerApi - fetch parameter creator
 * @export
 */

export const OidcControllerApiFetchParamCreator = function (configuration) {
  return {
    /**
     *
     * @summary ログイントークン取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOidcLoginToken(options = {}) {
      const localVarPath = `/api/v1/oidc/login_token`;
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'POST'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     *
     * @summary OIDCプロバイダーリスト取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProviders(options = {}) {
      const localVarPath = `/api/v1/oidc/providers`;
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'GET'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     *
     * @summary OIDCコールバック
     * @param {string} [error] error
     * @param {string} [errorDescription]
     * @param {string} [id_token] ID Token
     * @param {string} [state] state
     * @param {string} [error_description] errorDescription
     * @param {string} [idToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    oidcCallback(error, errorDescription, id_token, state, error_description, idToken, options = {}) {
      const localVarPath = `/api/v1/oidc/callback`;
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'POST'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      const localVarFormParams = new url.URLSearchParams();

      if (error !== undefined) {
        localVarFormParams.set('error', error);
      }

      if (errorDescription !== undefined) {
        localVarFormParams.set('errorDescription', errorDescription);
      }

      if (id_token !== undefined) {
        localVarFormParams.set('id_token', id_token);
      }

      if (state !== undefined) {
        localVarFormParams.set('state', state);
      }

      if (error_description !== undefined) {
        localVarFormParams.set('error_description', error_description);
      }

      if (idToken !== undefined) {
        localVarFormParams.set('idToken', idToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      localVarRequestOptions.body = localVarFormParams.toString();
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     *
     * @summary OIDCログイン
     * @param {string} providerId OIDCプロバイダID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    oidcLogin(providerId, options = {}) {
      // verify required parameter 'providerId' is not null or undefined
      if (providerId === null || providerId === undefined) {
        throw new RequiredError('providerId', 'Required parameter providerId was null or undefined when calling oidcLogin.');
      }

      const localVarPath = `/api/v1/oidc/login`;
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'GET'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};

      if (providerId !== undefined) {
        localVarQueryParameter['providerId'] = providerId;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    }

  };
};
/**
 * OidcControllerApi - functional programming interface
 * @export
 */

export const OidcControllerApiFp = function (configuration) {
  return {
    /**
     *
     * @summary ログイントークン取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOidcLoginToken(options) {
      const localVarFetchArgs = OidcControllerApiFetchParamCreator(configuration).getOidcLoginToken(options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },

    /**
     *
     * @summary OIDCプロバイダーリスト取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProviders(options) {
      const localVarFetchArgs = OidcControllerApiFetchParamCreator(configuration).getProviders(options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },

    /**
     *
     * @summary OIDCコールバック
     * @param {string} [error] error
     * @param {string} [errorDescription]
     * @param {string} [id_token] ID Token
     * @param {string} [state] state
     * @param {string} [error_description] errorDescription
     * @param {string} [idToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    oidcCallback(error, errorDescription, id_token, state, error_description, idToken, options) {
      const localVarFetchArgs = OidcControllerApiFetchParamCreator(configuration).oidcCallback(error, errorDescription, id_token, state, error_description, idToken, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },

    /**
     *
     * @summary OIDCログイン
     * @param {string} providerId OIDCプロバイダID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    oidcLogin(providerId, options) {
      const localVarFetchArgs = OidcControllerApiFetchParamCreator(configuration).oidcLogin(providerId, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    }

  };
};
/**
 * OidcControllerApi - factory interface
 * @export
 */

export const OidcControllerApiFactory = function (configuration, fetch, basePath) {
  return {
    /**
     *
     * @summary ログイントークン取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOidcLoginToken(options) {
      return OidcControllerApiFp(configuration).getOidcLoginToken(options)(fetch, basePath);
    },

    /**
     *
     * @summary OIDCプロバイダーリスト取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProviders(options) {
      return OidcControllerApiFp(configuration).getProviders(options)(fetch, basePath);
    },

    /**
     *
     * @summary OIDCコールバック
     * @param {string} [error] error
     * @param {string} [errorDescription]
     * @param {string} [id_token] ID Token
     * @param {string} [state] state
     * @param {string} [error_description] errorDescription
     * @param {string} [idToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    oidcCallback(error, errorDescription, id_token, state, error_description, idToken, options) {
      return OidcControllerApiFp(configuration).oidcCallback(error, errorDescription, id_token, state, error_description, idToken, options)(fetch, basePath);
    },

    /**
     *
     * @summary OIDCログイン
     * @param {string} providerId OIDCプロバイダID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    oidcLogin(providerId, options) {
      return OidcControllerApiFp(configuration).oidcLogin(providerId, options)(fetch, basePath);
    }

  };
};
/**
 * OidcControllerApi - object-oriented interface
 * @export
 * @class OidcControllerApi
 * @extends {BaseAPI}
 */

export class OidcControllerApi extends BaseAPI {
  /**
   *
   * @summary ログイントークン取得
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OidcControllerApi
   */
  getOidcLoginToken(options) {
    return OidcControllerApiFp(this.configuration).getOidcLoginToken(options)(this.fetch, this.basePath);
  }
  /**
   *
   * @summary OIDCプロバイダーリスト取得
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OidcControllerApi
   */


  getProviders(options) {
    return OidcControllerApiFp(this.configuration).getProviders(options)(this.fetch, this.basePath);
  }
  /**
   *
   * @summary OIDCコールバック
   * @param {string} [error] error
   * @param {string} [errorDescription]
   * @param {string} [id_token] ID Token
   * @param {string} [state] state
   * @param {string} [error_description] errorDescription
   * @param {string} [idToken]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OidcControllerApi
   */


  oidcCallback(error, errorDescription, id_token, state, error_description, idToken, options) {
    return OidcControllerApiFp(this.configuration).oidcCallback(error, errorDescription, id_token, state, error_description, idToken, options)(this.fetch, this.basePath);
  }
  /**
   *
   * @summary OIDCログイン
   * @param {string} providerId OIDCプロバイダID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OidcControllerApi
   */


  oidcLogin(providerId, options) {
    return OidcControllerApiFp(this.configuration).oidcLogin(providerId, options)(this.fetch, this.basePath);
  }

}
/**
 * PasswordControllerApi - fetch parameter creator
 * @export
 */

export const PasswordControllerApiFetchParamCreator = function (configuration) {
  return {
    /**
     *
     * @summary パスワードリセット依頼
     * @param {PasswordResetRequest} PasswordResetRequest パスワードリセット依頼
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    requestReset(PasswordResetRequest, options = {}) {
      // verify required parameter 'PasswordResetRequest' is not null or undefined
      if (PasswordResetRequest === null || PasswordResetRequest === undefined) {
        throw new RequiredError('PasswordResetRequest', 'Required parameter PasswordResetRequest was null or undefined when calling requestReset.');
      }

      const localVarPath = `/api/v1/password/request_reset`;
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'POST'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      localVarHeaderParameter['Content-Type'] = 'application/json';
      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization = "PasswordResetRequest" !== "string" || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(PasswordResetRequest || {}) : PasswordResetRequest || "";
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     *
     * @summary パスワードリセット実施
     * @param {PasswordResetByTokenRequest} PasswordResetByTokenRequest パスワードリセット情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetPassword(PasswordResetByTokenRequest, options = {}) {
      // verify required parameter 'PasswordResetByTokenRequest' is not null or undefined
      if (PasswordResetByTokenRequest === null || PasswordResetByTokenRequest === undefined) {
        throw new RequiredError('PasswordResetByTokenRequest', 'Required parameter PasswordResetByTokenRequest was null or undefined when calling resetPassword.');
      }

      const localVarPath = `/api/v1/password/reset`;
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'POST'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      localVarHeaderParameter['Content-Type'] = 'application/json';
      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization = "PasswordResetByTokenRequest" !== "string" || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(PasswordResetByTokenRequest || {}) : PasswordResetByTokenRequest || "";
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     *
     * @summary パスワード変更
     * @param {PasswordUpdateRequest} PasswordUpdateRequest パスワード更新情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePassword(PasswordUpdateRequest, options = {}) {
      // verify required parameter 'PasswordUpdateRequest' is not null or undefined
      if (PasswordUpdateRequest === null || PasswordUpdateRequest === undefined) {
        throw new RequiredError('PasswordUpdateRequest', 'Required parameter PasswordUpdateRequest was null or undefined when calling updatePassword.');
      }

      const localVarPath = `/api/v1/password`;
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'POST'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {};
      localVarHeaderParameter['Content-Type'] = 'application/json';
      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization = "PasswordUpdateRequest" !== "string" || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(PasswordUpdateRequest || {}) : PasswordUpdateRequest || "";
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    }

  };
};
/**
 * PasswordControllerApi - functional programming interface
 * @export
 */

export const PasswordControllerApiFp = function (configuration) {
  return {
    /**
     *
     * @summary パスワードリセット依頼
     * @param {PasswordResetRequest} PasswordResetRequest パスワードリセット依頼
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    requestReset(PasswordResetRequest, options) {
      const localVarFetchArgs = PasswordControllerApiFetchParamCreator(configuration).requestReset(PasswordResetRequest, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },

    /**
     *
     * @summary パスワードリセット実施
     * @param {PasswordResetByTokenRequest} PasswordResetByTokenRequest パスワードリセット情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetPassword(PasswordResetByTokenRequest, options) {
      const localVarFetchArgs = PasswordControllerApiFetchParamCreator(configuration).resetPassword(PasswordResetByTokenRequest, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },

    /**
     *
     * @summary パスワード変更
     * @param {PasswordUpdateRequest} PasswordUpdateRequest パスワード更新情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePassword(PasswordUpdateRequest, options) {
      const localVarFetchArgs = PasswordControllerApiFetchParamCreator(configuration).updatePassword(PasswordUpdateRequest, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    }

  };
};
/**
 * PasswordControllerApi - factory interface
 * @export
 */

export const PasswordControllerApiFactory = function (configuration, fetch, basePath) {
  return {
    /**
     *
     * @summary パスワードリセット依頼
     * @param {PasswordResetRequest} PasswordResetRequest パスワードリセット依頼
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    requestReset(PasswordResetRequest, options) {
      return PasswordControllerApiFp(configuration).requestReset(PasswordResetRequest, options)(fetch, basePath);
    },

    /**
     *
     * @summary パスワードリセット実施
     * @param {PasswordResetByTokenRequest} PasswordResetByTokenRequest パスワードリセット情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetPassword(PasswordResetByTokenRequest, options) {
      return PasswordControllerApiFp(configuration).resetPassword(PasswordResetByTokenRequest, options)(fetch, basePath);
    },

    /**
     *
     * @summary パスワード変更
     * @param {PasswordUpdateRequest} PasswordUpdateRequest パスワード更新情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePassword(PasswordUpdateRequest, options) {
      return PasswordControllerApiFp(configuration).updatePassword(PasswordUpdateRequest, options)(fetch, basePath);
    }

  };
};
/**
 * PasswordControllerApi - object-oriented interface
 * @export
 * @class PasswordControllerApi
 * @extends {BaseAPI}
 */

export class PasswordControllerApi extends BaseAPI {
  /**
   *
   * @summary パスワードリセット依頼
   * @param {PasswordResetRequest} PasswordResetRequest パスワードリセット依頼
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PasswordControllerApi
   */
  requestReset(PasswordResetRequest, options) {
    return PasswordControllerApiFp(this.configuration).requestReset(PasswordResetRequest, options)(this.fetch, this.basePath);
  }
  /**
   *
   * @summary パスワードリセット実施
   * @param {PasswordResetByTokenRequest} PasswordResetByTokenRequest パスワードリセット情報
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PasswordControllerApi
   */


  resetPassword(PasswordResetByTokenRequest, options) {
    return PasswordControllerApiFp(this.configuration).resetPassword(PasswordResetByTokenRequest, options)(this.fetch, this.basePath);
  }
  /**
   *
   * @summary パスワード変更
   * @param {PasswordUpdateRequest} PasswordUpdateRequest パスワード更新情報
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PasswordControllerApi
   */


  updatePassword(PasswordUpdateRequest, options) {
    return PasswordControllerApiFp(this.configuration).updatePassword(PasswordUpdateRequest, options)(this.fetch, this.basePath);
  }

}
/**
 * PersonalDashboardControllerApi - fetch parameter creator
 * @export
 */

export const PersonalDashboardControllerApiFetchParamCreator = function (configuration) {
  return {
    /**
     *
     * @summary 月次系マイダッシュボード取得
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPersonalDashboardByMonth(userId, year, month, options = {}) {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling getPersonalDashboardByMonth.');
      } // verify required parameter 'year' is not null or undefined


      if (year === null || year === undefined) {
        throw new RequiredError('year', 'Required parameter year was null or undefined when calling getPersonalDashboardByMonth.');
      } // verify required parameter 'month' is not null or undefined


      if (month === null || month === undefined) {
        throw new RequiredError('month', 'Required parameter month was null or undefined when calling getPersonalDashboardByMonth.');
      }

      const localVarPath = `/api/v1/dashboard/users/{userId}/by_month/{year}/{month}`.replace(`{${"userId"}}`, encodeURIComponent(String(userId))).replace(`{${"year"}}`, encodeURIComponent(String(year))).replace(`{${"month"}}`, encodeURIComponent(String(month)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'GET'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     *
     * @summary 通年系マイダッシュボード取得
     * @param {string} userId ユーザID
     * @param {number} nendo 年度
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPersonalDashboardByNendo(userId, nendo, options = {}) {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling getPersonalDashboardByNendo.');
      } // verify required parameter 'nendo' is not null or undefined


      if (nendo === null || nendo === undefined) {
        throw new RequiredError('nendo', 'Required parameter nendo was null or undefined when calling getPersonalDashboardByNendo.');
      }

      const localVarPath = `/api/v1/dashboard/users/{userId}/by_nendo/{nendo}`.replace(`{${"userId"}}`, encodeURIComponent(String(userId))).replace(`{${"nendo"}}`, encodeURIComponent(String(nendo)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'GET'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    }

  };
};
/**
 * PersonalDashboardControllerApi - functional programming interface
 * @export
 */

export const PersonalDashboardControllerApiFp = function (configuration) {
  return {
    /**
     *
     * @summary 月次系マイダッシュボード取得
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPersonalDashboardByMonth(userId, year, month, options) {
      const localVarFetchArgs = PersonalDashboardControllerApiFetchParamCreator(configuration).getPersonalDashboardByMonth(userId, year, month, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },

    /**
     *
     * @summary 通年系マイダッシュボード取得
     * @param {string} userId ユーザID
     * @param {number} nendo 年度
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPersonalDashboardByNendo(userId, nendo, options) {
      const localVarFetchArgs = PersonalDashboardControllerApiFetchParamCreator(configuration).getPersonalDashboardByNendo(userId, nendo, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    }

  };
};
/**
 * PersonalDashboardControllerApi - factory interface
 * @export
 */

export const PersonalDashboardControllerApiFactory = function (configuration, fetch, basePath) {
  return {
    /**
     *
     * @summary 月次系マイダッシュボード取得
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPersonalDashboardByMonth(userId, year, month, options) {
      return PersonalDashboardControllerApiFp(configuration).getPersonalDashboardByMonth(userId, year, month, options)(fetch, basePath);
    },

    /**
     *
     * @summary 通年系マイダッシュボード取得
     * @param {string} userId ユーザID
     * @param {number} nendo 年度
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPersonalDashboardByNendo(userId, nendo, options) {
      return PersonalDashboardControllerApiFp(configuration).getPersonalDashboardByNendo(userId, nendo, options)(fetch, basePath);
    }

  };
};
/**
 * PersonalDashboardControllerApi - object-oriented interface
 * @export
 * @class PersonalDashboardControllerApi
 * @extends {BaseAPI}
 */

export class PersonalDashboardControllerApi extends BaseAPI {
  /**
   *
   * @summary 月次系マイダッシュボード取得
   * @param {string} userId ユーザID
   * @param {number} year 年
   * @param {number} month 月
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PersonalDashboardControllerApi
   */
  getPersonalDashboardByMonth(userId, year, month, options) {
    return PersonalDashboardControllerApiFp(this.configuration).getPersonalDashboardByMonth(userId, year, month, options)(this.fetch, this.basePath);
  }
  /**
   *
   * @summary 通年系マイダッシュボード取得
   * @param {string} userId ユーザID
   * @param {number} nendo 年度
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PersonalDashboardControllerApi
   */


  getPersonalDashboardByNendo(userId, nendo, options) {
    return PersonalDashboardControllerApiFp(this.configuration).getPersonalDashboardByNendo(userId, nendo, options)(this.fetch, this.basePath);
  }

}
/**
 * PhysicianInterviewsControllerApi - fetch parameter creator
 * @export
 */

export const PhysicianInterviewsControllerApiFetchParamCreator = function (configuration) {
  return {
    /**
     *
     * @summary 産業医面談作成
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {PhysicianInterviewEditEntity} PhysicianInterviewEditEntity 作成する産業医面談情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPhysicianInterview(userId, year, month, PhysicianInterviewEditEntity, options = {}) {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling createPhysicianInterview.');
      } // verify required parameter 'year' is not null or undefined


      if (year === null || year === undefined) {
        throw new RequiredError('year', 'Required parameter year was null or undefined when calling createPhysicianInterview.');
      } // verify required parameter 'month' is not null or undefined


      if (month === null || month === undefined) {
        throw new RequiredError('month', 'Required parameter month was null or undefined when calling createPhysicianInterview.');
      } // verify required parameter 'PhysicianInterviewEditEntity' is not null or undefined


      if (PhysicianInterviewEditEntity === null || PhysicianInterviewEditEntity === undefined) {
        throw new RequiredError('PhysicianInterviewEditEntity', 'Required parameter PhysicianInterviewEditEntity was null or undefined when calling createPhysicianInterview.');
      }

      const localVarPath = `/api/v1/physician_interviews/{userId}/{year}/{month}/create`.replace(`{${"userId"}}`, encodeURIComponent(String(userId))).replace(`{${"year"}}`, encodeURIComponent(String(year))).replace(`{${"month"}}`, encodeURIComponent(String(month)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'POST'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';
      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization = "PhysicianInterviewEditEntity" !== "string" || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(PhysicianInterviewEditEntity || {}) : PhysicianInterviewEditEntity || "";
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     *
     * @summary 産業医面談削除
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePhysicianInterview(userId, year, month, options = {}) {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling deletePhysicianInterview.');
      } // verify required parameter 'year' is not null or undefined


      if (year === null || year === undefined) {
        throw new RequiredError('year', 'Required parameter year was null or undefined when calling deletePhysicianInterview.');
      } // verify required parameter 'month' is not null or undefined


      if (month === null || month === undefined) {
        throw new RequiredError('month', 'Required parameter month was null or undefined when calling deletePhysicianInterview.');
      }

      const localVarPath = `/api/v1/physician_interviews/{userId}/{year}/{month}`.replace(`{${"userId"}}`, encodeURIComponent(String(userId))).replace(`{${"year"}}`, encodeURIComponent(String(year))).replace(`{${"month"}}`, encodeURIComponent(String(month)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'DELETE'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     *
     * @summary 産業医面談対象者一覧取得
     * @param {number} nendo 年度
     * @param {string} [depCode] 部門コード
     * @param {number} [receptionStatus] 受診状況
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPhysicianInterviews(nendo, depCode, receptionStatus, options = {}) {
      // verify required parameter 'nendo' is not null or undefined
      if (nendo === null || nendo === undefined) {
        throw new RequiredError('nendo', 'Required parameter nendo was null or undefined when calling getPhysicianInterviews.');
      }

      const localVarPath = `/api/v1/physician_interviews/{nendo}`.replace(`{${"nendo"}}`, encodeURIComponent(String(nendo)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'GET'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      if (depCode !== undefined) {
        localVarQueryParameter['depCode'] = depCode;
      }

      if (receptionStatus !== undefined) {
        localVarQueryParameter['receptionStatus'] = receptionStatus;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     *
     * @summary 産業医面談更新
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {PhysicianInterviewEditEntity} PhysicianInterviewEditEntity 更新する産業医面談情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePhysicianInterview(userId, year, month, PhysicianInterviewEditEntity, options = {}) {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling updatePhysicianInterview.');
      } // verify required parameter 'year' is not null or undefined


      if (year === null || year === undefined) {
        throw new RequiredError('year', 'Required parameter year was null or undefined when calling updatePhysicianInterview.');
      } // verify required parameter 'month' is not null or undefined


      if (month === null || month === undefined) {
        throw new RequiredError('month', 'Required parameter month was null or undefined when calling updatePhysicianInterview.');
      } // verify required parameter 'PhysicianInterviewEditEntity' is not null or undefined


      if (PhysicianInterviewEditEntity === null || PhysicianInterviewEditEntity === undefined) {
        throw new RequiredError('PhysicianInterviewEditEntity', 'Required parameter PhysicianInterviewEditEntity was null or undefined when calling updatePhysicianInterview.');
      }

      const localVarPath = `/api/v1/physician_interviews/{userId}/{year}/{month}`.replace(`{${"userId"}}`, encodeURIComponent(String(userId))).replace(`{${"year"}}`, encodeURIComponent(String(year))).replace(`{${"month"}}`, encodeURIComponent(String(month)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'POST'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';
      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization = "PhysicianInterviewEditEntity" !== "string" || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(PhysicianInterviewEditEntity || {}) : PhysicianInterviewEditEntity || "";
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    }

  };
};
/**
 * PhysicianInterviewsControllerApi - functional programming interface
 * @export
 */

export const PhysicianInterviewsControllerApiFp = function (configuration) {
  return {
    /**
     *
     * @summary 産業医面談作成
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {PhysicianInterviewEditEntity} PhysicianInterviewEditEntity 作成する産業医面談情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPhysicianInterview(userId, year, month, PhysicianInterviewEditEntity, options) {
      const localVarFetchArgs = PhysicianInterviewsControllerApiFetchParamCreator(configuration).createPhysicianInterview(userId, year, month, PhysicianInterviewEditEntity, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },

    /**
     *
     * @summary 産業医面談削除
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePhysicianInterview(userId, year, month, options) {
      const localVarFetchArgs = PhysicianInterviewsControllerApiFetchParamCreator(configuration).deletePhysicianInterview(userId, year, month, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },

    /**
     *
     * @summary 産業医面談対象者一覧取得
     * @param {number} nendo 年度
     * @param {string} [depCode] 部門コード
     * @param {number} [receptionStatus] 受診状況
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPhysicianInterviews(nendo, depCode, receptionStatus, options) {
      const localVarFetchArgs = PhysicianInterviewsControllerApiFetchParamCreator(configuration).getPhysicianInterviews(nendo, depCode, receptionStatus, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },

    /**
     *
     * @summary 産業医面談更新
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {PhysicianInterviewEditEntity} PhysicianInterviewEditEntity 更新する産業医面談情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePhysicianInterview(userId, year, month, PhysicianInterviewEditEntity, options) {
      const localVarFetchArgs = PhysicianInterviewsControllerApiFetchParamCreator(configuration).updatePhysicianInterview(userId, year, month, PhysicianInterviewEditEntity, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    }

  };
};
/**
 * PhysicianInterviewsControllerApi - factory interface
 * @export
 */

export const PhysicianInterviewsControllerApiFactory = function (configuration, fetch, basePath) {
  return {
    /**
     *
     * @summary 産業医面談作成
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {PhysicianInterviewEditEntity} PhysicianInterviewEditEntity 作成する産業医面談情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPhysicianInterview(userId, year, month, PhysicianInterviewEditEntity, options) {
      return PhysicianInterviewsControllerApiFp(configuration).createPhysicianInterview(userId, year, month, PhysicianInterviewEditEntity, options)(fetch, basePath);
    },

    /**
     *
     * @summary 産業医面談削除
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePhysicianInterview(userId, year, month, options) {
      return PhysicianInterviewsControllerApiFp(configuration).deletePhysicianInterview(userId, year, month, options)(fetch, basePath);
    },

    /**
     *
     * @summary 産業医面談対象者一覧取得
     * @param {number} nendo 年度
     * @param {string} [depCode] 部門コード
     * @param {number} [receptionStatus] 受診状況
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPhysicianInterviews(nendo, depCode, receptionStatus, options) {
      return PhysicianInterviewsControllerApiFp(configuration).getPhysicianInterviews(nendo, depCode, receptionStatus, options)(fetch, basePath);
    },

    /**
     *
     * @summary 産業医面談更新
     * @param {string} userId ユーザID
     * @param {number} year 年
     * @param {number} month 月
     * @param {PhysicianInterviewEditEntity} PhysicianInterviewEditEntity 更新する産業医面談情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePhysicianInterview(userId, year, month, PhysicianInterviewEditEntity, options) {
      return PhysicianInterviewsControllerApiFp(configuration).updatePhysicianInterview(userId, year, month, PhysicianInterviewEditEntity, options)(fetch, basePath);
    }

  };
};
/**
 * PhysicianInterviewsControllerApi - object-oriented interface
 * @export
 * @class PhysicianInterviewsControllerApi
 * @extends {BaseAPI}
 */

export class PhysicianInterviewsControllerApi extends BaseAPI {
  /**
   *
   * @summary 産業医面談作成
   * @param {string} userId ユーザID
   * @param {number} year 年
   * @param {number} month 月
   * @param {PhysicianInterviewEditEntity} PhysicianInterviewEditEntity 作成する産業医面談情報
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PhysicianInterviewsControllerApi
   */
  createPhysicianInterview(userId, year, month, PhysicianInterviewEditEntity, options) {
    return PhysicianInterviewsControllerApiFp(this.configuration).createPhysicianInterview(userId, year, month, PhysicianInterviewEditEntity, options)(this.fetch, this.basePath);
  }
  /**
   *
   * @summary 産業医面談削除
   * @param {string} userId ユーザID
   * @param {number} year 年
   * @param {number} month 月
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PhysicianInterviewsControllerApi
   */


  deletePhysicianInterview(userId, year, month, options) {
    return PhysicianInterviewsControllerApiFp(this.configuration).deletePhysicianInterview(userId, year, month, options)(this.fetch, this.basePath);
  }
  /**
   *
   * @summary 産業医面談対象者一覧取得
   * @param {number} nendo 年度
   * @param {string} [depCode] 部門コード
   * @param {number} [receptionStatus] 受診状況
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PhysicianInterviewsControllerApi
   */


  getPhysicianInterviews(nendo, depCode, receptionStatus, options) {
    return PhysicianInterviewsControllerApiFp(this.configuration).getPhysicianInterviews(nendo, depCode, receptionStatus, options)(this.fetch, this.basePath);
  }
  /**
   *
   * @summary 産業医面談更新
   * @param {string} userId ユーザID
   * @param {number} year 年
   * @param {number} month 月
   * @param {PhysicianInterviewEditEntity} PhysicianInterviewEditEntity 更新する産業医面談情報
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PhysicianInterviewsControllerApi
   */


  updatePhysicianInterview(userId, year, month, PhysicianInterviewEditEntity, options) {
    return PhysicianInterviewsControllerApiFp(this.configuration).updatePhysicianInterview(userId, year, month, PhysicianInterviewEditEntity, options)(this.fetch, this.basePath);
  }

}
/**
 * RanksControllerApi - fetch parameter creator
 * @export
 */

export const RanksControllerApiFetchParamCreator = function (configuration) {
  return {
    /**
     *
     * @summary ランク属性情報取得
     * @param {number} nendo 年度
     * @param {string} rankCode 職務ランクコード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRankAttr(nendo, rankCode, options = {}) {
      // verify required parameter 'nendo' is not null or undefined
      if (nendo === null || nendo === undefined) {
        throw new RequiredError('nendo', 'Required parameter nendo was null or undefined when calling getRankAttr.');
      } // verify required parameter 'rankCode' is not null or undefined


      if (rankCode === null || rankCode === undefined) {
        throw new RequiredError('rankCode', 'Required parameter rankCode was null or undefined when calling getRankAttr.');
      }

      const localVarPath = `/api/v1/ranks/{nendo}/{rankCode}`.replace(`{${"nendo"}}`, encodeURIComponent(String(nendo))).replace(`{${"rankCode"}}`, encodeURIComponent(String(rankCode)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'GET'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     *
     * @summary ランク属性情報リスト取得
     * @param {number} nendo 年度
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRankAttrList(nendo, options = {}) {
      // verify required parameter 'nendo' is not null or undefined
      if (nendo === null || nendo === undefined) {
        throw new RequiredError('nendo', 'Required parameter nendo was null or undefined when calling getRankAttrList.');
      }

      const localVarPath = `/api/v1/ranks/{nendo}`.replace(`{${"nendo"}}`, encodeURIComponent(String(nendo)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'GET'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     *
     * @summary ランク属性情報更新
     * @param {number} nendo 年度
     * @param {Array<RankAttrEditEntity>} [RankAttrEditEntity] ランク属性情報更新リスト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateRankAttrList(nendo, RankAttrEditEntity, options = {}) {
      // verify required parameter 'nendo' is not null or undefined
      if (nendo === null || nendo === undefined) {
        throw new RequiredError('nendo', 'Required parameter nendo was null or undefined when calling updateRankAttrList.');
      }

      const localVarPath = `/api/v1/ranks/{nendo}`.replace(`{${"nendo"}}`, encodeURIComponent(String(nendo)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'POST'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';
      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization = "Array&lt;RankAttrEditEntity&gt;" !== "string" || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(RankAttrEditEntity || {}) : RankAttrEditEntity || "";
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     *
     * @summary 職務ランクマスタ情報更新
     * @param {Array<RankEntity>} [RankEntity] 職務ランクマスタ情報更新リスト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateRankList(RankEntity, options = {}) {
      const localVarPath = `/api/v1/ranks`;
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'POST'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';
      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization = "Array&lt;RankEntity&gt;" !== "string" || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(RankEntity || {}) : RankEntity || "";
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    }

  };
};
/**
 * RanksControllerApi - functional programming interface
 * @export
 */

export const RanksControllerApiFp = function (configuration) {
  return {
    /**
     *
     * @summary ランク属性情報取得
     * @param {number} nendo 年度
     * @param {string} rankCode 職務ランクコード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRankAttr(nendo, rankCode, options) {
      const localVarFetchArgs = RanksControllerApiFetchParamCreator(configuration).getRankAttr(nendo, rankCode, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },

    /**
     *
     * @summary ランク属性情報リスト取得
     * @param {number} nendo 年度
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRankAttrList(nendo, options) {
      const localVarFetchArgs = RanksControllerApiFetchParamCreator(configuration).getRankAttrList(nendo, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },

    /**
     *
     * @summary ランク属性情報更新
     * @param {number} nendo 年度
     * @param {Array<RankAttrEditEntity>} [RankAttrEditEntity] ランク属性情報更新リスト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateRankAttrList(nendo, RankAttrEditEntity, options) {
      const localVarFetchArgs = RanksControllerApiFetchParamCreator(configuration).updateRankAttrList(nendo, RankAttrEditEntity, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },

    /**
     *
     * @summary 職務ランクマスタ情報更新
     * @param {Array<RankEntity>} [RankEntity] 職務ランクマスタ情報更新リスト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateRankList(RankEntity, options) {
      const localVarFetchArgs = RanksControllerApiFetchParamCreator(configuration).updateRankList(RankEntity, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    }

  };
};
/**
 * RanksControllerApi - factory interface
 * @export
 */

export const RanksControllerApiFactory = function (configuration, fetch, basePath) {
  return {
    /**
     *
     * @summary ランク属性情報取得
     * @param {number} nendo 年度
     * @param {string} rankCode 職務ランクコード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRankAttr(nendo, rankCode, options) {
      return RanksControllerApiFp(configuration).getRankAttr(nendo, rankCode, options)(fetch, basePath);
    },

    /**
     *
     * @summary ランク属性情報リスト取得
     * @param {number} nendo 年度
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRankAttrList(nendo, options) {
      return RanksControllerApiFp(configuration).getRankAttrList(nendo, options)(fetch, basePath);
    },

    /**
     *
     * @summary ランク属性情報更新
     * @param {number} nendo 年度
     * @param {Array<RankAttrEditEntity>} [RankAttrEditEntity] ランク属性情報更新リスト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateRankAttrList(nendo, RankAttrEditEntity, options) {
      return RanksControllerApiFp(configuration).updateRankAttrList(nendo, RankAttrEditEntity, options)(fetch, basePath);
    },

    /**
     *
     * @summary 職務ランクマスタ情報更新
     * @param {Array<RankEntity>} [RankEntity] 職務ランクマスタ情報更新リスト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateRankList(RankEntity, options) {
      return RanksControllerApiFp(configuration).updateRankList(RankEntity, options)(fetch, basePath);
    }

  };
};
/**
 * RanksControllerApi - object-oriented interface
 * @export
 * @class RanksControllerApi
 * @extends {BaseAPI}
 */

export class RanksControllerApi extends BaseAPI {
  /**
   *
   * @summary ランク属性情報取得
   * @param {number} nendo 年度
   * @param {string} rankCode 職務ランクコード
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RanksControllerApi
   */
  getRankAttr(nendo, rankCode, options) {
    return RanksControllerApiFp(this.configuration).getRankAttr(nendo, rankCode, options)(this.fetch, this.basePath);
  }
  /**
   *
   * @summary ランク属性情報リスト取得
   * @param {number} nendo 年度
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RanksControllerApi
   */


  getRankAttrList(nendo, options) {
    return RanksControllerApiFp(this.configuration).getRankAttrList(nendo, options)(this.fetch, this.basePath);
  }
  /**
   *
   * @summary ランク属性情報更新
   * @param {number} nendo 年度
   * @param {Array<RankAttrEditEntity>} [RankAttrEditEntity] ランク属性情報更新リスト
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RanksControllerApi
   */


  updateRankAttrList(nendo, RankAttrEditEntity, options) {
    return RanksControllerApiFp(this.configuration).updateRankAttrList(nendo, RankAttrEditEntity, options)(this.fetch, this.basePath);
  }
  /**
   *
   * @summary 職務ランクマスタ情報更新
   * @param {Array<RankEntity>} [RankEntity] 職務ランクマスタ情報更新リスト
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RanksControllerApi
   */


  updateRankList(RankEntity, options) {
    return RanksControllerApiFp(this.configuration).updateRankList(RankEntity, options)(this.fetch, this.basePath);
  }

}
/**
 * UsersControllerApi - fetch parameter creator
 * @export
 */

export const UsersControllerApiFetchParamCreator = function (configuration) {
  return {
    /**
     *
     * @summary 有給休暇情報取得
     * @param {number} nendo 年度
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPaidVacations(nendo, options = {}) {
      // verify required parameter 'nendo' is not null or undefined
      if (nendo === null || nendo === undefined) {
        throw new RequiredError('nendo', 'Required parameter nendo was null or undefined when calling getPaidVacations.');
      }

      const localVarPath = `/api/v1/users/vacations/{nendo}`.replace(`{${"nendo"}}`, encodeURIComponent(String(nendo)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'GET'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     *
     * @summary ユーザ情報取得
     * @param {string} userId ユーザID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUser(userId, options = {}) {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling getUser.');
      }

      const localVarPath = `/api/v1/users/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'GET'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     *
     * @summary 全ユーザ情報取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUsers(options = {}) {
      const localVarPath = `/api/v1/users`;
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'GET'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     *
     * @summary 新規ユーザ登録
     * @param {UserEditEntity} UserEditEntity 登録ユーザ情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    registerUser(UserEditEntity, options = {}) {
      // verify required parameter 'UserEditEntity' is not null or undefined
      if (UserEditEntity === null || UserEditEntity === undefined) {
        throw new RequiredError('UserEditEntity', 'Required parameter UserEditEntity was null or undefined when calling registerUser.');
      }

      const localVarPath = `/api/v1/users/register`;
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'POST'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';
      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization = "UserEditEntity" !== "string" || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(UserEditEntity || {}) : UserEditEntity || "";
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     *
     * @summary ユーザ初期化
     * @param {UserResetRequest} UserResetRequest ユーザ初期化
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetUser(UserResetRequest, options = {}) {
      // verify required parameter 'UserResetRequest' is not null or undefined
      if (UserResetRequest === null || UserResetRequest === undefined) {
        throw new RequiredError('UserResetRequest', 'Required parameter UserResetRequest was null or undefined when calling resetUser.');
      }

      const localVarPath = `/api/v1/users/reset`;
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'POST'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';
      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization = "UserResetRequest" !== "string" || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(UserResetRequest || {}) : UserResetRequest || "";
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     *
     * @summary 有給休暇情報更新
     * @param {number} nendo 年度
     * @param {Array<PaidVacationEditEntity>} PaidVacationEditEntity 有給休暇情報リスト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePaidVacations(nendo, PaidVacationEditEntity, options = {}) {
      // verify required parameter 'nendo' is not null or undefined
      if (nendo === null || nendo === undefined) {
        throw new RequiredError('nendo', 'Required parameter nendo was null or undefined when calling updatePaidVacations.');
      } // verify required parameter 'PaidVacationEditEntity' is not null or undefined


      if (PaidVacationEditEntity === null || PaidVacationEditEntity === undefined) {
        throw new RequiredError('PaidVacationEditEntity', 'Required parameter PaidVacationEditEntity was null or undefined when calling updatePaidVacations.');
      }

      const localVarPath = `/api/v1/users/vacations/{nendo}`.replace(`{${"nendo"}}`, encodeURIComponent(String(nendo)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'POST'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';
      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization = "Array&lt;PaidVacationEditEntity&gt;" !== "string" || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(PaidVacationEditEntity || {}) : PaidVacationEditEntity || "";
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     *
     * @summary パスワード変更
     * @param {PasswordUpdateRequest} PasswordUpdateRequest パスワード更新情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePassword1(PasswordUpdateRequest, options = {}) {
      // verify required parameter 'PasswordUpdateRequest' is not null or undefined
      if (PasswordUpdateRequest === null || PasswordUpdateRequest === undefined) {
        throw new RequiredError('PasswordUpdateRequest', 'Required parameter PasswordUpdateRequest was null or undefined when calling updatePassword1.');
      }

      const localVarPath = `/api/v1/users/password`;
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'POST'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';
      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization = "PasswordUpdateRequest" !== "string" || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(PasswordUpdateRequest || {}) : PasswordUpdateRequest || "";
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     *
     * @summary ユーザ休業更新
     * @param {string} userId ユーザID
     * @param {UpdateUserAbsencesRequest} UpdateUserAbsencesRequest ユーザ休業更新リクエスト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserAbsences(userId, UpdateUserAbsencesRequest, options = {}) {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling updateUserAbsences.');
      } // verify required parameter 'UpdateUserAbsencesRequest' is not null or undefined


      if (UpdateUserAbsencesRequest === null || UpdateUserAbsencesRequest === undefined) {
        throw new RequiredError('UpdateUserAbsencesRequest', 'Required parameter UpdateUserAbsencesRequest was null or undefined when calling updateUserAbsences.');
      }

      const localVarPath = `/api/v1/users/{userId}/absences`.replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'POST'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';
      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization = "UpdateUserAbsencesRequest" !== "string" || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(UpdateUserAbsencesRequest || {}) : UpdateUserAbsencesRequest || "";
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     *
     * @summary 全ユーザ情報更新
     * @param {Array<UserEditEntity>} UserEditEntity ユーザ情報リスト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUsers(UserEditEntity, options = {}) {
      // verify required parameter 'UserEditEntity' is not null or undefined
      if (UserEditEntity === null || UserEditEntity === undefined) {
        throw new RequiredError('UserEditEntity', 'Required parameter UserEditEntity was null or undefined when calling updateUsers.');
      }

      const localVarPath = `/api/v1/users/users`;
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;

      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = Object.assign({
        method: 'POST'
      }, baseOptions, options);
      const localVarHeaderParameter = {};
      const localVarQueryParameter = {}; // authentication JWT required

      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? configuration.apiKey("Authorization") : configuration.apiKey;
        localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';
      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query); // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization = "Array&lt;UserEditEntity&gt;" !== "string" || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(UserEditEntity || {}) : UserEditEntity || "";
      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    }

  };
};
/**
 * UsersControllerApi - functional programming interface
 * @export
 */

export const UsersControllerApiFp = function (configuration) {
  return {
    /**
     *
     * @summary 有給休暇情報取得
     * @param {number} nendo 年度
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPaidVacations(nendo, options) {
      const localVarFetchArgs = UsersControllerApiFetchParamCreator(configuration).getPaidVacations(nendo, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },

    /**
     *
     * @summary ユーザ情報取得
     * @param {string} userId ユーザID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUser(userId, options) {
      const localVarFetchArgs = UsersControllerApiFetchParamCreator(configuration).getUser(userId, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },

    /**
     *
     * @summary 全ユーザ情報取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUsers(options) {
      const localVarFetchArgs = UsersControllerApiFetchParamCreator(configuration).getUsers(options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },

    /**
     *
     * @summary 新規ユーザ登録
     * @param {UserEditEntity} UserEditEntity 登録ユーザ情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    registerUser(UserEditEntity, options) {
      const localVarFetchArgs = UsersControllerApiFetchParamCreator(configuration).registerUser(UserEditEntity, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },

    /**
     *
     * @summary ユーザ初期化
     * @param {UserResetRequest} UserResetRequest ユーザ初期化
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetUser(UserResetRequest, options) {
      const localVarFetchArgs = UsersControllerApiFetchParamCreator(configuration).resetUser(UserResetRequest, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },

    /**
     *
     * @summary 有給休暇情報更新
     * @param {number} nendo 年度
     * @param {Array<PaidVacationEditEntity>} PaidVacationEditEntity 有給休暇情報リスト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePaidVacations(nendo, PaidVacationEditEntity, options) {
      const localVarFetchArgs = UsersControllerApiFetchParamCreator(configuration).updatePaidVacations(nendo, PaidVacationEditEntity, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },

    /**
     *
     * @summary パスワード変更
     * @param {PasswordUpdateRequest} PasswordUpdateRequest パスワード更新情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePassword1(PasswordUpdateRequest, options) {
      const localVarFetchArgs = UsersControllerApiFetchParamCreator(configuration).updatePassword1(PasswordUpdateRequest, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },

    /**
     *
     * @summary ユーザ休業更新
     * @param {string} userId ユーザID
     * @param {UpdateUserAbsencesRequest} UpdateUserAbsencesRequest ユーザ休業更新リクエスト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserAbsences(userId, UpdateUserAbsencesRequest, options) {
      const localVarFetchArgs = UsersControllerApiFetchParamCreator(configuration).updateUserAbsences(userId, UpdateUserAbsencesRequest, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },

    /**
     *
     * @summary 全ユーザ情報更新
     * @param {Array<UserEditEntity>} UserEditEntity ユーザ情報リスト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUsers(UserEditEntity, options) {
      const localVarFetchArgs = UsersControllerApiFetchParamCreator(configuration).updateUsers(UserEditEntity, options);
      return (fetch = portableFetch, basePath = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    }

  };
};
/**
 * UsersControllerApi - factory interface
 * @export
 */

export const UsersControllerApiFactory = function (configuration, fetch, basePath) {
  return {
    /**
     *
     * @summary 有給休暇情報取得
     * @param {number} nendo 年度
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPaidVacations(nendo, options) {
      return UsersControllerApiFp(configuration).getPaidVacations(nendo, options)(fetch, basePath);
    },

    /**
     *
     * @summary ユーザ情報取得
     * @param {string} userId ユーザID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUser(userId, options) {
      return UsersControllerApiFp(configuration).getUser(userId, options)(fetch, basePath);
    },

    /**
     *
     * @summary 全ユーザ情報取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUsers(options) {
      return UsersControllerApiFp(configuration).getUsers(options)(fetch, basePath);
    },

    /**
     *
     * @summary 新規ユーザ登録
     * @param {UserEditEntity} UserEditEntity 登録ユーザ情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    registerUser(UserEditEntity, options) {
      return UsersControllerApiFp(configuration).registerUser(UserEditEntity, options)(fetch, basePath);
    },

    /**
     *
     * @summary ユーザ初期化
     * @param {UserResetRequest} UserResetRequest ユーザ初期化
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetUser(UserResetRequest, options) {
      return UsersControllerApiFp(configuration).resetUser(UserResetRequest, options)(fetch, basePath);
    },

    /**
     *
     * @summary 有給休暇情報更新
     * @param {number} nendo 年度
     * @param {Array<PaidVacationEditEntity>} PaidVacationEditEntity 有給休暇情報リスト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePaidVacations(nendo, PaidVacationEditEntity, options) {
      return UsersControllerApiFp(configuration).updatePaidVacations(nendo, PaidVacationEditEntity, options)(fetch, basePath);
    },

    /**
     *
     * @summary パスワード変更
     * @param {PasswordUpdateRequest} PasswordUpdateRequest パスワード更新情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePassword1(PasswordUpdateRequest, options) {
      return UsersControllerApiFp(configuration).updatePassword1(PasswordUpdateRequest, options)(fetch, basePath);
    },

    /**
     *
     * @summary ユーザ休業更新
     * @param {string} userId ユーザID
     * @param {UpdateUserAbsencesRequest} UpdateUserAbsencesRequest ユーザ休業更新リクエスト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserAbsences(userId, UpdateUserAbsencesRequest, options) {
      return UsersControllerApiFp(configuration).updateUserAbsences(userId, UpdateUserAbsencesRequest, options)(fetch, basePath);
    },

    /**
     *
     * @summary 全ユーザ情報更新
     * @param {Array<UserEditEntity>} UserEditEntity ユーザ情報リスト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUsers(UserEditEntity, options) {
      return UsersControllerApiFp(configuration).updateUsers(UserEditEntity, options)(fetch, basePath);
    }

  };
};
/**
 * UsersControllerApi - object-oriented interface
 * @export
 * @class UsersControllerApi
 * @extends {BaseAPI}
 */

export class UsersControllerApi extends BaseAPI {
  /**
   *
   * @summary 有給休暇情報取得
   * @param {number} nendo 年度
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersControllerApi
   */
  getPaidVacations(nendo, options) {
    return UsersControllerApiFp(this.configuration).getPaidVacations(nendo, options)(this.fetch, this.basePath);
  }
  /**
   *
   * @summary ユーザ情報取得
   * @param {string} userId ユーザID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersControllerApi
   */


  getUser(userId, options) {
    return UsersControllerApiFp(this.configuration).getUser(userId, options)(this.fetch, this.basePath);
  }
  /**
   *
   * @summary 全ユーザ情報取得
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersControllerApi
   */


  getUsers(options) {
    return UsersControllerApiFp(this.configuration).getUsers(options)(this.fetch, this.basePath);
  }
  /**
   *
   * @summary 新規ユーザ登録
   * @param {UserEditEntity} UserEditEntity 登録ユーザ情報
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersControllerApi
   */


  registerUser(UserEditEntity, options) {
    return UsersControllerApiFp(this.configuration).registerUser(UserEditEntity, options)(this.fetch, this.basePath);
  }
  /**
   *
   * @summary ユーザ初期化
   * @param {UserResetRequest} UserResetRequest ユーザ初期化
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersControllerApi
   */


  resetUser(UserResetRequest, options) {
    return UsersControllerApiFp(this.configuration).resetUser(UserResetRequest, options)(this.fetch, this.basePath);
  }
  /**
   *
   * @summary 有給休暇情報更新
   * @param {number} nendo 年度
   * @param {Array<PaidVacationEditEntity>} PaidVacationEditEntity 有給休暇情報リスト
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersControllerApi
   */


  updatePaidVacations(nendo, PaidVacationEditEntity, options) {
    return UsersControllerApiFp(this.configuration).updatePaidVacations(nendo, PaidVacationEditEntity, options)(this.fetch, this.basePath);
  }
  /**
   *
   * @summary パスワード変更
   * @param {PasswordUpdateRequest} PasswordUpdateRequest パスワード更新情報
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersControllerApi
   */


  updatePassword1(PasswordUpdateRequest, options) {
    return UsersControllerApiFp(this.configuration).updatePassword1(PasswordUpdateRequest, options)(this.fetch, this.basePath);
  }
  /**
   *
   * @summary ユーザ休業更新
   * @param {string} userId ユーザID
   * @param {UpdateUserAbsencesRequest} UpdateUserAbsencesRequest ユーザ休業更新リクエスト
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersControllerApi
   */


  updateUserAbsences(userId, UpdateUserAbsencesRequest, options) {
    return UsersControllerApiFp(this.configuration).updateUserAbsences(userId, UpdateUserAbsencesRequest, options)(this.fetch, this.basePath);
  }
  /**
   *
   * @summary 全ユーザ情報更新
   * @param {Array<UserEditEntity>} UserEditEntity ユーザ情報リスト
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersControllerApi
   */


  updateUsers(UserEditEntity, options) {
    return UsersControllerApiFp(this.configuration).updateUsers(UserEditEntity, options)(this.fetch, this.basePath);
  }

}