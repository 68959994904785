import moment from "moment";
export class Minutes {
  constructor(minutes) {
    this.minutes = minutes;
  }

  static apiValueOf(value) {
    return value === undefined || value === null ? undefined : new Minutes(value.minutes);
  }

  static of(value) {
    return value === undefined || value === null ? undefined : new Minutes(value);
  }

  static fromDate(baseDate, value) {
    return value === undefined || value === null ? undefined : Minutes.of(moment(value).diff(baseDate, "minutes"));
  }

  intoDate(baseDate) {
    return moment(baseDate).add(this.minutes, "minutes").toDate();
  }

  get asApiEntity() {
    return {
      minutes: this.minutes
    };
  }

  add(rhs) {
    return new Minutes(this.minutes + rhs.minutes);
  }

  divRound(divBy) {
    return new Minutes(Math.round(this.minutes / divBy));
  }

}