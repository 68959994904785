import moment from "moment";
export class Days {
  constructor(days) {
    this.days = days;
  }

  static apiValueOf(value) {
    return value === undefined || value === null ? undefined : new Days(value.days);
  }

  static of(value) {
    return value === undefined || value === null ? undefined : new Days(value);
  }

  static fromDate(baseDate, value) {
    return value === undefined || value === null ? undefined : Days.of(moment(value).diff(baseDate, "days"));
  }

  intoDate(baseDate) {
    return moment(baseDate).add(this.days, "days").toDate();
  }

  add(d) {
    return new Days(this.days + d.days);
  }

  divRound(divBy) {
    return new Days(Math.round(this.days / divBy));
  }

}