import moment from "moment";
/** 勤怠分類を表現する定数オブジェクト。一つの勤怠分類につき必ずシングルトン */

export class KintaiBunrui {
  constructor(
  /** 勤怠分類のコード (ID) */
  code,
  /** 勤怠分類の名前 */
  name,
  /** 勤怠分類の短い名前 */
  abbrName,
  /** 説明 */
  description,
  /** 出現可能年度 */
  allowedNendo,
  /** レギュラー勤怠表上での勤怠分類のコンボボックス上の出現位置 */
  regularPulldownIndex,
  /** レギュラー勤怠表上での勤怠分類[N]での出現可能位置 */
  regularAllowedPositions,
  /** 併用可能な勤怠分類コード */
  allowedCombinationCodes,
  /** 利用可能な {フレックス, 一般} × {管理職, 一般職} の組み合わせ列  */
  allowedRanks,
  /** S-Port勤怠表上での勤怠分類のコンボボックス上の出現位置  */
  sportPulldownIndicies,
  /** （申請者が一般職）承認が必要か？ */
  needsApprove,
  /** （申請者が管理職）承認が必要か？ */
  needsApproveIfManager,
  /** （申請者問わず）総務人事による承認が必要か？ */
  requirePrivilegedAuthorization,
  /** S-Port 固定時間 */
  sportFixedTimes) {
    this.code = code;
    this.name = name;
    this.abbrName = abbrName;
    this.description = description;
    this.allowedNendo = allowedNendo;
    this.regularPulldownIndex = regularPulldownIndex;
    this.regularAllowedPositions = regularAllowedPositions;
    this.allowedCombinationCodes = allowedCombinationCodes;
    this.allowedRanks = allowedRanks;
    this.sportPulldownIndicies = sportPulldownIndicies;
    this.needsApprove = needsApprove;
    this.needsApproveIfManager = needsApproveIfManager;
    this.requirePrivilegedAuthorization = requirePrivilegedAuthorization;
    this.sportFixedTimes = sportFixedTimes;
    this.allowedCombinationCodeMap = {};
    this.nendoMap = {};
    allowedCombinationCodes.forEach(e => this.allowedCombinationCodeMap[e] = true);

    for (const nendo of allowedNendo) {
      this.nendoMap[nendo] = true;
    }
  }
  /** レギュラー勤怠表（Flex・一般）用か？ */


  get isForRegular() {
    return this.regularAllowedPositions.length > 0;
  }
  /** その年度で指定できるか？ */


  canAppearNendoAt(nendo) {
    return !!this.nendoMap[nendo];
  }
  /** その日付で指定できるか？ */


  canAppearDateAt(date) {
    return this.canAppearNendoAt(getNendo(date));
  }
  /** レギュラー勤怠表で対象の条件で使用できるか？ */


  isForRegularRank(isFlex, isManager) {
    const symbolString = `${isFlex ? "F" : "N"}${isManager ? "M" : "G"}`;
    return !!this.allowedRanks.find(r => r === symbolString);
  }
  /** レギュラー勤怠表で勤怠分類[N]に指定可能か？ */


  canAppearAt(regularPosition) {
    return this.regularAllowedPositions.indexOf(regularPosition) !== -1;
  }
  /** 他の勤怠分類と併用可能か？ */


  canBeWith(anotherBunrui) {
    return this.allowedCombinationCodeMap[anotherBunrui.code] || false;
  }
  /** S-Port勤怠表で勤怠分類[N]に指定可能か？ */


  canSPortAppearAt(sPortPosition) {
    return this.sportPulldownIndicies[sPortPosition - 1] !== null;
  }
  /** この勤怠区分について承認が必要か？（査閲・承認） */


  checkIfApproveIsRequired(applicantIsManager) {
    return applicantIsManager ? this.needsApproveIfManager : this.needsApprove;
  }

}

const getNendo = d => moment(d).subtract(3, "months").toDate().getFullYear();