import { Minutes } from "./Minutes";
export const minutesSub = (l, r) => {
  if (!l && !r) {
    return undefined;
  }

  if (!l) {
    return new Minutes(-r.minutes);
  }

  if (!r) {
    return l;
  }

  return new Minutes(l.minutes - r.minutes);
};