import moment from "moment";
export class HourBasedDays {
  constructor(hours) {
    this.hours = hours;
  }

  static apiValueOf(value) {
    return value === undefined || value === null ? undefined : new HourBasedDays(value.hours);
  }

  static of(value) {
    return value === undefined || value === null ? undefined : new HourBasedDays(value);
  }

  static fromDate(baseDate, value) {
    return value === undefined || value === null ? undefined : HourBasedDays.of(moment(value).diff(baseDate, "hours"));
  }

  static fromString(value) {
    if (value === "" || !value.match(/^\d*\.?\d*$/)) {
      return undefined;
    }

    const num = +value;
    const integral = Math.floor(num * 1000);
    return new HourBasedDays(125 * Math.floor(integral / 125) / 1000);
  }

  intoDate(baseDate) {
    return moment(baseDate).add(this.hours, "days").toDate();
  }

  get dayCount() {
    return this.hours / 8;
  }

  toString(format = "hour") {
    if (format === "day") {
      return `${this.hours / 8}`;
    }

    return `${this.hours}`;
  }

  add(d) {
    return new HourBasedDays(this.hours + d.hours);
  }

  addDays(d) {
    return new HourBasedDays(this.hours + d.days * 8);
  }

  divRound(divBy) {
    return new HourBasedDays(this.hours / divBy);
  }

}